import React, { useContext } from "react";
import { Context } from "../../context/DataContext";
import "./CartItem.css";
import { RiDeleteBin3Fill } from "react-icons/ri";

const CartItem = (props) => {
  const { removeFromCart } = useContext(Context);

  return (
    <>
      <div className="row py-2">
        <div className="col-2">
          <img
            className="img-fluid"
            src={props.item.image}
            alt={props.item.name}
          />
        </div>
        <div className="col-10">
          <h5>
            {props.item.name} <strong>{props.item.quantity} x </strong>
            <strong>{props.item.price}€</strong>{" "}
            <span role="button" className="p-1">
              <RiDeleteBin3Fill
                onClick={() => removeFromCart(props.item.id)}
                size={20}
                color="red"
              />
            </span>
          </h5>
          {/* <h5 classname="">
            <strong>{props.item.quantity} x </strong>
          </h5>{" "}
          <h5 className="">
            <strong>{props.item.price}€</strong>
          </h5> */}
          {/* <button
						className="removeBtn"
						onClick={() => removeFromCart(props.item.id)}
					>
						Remover
					</button> */}
        </div>
      </div>
    </>
    // <li className="cartItem">
    // 	<div>
    // 		<img
    // 			className="itemImage"
    // 			src={props.item.image}
    // 			alt={props.item.name}
    // 		/>
    // 	</div>
    // 	<div className="itemDetails">
    // 		<div className="itemName">{props.item.name}</div>
    // 		<div className="priceAndQuantity">
    // 			<span>{props.item.quantity} x</span>
    // 			<span className="price">{props.item.price}€</span>
    // 			<button
    // 				className="removeBtn"
    // 				onClick={() => removeFromCart(props.item.id)}
    // 			>
    // 				Remover
    // 			</button>
    // 		</div>
    // 	</div>
    // </li>
  );
};

export default CartItem;
