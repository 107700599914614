import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import img1 from "../../images/services/img1.jpg";
import img2 from "../../images/services/img2.jpg";
import img3 from "../../images/services/img3.jpg";
import img4 from "../../images/services/img4.jpg";
import "./courses.css";
import { Context } from "../../context/DataContext";

export const CoursesNav = ({ data, searchItems, url }) => {
  const { handleImageChange, category1 } = useContext(Context);
  const { t } = useTranslation(["common"]);
  const [searchInput, setSearchInput] = useState("");

  function placeHolderLabel() {
    if (url === "/formacao" || url === "/formacao") {
      return "formação.";
    }
    if (url === "/consultoria") {
      return "consultorias.";
    }
    if (url === "/auditorias&projetos") {
      return "auditorias e projetos.";
    }
  }

  return (
    <>
      <div id="home-courses">
        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <img
                src={
                  url === "/formacao" || url === "/formacao2"
                    ? img1
                    : url === "/consultoria"
                    ? img2
                    : url === "/auditorias&projetos"
                    ? img3
                    : ""
                }
                class="img-fluid rounded-start"
                alt="..."
              />
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h5 class="card-title">
                  <b>
                    {url === "/formacao"
                      ? "Formação"
                      : url === "/consultoria"
                      ? "Consultorias"
                      : url === "/auditorias&projetos"
                      ? "Auditorias e projetos"
                      : ""}
                  </b>
                </h5>
                <p class="card-text">
                  Nesta página apresentamos toda a nossa oferta{" "}
                  {url === "/formacao"
                    ? "em termos de formação."
                    : url === "/consultoria"
                    ? "relativamente às consultorias disponíveis."
                    : url === "/auditorias&projetos"
                    ? "relativamente a Auditoria e Projetos disponíveis."
                    : ""}
                </p>
                <div className="input-group">
                  <input
                    type="text"
                    aria-label="search-title"
                    className="form-control"
                    //placeholder="Pesquisar por formação"
                    placeholder={`Pesquisar por ${placeHolderLabel()}`}
                    onChange={(e) => searchItems(e.target.value)}
                  />
                </div>
                <p class="card-text">
                  <small class="text-muted">{data.length} resultados</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
