import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as LinkR } from 'react-router-dom';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { getFormations } from '../../redux/formationsSlideSlice';
import Spinner from '../Spinner/Spinner';
import './FormationSlider.css';

const FormationsSlider = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const formationsData = useSelector((state) => state.formations.data);
  const formationsDataStatus = useSelector((state) => state.formations.status);

  useEffect(() => {
    dispatch(getFormations());
  }, [dispatch]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function getWarning(eventDateEnd) {
    let today = new Date();
    const diffTime = Math.abs(eventDateEnd - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays <= 10) {
      return (
        <span
          class="card-notify-badge"
          style={{
            background: '#ffd32a',
          }}
        >
          {t('common:formation-scheduled.almost-finish')}
        </span>
      );
    } else if (diffDays <= 5) {
      return (
        <span
          class="card-notify-badge"
          style={{
            background: '#ff5e57',
          }}
        >
          {t('common:formation-scheduled.almost-finish')}
        </span>
      );
    } else {
      return (
        <span
          class="card-notify-badge"
          style={{
            background: '#05c46b',
          }}
        >
          {t('common:formation-scheduled.open')}
        </span>
      );
    }
  }
  return (
    <>
      {formationsDataStatus !== 'Fulfilled' ? <Spinner /> : ''}
      <section>
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="title-box">
                <h2 className="title-alt">
                  {t('common:formation-scheduled.title')}
                </h2>
                <h3 className="fadeIn animated wow" data-wow-delay=".1s">
                  {t('common:formation-scheduled.title2')}
                </h3>
                <div className="border"></div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center pt-2 m-5">
            <div className="col-12 p-2">
              <Slider {...settings}>
                {formationsData.map((event, index) => {
                  return (
                    <div key={index} className="p-4">
                      <div>
                        <div
                          class="card"
                          style={{
                            width: '18rem',
                          }}
                        >
                          {getWarning(event.end)}
                          {/* <span class="card-notify-badge">Fully-Loaded</span> */}

                          <img
                            src={event.info.image}
                            class="card-img-top-slider"
                            alt={index}
                          />
                          <div class="card-body ">
                            <p class="card-text text-center">
                              <div className="row">
                                <strong>
                                  {t('common:formation-scheduled.start')}:{' '}
                                  {event.start.toLocaleDateString('pt-PT')}
                                </strong>
                              </div>
                              <div className="row">
                                <strong>
                                  {t('common:formation-scheduled.finish')}: :{' '}
                                  {event.end.toLocaleDateString('pt-PT')}
                                </strong>
                              </div>
                              <div className="row">
                                <strong>
                                  {t('common:formation-scheduled.duration')}:{' '}
                                  {event.info.duration}
                                </strong>
                              </div>
                              <div className="row justify-content-center p-3">
                                <div className="col-8 text-center">
                                  <a
                                    href={event.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    class="btn btn-secondary"
                                  >
                                    {' '}
                                    {t('common:formation-scheduled.button')}
                                  </a>
                                  {/* <a href="#" class="btn btn-secondary">
																			Inscrição
																		</a> */}
                                </div>
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
          <div className="row justify-content-center p-3">
            <div className="col-8 text-center">
              <LinkR className="btn btn-secondary" to="/formacao-agendadas">
                {t('common:formation-scheduled.all-formation-planned')}
              </LinkR>
              {/* <a
                href="/formacao-agendadas"
                target="_blank"
                rel="noreferrer"
                class="btn btn-secondary"
              >
                {" "}
                Ver toda a formação agendada
              </a> */}
              {/* <a href="#" class="btn btn-secondary">
																			Inscrição
																		</a> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );

  // if (eventsSlider.length > 0) {
  //   return (
  //     <>
  //       <section>
  //         <div className="container pt-5">
  //           <div className="row justify-content-center pt-3">
  //             <div className="col-12 p-2">
  //               <Slider {...settings}>
  //                 {eventsSlider.map((event, index) => {
  //                   return (
  //                     <div key={index} className="p-4">
  //                       <div>
  //                         <div
  //                           class="card"
  //                           style={{
  //                             width: "18rem",
  //                           }}
  //                         >
  //                           {getWarning(event.end)}
  //                           {/* <span class="card-notify-badge">Fully-Loaded</span> */}

  //                           <img
  //                             src={event.info.image}
  //                             class="card-img-top-slider"
  //                             alt={index}
  //                           />
  //                           <div class="card-body ">
  //                             <p class="card-text text-center">
  //                               <div className="row">
  //                                 <strong>
  //                                   Inicio:{" "}
  //                                   {event.start.toLocaleDateString("pt-PT")}
  //                                 </strong>
  //                               </div>
  //                               <div className="row">
  //                                 <strong>
  //                                   Fim: {event.end.toLocaleDateString("pt-PT")}
  //                                 </strong>
  //                               </div>
  //                               <div className="row">
  //                                 <strong>
  //                                   Duração: {event.info.duration}
  //                                 </strong>
  //                               </div>
  //                               <div className="row justify-content-center pt-3">
  //                                 <div className="col-8 text-center">
  //                                   <a
  //                                     href={event.url}
  //                                     target="_blank"
  //                                     rel="noreferrer"
  //                                     class="btn btn-secondary"
  //                                   >
  //                                     {" "}
  //                                     Inscrição
  //                                   </a>
  //                                   {/* <a href="#" class="btn btn-secondary">
  // 																		Inscrição
  // 																	</a> */}
  //                                 </div>
  //                               </div>
  //                             </p>
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   );
  //                 })}
  //               </Slider>
  //             </div>
  //           </div>
  //         </div>
  //       </section>
  //     </>
  //   );
  // } else {
  //   return (
  //     <>
  //       <div className="text-center pt-5">
  //         <div
  //           class="spinner-grow text-danger"
  //           style={{ width: "3rem", height: "3rem" }}
  //           role="status"
  //         >
  //           <span class="visually-hidden">Loading....</span>
  //         </div>
  //       </div>
  //     </>
  //   );
  // }
};

export default FormationsSlider;
