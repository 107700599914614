import React, { useState } from "react";
import logo1 from "../../images/opco_group/automative_summit_logo.png";
import logo2 from "../../images/opco_group/industrial_forum_logo.png";
import logo3 from "../../images/opco_group/opconetwork_logo.png";
import logo1w from "../../images/opco_group/auto.webp";
import logo2w from "../../images/opco_group/indus.webp";
import logo3w from "../../images/opco_group/opconetwork_logo.webp";
import { Link as LinkR } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Footer.css";
//import { Button, Modal } from "react-bootstrap";
import PrivacyComponent from "./PrivacyComponent_v2";
import TConditions from "./TConditions_pt";
//import { Modal } from "react-responsive-modal";
import { CookieText } from "../CookieBar/CookieText";
import Image from "react-image-webp";
import { Button, Modal } from "react-bootstrap";

// TODO Change font family to default
const Footer = () => {
  const { t } = useTranslation(["common"]);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, settitle] = useState("");
  const [text, settext] = useState("");

  const onOpenModal = (event) => {
    let id = event.target.id;
    if (id === "privacy") {
      const title = "Política Privacidade";
      const text = <PrivacyComponent />;
      settitle(title);
      settext(text);
      setOpen(true);
    } else if (id === "cookies") {
      const title = "Termos e Condições";
      const text = <CookieText />;
      settitle(title);
      settext(text);
      setOpen(true);
    } else {
      const title = "Terms and conditions";
      const text = <TConditions />;
      settitle(title);
      settext(text);
      setOpen(true);
    }
    //setOpen(true);
  };
  const onCloseModal = () => {
    setOpen(false);
    settitle("");
    settext("");
  };
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    let id = e.target.id;
    if (id === "privacy") {
      const title = "Política Privacidade";
      const text = <PrivacyComponent />;
      settitle(title);
      settext(text);
      setShow(true);
    } else {
      const title = "Termos e Condições";
      const text = <TConditions />;
      settitle(title);
      settext(text);
      setShow(true);
    }
  };

  const handleClick = (event) => {
    let logoID = event.target.id;
    let link = "";
    console.log("Image id", logoID);
    switch (logoID) {
      case "1":
        link = "https://automotivesummit.pt/";
        break;
      case "2":
        link = "https://www.ifportugal.pt/";
        break;
      case "3":
        link = "https://opconetwork.com/";
        break;
      default:
        break;
    }
    window.open(link, "_blank");
  };
  return (
    <>
      <footer className="section bg-secondary footer footer-sm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <Image
                  src={logo1}
                  webp={logo1w}
                  alt=""
                  className="logo logo-universe"
                  id="1"
                  onClick={handleClick}
                />

                <Image
                  src={logo2}
                  webp={logo2w}
                  alt=""
                  className="logo logo-universe"
                  id="2"
                  onClick={handleClick}
                />

                <Image
                  src={logo3}
                  webp={logo3w}
                  alt=""
                  className="logo logo-universe-network"
                  id="3"
                  onClick={handleClick}
                />
                <ul className="list-inline social-circle">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/rhopcoacademia/"
                      target="_blank"
                      rel="noreferrer"
                      className="social-media-icon"
                    >
                      {" "}
                      <i className="zmdi zmdi-facebook"></i>{" "}
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/opcocreatingsolutions/"
                      target="_blank"
                      rel="noreferrer"
                      className="social-media-icon"
                    >
                      {" "}
                      <i className="zmdi zmdi-instagram"></i>{" "}
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.youtube.com/channel/UCu7x0s7GqPjUkMF1V11_lPw?view_as=subscriber"
                      target="_blank"
                      rel="noreferrer"
                      className="social-media-icon"
                    >
                      {" "}
                      <i className="zmdi zmdi-youtube"></i>{" "}
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://pt.linkedin.com/company/rhopcoacademia"
                      target="_blank"
                      rel="noreferrer"
                      className="social-media-icon"
                    >
                      {" "}
                      <i className="zmdi zmdi-linkedin"></i>{" "}
                    </a>
                  </li>
                </ul>

                <ul className="list-inline menu-list mt-4">
                  <li className="list-inline-item">
                    <LinkR className="text-white link-to-docs" to="/quem-somos">
                      {t("common:footer.item1")}
                    </LinkR>
                  </li>
                  <li className="list-inline-item">
                    <LinkR className="text-white link-to-docs" to="/careers">
                      OPCO Careers
                    </LinkR>
                  </li>
                  <li className="list-inline-item">
                    <LinkR className="text-white link-to-docs" to="/contactos">
                      {t("common:footer.item2")}
                    </LinkR>
                  </li>
                  <li className="list-inline-item">
                    <LinkR
                      className="text-white link-to-docs"
                      to="/politica-privacidade"
                    >
                      {t("common:footer.item4")}
                    </LinkR>
                  </li>
                  <li className="list-inline-item">
                    <LinkR
                      className="text-white link-to-docs"
                      to="/termos-condicoes"
                    >
                      {t("common:footer.item3")}
                    </LinkR>
                  </li>
                </ul>
                <p className="text-muted mb-0 reserved-rights">
                  © OPCO 2021 {t("common:footer.item5")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <Modal show={show} onHide={handleClose}>
            <Modal.Header className="text-center">
              <Modal.Title>
                <h3>
                  <strong>{title}</strong>
                </h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">{text}</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleClose}
                className="text-center"
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </footer>
    </>
  );
};

export default Footer;

const ModalContent = ({ title, text, closeModal }) => {
  return (
    <>
      <div className="container">
        <h3 className="text-center">
          <strong>{title}</strong>
        </h3>
        {text}
        <div className="text-center">
          <button
            type="button"
            class="btn btn-danger btn-shadow rounded-pill w-lg animated fadeInDown wow"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};
