import React from "react";
import AboutSection from "../components/About/about";
import HomeAbout from "../components/About/home";
import Mission from "../components/About/mission";
import NavbarAbout from "../components/About/navbarAbout";
import Testimonials2 from "../components/About/testimonials2";
import Timeline from "../components/About/timeline";
import Footer from "../components/Footer";
import Team from "../components/Team";

const About = () => {
  return (
    <>
      {/* <NavbarAbout /> */}
      <HomeAbout />
      <AboutSection />
      <Mission />
      <Team />
      <Testimonials2 />
      {/* <Timeline /> */}
      {/* <Footer pathLogo={"/about"} /> */}
    </>
  );
};

export default About;
