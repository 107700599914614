import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../context/DataContext";
import SingleCourse from "../components/Courses/singleCourse";
import { useDispatch, useSelector } from "react-redux";
import { getFormations } from "../redux/formationsCatalogSlicev2";
import "./event.css";
import Spinner from "../components/Spinner/Spinner";

const Course2 = () => {
  const dispatch = useDispatch();
  const catalogData = useSelector((state) => state.formationCatalog2.data);
  let { courseID } = useParams();
  //console.log(catalogData);
  console.log("Course ID:", courseID);
  //const { courses } = useContext(Context);
  const [course, setCourse] = useState();

  useEffect(() => {
    function getCourse() {
      //var str = item.fields.title;
      //var temp = str.split(" ").join("-").replace("---", "-");
      let course_temp = catalogData.find(
        (course) =>
          course.fields.title
            .split(" ")
            .join("-")
            .replace("---", "-")
            .replace("/", "-") === courseID
      );
      if (course_temp !== undefined) {
        setCourse(course_temp);
      }
    }
    dispatch(getFormations());
    getCourse();
  }, [catalogData]);

  if (!catalogData.length > 0) {
    return (
      <>
        {/* <section>
          <div className="container">
            <div class="full-page-loader">
              <img width="200" src="/images/logowebp.webp" alt="OPCO logo" />
            </div>
          </div>
        </section> */}
        <Spinner />
      </>
    );
  }
  return <>{course && <SingleCourse course={course} />}</>;
};

export default Course2;
