import { React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import {
  filterSearchInput,
  filterSelect1,
  filterSelect2,
} from '../../redux/formationsCatalogSlicev2';

const LeftContainerCourses = () => {
  const { t } = useTranslation(['common']);
  const [searchBarString, setSearchBarString] = useState('');

  const dispatch = useDispatch();
  const select1Value = useSelector(
    (state) => state.formationCatalog2.select1Value,
  );
  const buttonId = useSelector((state) => state.formationCatalog2.buttonId);
  const catalogData = useSelector(
    (state) => state.formationCatalog2.fileredData,
  );

  const searchItems = (e) => {
    e.preventDefault();
    //console.log(e);
    console.log(searchBarString);
    dispatch(filterSearchInput(searchBarString));
  };
  const handleChangeSelect1 = (selectedOption) => {
    dispatch(filterSelect1(selectedOption.value));
  };
  const handleChangeSelect2 = (selectedOption) => {
    console.log(selectedOption);
    dispatch(filterSelect2(selectedOption.value));
  };

  const checkDisabled = () => {
    //console.log(buttonId);
    return buttonId > 0 ? true : false;
  };

  const searchBarText = `🔍 ${t('common:courses-page.search-bar')}`;
  const foundItemsText = `${t('common:courses-page.find-courses')} ${
    catalogData.length
  }
  items`;

  const optionsSelect2 = [
    {
      value: 'LEAN/PRODUTIVIDADE',
      label: `${t('common:select-trainning.select1')}`,
    },
    {
      value: 'ENGENHARIA DA QUALIDADE',
      label: `${t('common:select-trainning.select2')}`,
    },
    {
      value: 'GESTÃO E OPERAÇÕES',
      label: `${t('common:select-trainning.select3')}`,
    },
    { value: 'SOFT SKILLS', label: `${t('common:select-trainning.select4')}` },
    {
      value: 'RECURSOS HUMANOS',
      label: `${t('common:select-trainning.select5')}`,
    },
    {
      value:
        'AMBIENTE, SAUDE E SEGURANÇA NO TRABALHO, ECONOMIA CIRCULAR E SUSTENTABILIDADE',
      label: `${t('common:select-trainning.select6')}`,
    },
    {
      value: 'WORKSHOPS PRÁTICOS',
      label: `${t('common:select-trainning.select7')}`,
    },
    {
      value: 'FORMAÇÃO À MEDIDA',
      label: `${t('common:select-trainning.select8')}`,
    },
    {
      value: 'AUDITORIAS INTERNAS',
      label: `${t('common:select-trainning.select9')}`,
    },
    {
      value: 'PROJETOS DE CERTIFICAÇÃO',
      label: `${t('common:select-trainning.select10')}`,
    },
  ];

  return (
    <>
      <div className="row">
        {' '}
        <form onSubmit={(e) => searchItems(e)}>
          <div className="row">
            <div className="col-8">
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="search-text"
                  aria-describedby="searchText"
                  placeholder={searchBarText}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSearchBarString(e.target.value);
                  }}
                  //onChange={(e) => searchItems(e, e.target.value)}
                />
              </div>
            </div>
            <div className="col-2">
              <button type="submit" class="btn btn-primary">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <span class="border bg-light rounded p-3 m-0">
          <h4 className="text-center">
            {t('common:courses-page.find-formation')}:
          </h4>
          {/* <Select
            className="pt-2"
            options={optionsSelect1}
            placeholder="Categoria"
            inputId="select1"
            onChange={handleChangeSelect1}
            defaultValue={optionsSelect1[0]}
          /> */}

          <Select
            className="pt-2"
            options={optionsSelect2}
            placeholder={t('common:courses-page.actuation-area')}
            onChange={handleChangeSelect2}
            //defaultValue={optionsSelect2[0]}
            isDisabled={checkDisabled()}
          />
          {/* <Select
            className="pt-2"
            options={optionsSelect3}
            placeholder="Tipo de Formação"
            //onChange={handleChangeSelect2}
            //defaultValue={optionsSelect2[0]}
          />
          <Select
            className="pt-2"
            options={optionsSelect4}
            placeholder="Horas de Formação"
            //onChange={handleChangeSelect2}
            //defaultValue={optionsSelect2[0]}
          /> */}
          <h5 className="text-center pt-2">{foundItemsText}</h5>
        </span>
      </div>
    </>
  );
};

export default LeftContainerCourses;

// const optionsSelect1 = [
//   { value: "Automóvel", label: "Automóvel" },
//   { value: "Aeronáutica", label: "Aeronáutica" },
//   { value: "Indústria", label: "Indústria" },
// ];
const optionsSelect1 = [
  { value: 'FORMAÇÃO OFICIAL OPCO', label: 'FORMAÇÃO OFICIAL OPCO' },
  { value: 'FORMAÇÃO VDA-QMC', label: 'FORMAÇÃO VDA-QMC' },
  { value: 'FORMAÇÃO SMMT INDUSTRY', label: 'FORMAÇÃO SMMT INDUSTRY' },
  { value: 'FORMAÇÃO ODETTE', label: 'FORMAÇÃO ODETTE' },
  { value: 'FORMAÇÃO FIEV', label: 'FORMAÇÃO FIEV' },
  { value: 'FORMAÇÃO PSA', label: 'FORMAÇÃO PSA' },
  {
    value: 'FORMAÇÃO OFICIAL AIAG E PLEXUS',
    label: 'FORMAÇÃO OFICIAL AIAG E PLEXUS',
  },
];

const optionsSelect3 = [
  { value: 'online', label: 'Online' },
  { value: 'presential', label: 'Presencial' },
  { value: 'online&presential', label: 'Online e Presencial' },
];
const optionsSelect4 = [
  { value: '8', label: 'até 8 horas' },
  { value: '16', label: 'Mais de 16 horas' },
  { value: '32', label: 'Mais de 32 horas' },
];
