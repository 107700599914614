import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../context/DataContext";
import { Formik, Form, Field } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import Text from "./text";
import { Stepper, Step } from "react-form-stepper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./forms.css";
import ButtonsBeforeNext from "./buttonsBeforeNext";

const schema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email!").required("Required"),
  dateBirth: Yup.date().required("Required"),
  company: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  department: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  street: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  zipCodeCity: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  country: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  telephone: Yup.number()
    .min(8, "Too Short!")
    // .max(50, "Too Long!")
    .required("Required"),
});

const Forms = () => {
  const toastId = React.useRef(null);
  const { sendForm, formStatus, setformStatus } = useContext(Context);
  const [currentStep, setcurrentStep] = useState(0);
  const [startDate] = useState(new Date());

  useEffect(() => {
    console.log("FormStatus", formStatus);
    if (formStatus === 200) {
      toast.update(toastId.current, {
        render: "Formulário enviado com sucesso. Obrigado!",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      setformStatus("null");
    }
    if (formStatus === 400) {
      toast.update(toastId.current, {
        render: "Ocorreu um erro. Por favor volte a tentar.",
        type: "error",
        isLoading: false,
      });
      setformStatus("null");
    }
  }, [formStatus, setformStatus]);

  return (
    <div>
      <div>
        <Stepper activeStep={currentStep} className="label">
          <Step
            label="Preliminary remarks"
            onClick={() => setcurrentStep(0)}
            className="label"
          />
          <Step label="Personal Data" onClick={() => setcurrentStep(1)} />
          <Step label="Company Data" onClick={() => setcurrentStep(2)} />
          <Step
            label="Proof of training VDA 6.3"
            onClick={() => setcurrentStep(3)}
          />
          <Step
            label="Qualification requirements"
            onClick={() => setcurrentStep(4)}
          />
          <Step label="Working experience" onClick={() => setcurrentStep(5)} />
          <Step label="Further documents" onClick={() => setcurrentStep(6)} />
        </Stepper>
      </div>
      <div className="container py-2">
        <div className="row justify-content-center">
          <div className="col-10 col-sm-12">
            <div>
              <Formik
                validateOnChange
                initialValues={{
                  inHouse: false,
                  inHome: false,
                  date: startDate,
                  firstName: "",
                  lastName: "",
                  dateBirth: startDate,
                  company: "",
                  department: "",
                  street: "",
                  zipCodeCity: "",
                  country: "",
                  telephone: "",
                  email: "",
                  fileProofVDA63: {},
                  fileAuditQualif: null,
                  fileFurtherDocs: null,
                  fileProofAutoCore: null,
                  fileWorkExperience: null,
                  fileOtherDocs: null,
                  note: "",
                  informationCorrect: false,
                }}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting }, errors) => {
                  // console.log(errors);
                  // console.log(values);
                  // console.log("entrou no submit");
                  // setTimeout(() => {
                  //   alert(JSON.stringify(values, null, 2));
                  //   setSubmitting(false);
                  // }, 400);
                  //const id = toast.loading("Please wait");
                  toastId.current = toast.loading(
                    "Estamos a enviar o seu formulário..."
                  );
                  sendForm(values);
                }}
              >
                {({
                  values,
                  setFieldValue,
                  handleBlur,
                  handleChange,
                  errors,
                  touched,
                }) => (
                  <Form>
                    {currentStep === 0 && (
                      <>
                        <div className="row">
                          <Text />
                        </div>
                        <div className="row justify-content-end">
                          <div className="col-2">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={() => setcurrentStep(1)}
                            >
                              Next step
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    {currentStep === 1 && (
                      <>
                        <div className="row justify-content-center">
                          <div className="col-8">
                            <form>
                              <fieldset className="border border-danger rounded p-2">
                                <legend class="w-auto legend">
                                  <>Event place:</>
                                </legend>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="inHouse"
                                    name="inHouse"
                                    checked={values.inHouse}
                                    onChange={handleChange}
                                  />
                                  <label class="form-check-label" for="inHouse">
                                    <>
                                      <>In house</> (the event will take place
                                      as private event in you company){" "}
                                    </>
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="inHome"
                                    name="inHome"
                                    checked={values.inHome}
                                    onChange={handleChange}
                                  />
                                  <label class="form-check-label" for="inHome">
                                    <>
                                      <>Open</> (the event will take place as
                                      public event, i. e. in a conference hotel)
                                    </>
                                  </label>
                                </div>
                              </fieldset>
                            </form>
                          </div>
                        </div>
                        <div className="row justify-content-center py-4">
                          <div className="col-8">
                            <form>
                              <fieldset class="border border-danger rounded p-2">
                                <legend class="w-auto legend">
                                  <>Applicant's data:</>
                                </legend>
                                <div class="mb-3 w-25">
                                  <label for="date" class="form-label">
                                    Date
                                  </label>
                                  <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={values.date}
                                    onChange={(newDate) => {
                                      setFieldValue("date", newDate);
                                    }}
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control"
                                    id="date"
                                    placeholder="Date"
                                  />
                                  {errors.date && touched.date ? (
                                    <div className="text-danger">
                                      {errors.date}
                                    </div>
                                  ) : null}
                                </div>
                                <div class="mb-3">
                                  <label for="firstName" class="form-label">
                                    Title, first name of the applicant
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="emailHelp"
                                    id="firstName"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.firstName && touched.firstName ? (
                                    <div className="text-danger">
                                      {errors.firstName}
                                    </div>
                                  ) : null}
                                </div>
                                <div class="mb-3">
                                  <label for="lastName" class="form-label">
                                    Last name of the applicant
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="lastName"
                                    name="lastName"
                                    placeholder=""
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.lastName && touched.lastName ? (
                                    <div className="text-danger">
                                      {errors.lastName}
                                    </div>
                                  ) : null}
                                </div>
                                <div class="mb-3 w-25">
                                  <label for="date" class="form-label">
                                    Date of Birth
                                  </label>
                                  <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={values.dateBirth}
                                    onChange={(newDate) =>
                                      setFieldValue("dateBirth", newDate)
                                    }
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control"
                                  />
                                  {errors.dateBirth && touched.dateBirth ? (
                                    <div className="text-danger">
                                      {errors.dateBirth}
                                    </div>
                                  ) : null}
                                </div>
                              </fieldset>
                            </form>
                          </div>
                        </div>
                        <ButtonsBeforeNext
                          setcurrentStep={setcurrentStep}
                          buttons={2}
                          step={2}
                        />
                      </>
                    )}
                    {currentStep === 2 && (
                      <>
                        <div className="row justify-content-center py-4">
                          <div className="col-6">
                            <form>
                              <fieldset class="border border-danger rounded p-2">
                                <legend class="w-auto legend">
                                  <>Company data:</>
                                </legend>
                                <div class="mb-3">
                                  <label for="firstName" class="form-label">
                                    Company Name
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="emailHelp"
                                    id="company"
                                    name="company"
                                    value={values.company}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.company && touched.company ? (
                                    <div className="text-danger">
                                      {errors.company}
                                    </div>
                                  ) : null}
                                </div>
                                <div class="mb-3">
                                  <label for="lastName" class="form-label">
                                    Department
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="department"
                                    name="department"
                                    placeholder=""
                                    value={values.department}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.department && touched.department ? (
                                    <div className="text-danger">
                                      {errors.department}
                                    </div>
                                  ) : null}
                                </div>
                                <div class="mb-3">
                                  <label for="lastName" class="form-label">
                                    Street
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="street"
                                    name="street"
                                    placeholder=""
                                    value={values.street}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.street && touched.department ? (
                                    <div className="text-danger">
                                      {errors.street}
                                    </div>
                                  ) : null}
                                </div>
                                <div class="mb-3">
                                  <label for="lastName" class="form-label">
                                    Zip Code, city
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="zipCodeCity"
                                    name="zipCodeCity"
                                    placeholder=""
                                    value={values.zipCodeCity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.zipCodeCity && touched.zipCodeCity ? (
                                    <div className="text-danger">
                                      {errors.zipCodeCity}
                                    </div>
                                  ) : null}
                                </div>
                                <div class="mb-3">
                                  <label for="lastName" class="form-label">
                                    Country
                                  </label>
                                  {/* <CountryDropdown /> */}
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="country"
                                    name="country"
                                    placeholder=""
                                    value={values.country}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.country && touched.country ? (
                                    <div className="text-danger">
                                      {errors.country}
                                    </div>
                                  ) : null}
                                </div>
                                <div class="mb-3">
                                  <label for="lastName" class="form-label">
                                    Telephone
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="telephone"
                                    name="telephone"
                                    placeholder=""
                                    value={values.telephone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.telephone && touched.telephone ? (
                                    <div className="text-danger">
                                      {errors.telephone}
                                    </div>
                                  ) : null}
                                </div>
                                <div class="mb-3">
                                  <label for="lastName" class="form-label">
                                    Email
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="email"
                                    name="email"
                                    placeholder=""
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.email && touched.email ? (
                                    <div className="text-danger">
                                      {errors.email}
                                    </div>
                                  ) : null}
                                </div>
                              </fieldset>
                            </form>
                          </div>
                        </div>
                        <ButtonsBeforeNext
                          setcurrentStep={setcurrentStep}
                          buttons={2}
                          step={3}
                        />
                      </>
                    )}
                    {currentStep === 3 && (
                      <>
                        <p>
                          <strong>
                            All fields are mandatory. Please upload the referred
                            proof document to each item.
                          </strong>
                        </p>
                        <div className="row">
                          <div className="form-group col-8 mb-2">
                            <label htmlFor="fileProofVDA63">
                              1. Copy of your certificate of qualification of
                              the training "VDA 6.3 – Qualification as Process
                              Auditor" (.pdf)
                            </label>
                          </div>
                          <div className="form-group col-3 mb-2">
                            <input
                              id="fileProofVDA63"
                              name="fileProofVDA63"
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  "fileProofVDA63",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                          </div>
                        </div>
                        <ButtonsBeforeNext
                          setcurrentStep={setcurrentStep}
                          buttons={2}
                          step={4}
                        />
                      </>
                    )}
                    {currentStep === 4 && (
                      <>
                        <p>
                          <strong>2. Qualification requirements</strong>
                        </p>
                        <div className="row">
                          <div className="form-group col-8 mb-2">
                            <label htmlFor="fileAuditQualif">
                              - Proof of your 3-day auditor qualification based
                              on DIN EN ISO 19011 (.pdf)
                            </label>
                          </div>
                          <div className="form-group col-3 mb-2">
                            <input
                              id="fileAuditQualif"
                              name="fileAuditQualif"
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  "fileAuditQualif",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-8 mb-2">
                            <label htmlFor="fileFurtherDocs">
                              - Further document (optional) (.pdf)
                            </label>
                          </div>
                          <div className="form-group col-3 mb-2">
                            <input
                              id="fileFurtherDocs"
                              name="fileFurtherDocs"
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  "fileFurtherDocs",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <strong>and</strong>
                        </div>
                        <br />
                        <div className="row">
                          <div className="form-group col-8 mb-2">
                            <label htmlFor="fileProofAutoCore">
                              - Proof of knowledge of the Automotive Core Tools
                              (.pdf)
                            </label>
                          </div>
                          <div className="form-group col-3 mb-2">
                            <input
                              id="fileProofAutoCore"
                              name="fileProofAutoCore"
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  "fileProofAutoCore",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-8 mb-2">
                            The training "Automotive Core Tools for System and
                            Process Auditors" (ID 417) and the VDA qualification
                            "Automotive Core Tools Professional" (ID 415 and 416
                            and possibly 450) are recognized. Alternatively,
                            evidence of a minimum two-day training about
                            Automotive Core Tools by another training provider
                            as well as the passing of an Automotive Core Tools
                            online quiz is required. If the quiz is not passed,
                            the "Automotive Core Tools for System and Process
                            Auditors" course (ID 417) must be completed.
                          </div>
                        </div>
                        <ButtonsBeforeNext
                          setcurrentStep={setcurrentStep}
                          buttons={2}
                          step={5}
                        />
                      </>
                    )}
                    {currentStep === 5 && (
                      <>
                        <p>
                          <strong>3. Working experience</strong>
                        </p>
                        <div className="row">
                          <div className="form-group col-8 mb-2">
                            <label htmlFor="fileWorkExperience">
                              - Proof of at least five (5) years industrial
                              experience including at least two (2) years in
                              quality management (.pdf) - CV or a company
                              document with time with function performed
                            </label>
                          </div>
                          <div className="form-group col-3 mb-2">
                            <input
                              id="fileWorkExperience"
                              name="fileWorkExperience"
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  "fileWorkExperience",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                          </div>
                        </div>
                        <ButtonsBeforeNext
                          setcurrentStep={setcurrentStep}
                          buttons={2}
                          step={6}
                        />
                      </>
                    )}
                    {currentStep === 6 && (
                      <>
                        <p>
                          <strong>4. Further documents</strong>
                        </p>
                        <div className="row">
                          <div className="form-group col-8 mb-2">
                            <label htmlFor="fileOtherDocs">
                              - Digital passport photo of the applicant for the
                              auditor card (.jpg, .png)
                            </label>
                          </div>
                          <div className="form-group col-3 mb-2">
                            <input
                              id="fileOtherDocs"
                              name="fileOtherDocs"
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  "fileOtherDocs",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                          </div>
                          <div className="row">
                            <div className="form-group col-2 mb-2">
                              <label htmlFor="note">Notes: </label>
                            </div>
                            <div className="form-group col-8 mb-2">
                              <Field
                                component="textarea"
                                id="note"
                                name="note"
                                placeholder=""
                                rows="4"
                              />
                            </div>
                          </div>
                          <div className="form-group col-8 mb-2">
                            <label>
                              <Field
                                type="checkbox"
                                name="informationCorrect"
                              />{" "}
                              I herewith confirm that the information given is
                              correct.
                            </label>
                          </div>
                        </div>
                        <button type="submit">Submit</button>
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Forms;
