import React, { useContext } from "react";
// import { MdMenu } from "react-icons/md";
import logo from "../../images/logo.png";
import "./Navbar2Elements.css";
import { FaGlobe } from "react-icons/fa";
import { IconContext } from "react-icons";
import { Context } from "../../context/LanguageContext";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { Link as LinkR } from "react-router-dom";
import ButtonFormations from "../ButtonFormation/ButtonFormations";
import ReactTooltip from "react-tooltip";
import ButtonTesting from "../ButtonTesting/ButtonTesting";

const NavbarApps = () => {
  const { language, handleLanguage } = useContext(Context);
  const { t } = useTranslation(["common"]);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg fixed-top navbar-custom sticky-dark"
        id="navbar-sticky"
      >
        <div className="container">
          <LinkR className="navbar-brand logo" to="/">
            <img src={logo} alt="" className="logo" />
          </LinkR>
          {/* <a className="navbar-brand logo" href="">
						{" "}
						<img src={logo} alt="" className="logo" />
					</a> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <MdMenu /> */}
            <i className="zmdi zmdi-menu"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ml-auto">
              {/* <li className="nav-item">
								<Link
									className="nav-link linkS"
									to="calendar"
									smooth={true}
									duration={500}
									spy={true}
									// exact="true"
								>
									{t("common:navbar.item12")}
								</Link>
							</li> */}
              {/* <li className="nav-item">
								<Link
									className="nav-link linkS"
									to="news"
									smooth={true}
									duration={500}
									spy={true}
									// exact="true"
								>
									{t("common:navbar.item13")}
								</Link>
							</li> */}
              <li className="nav-item dropdown pr-3">
                <a
                  className="nav-link dropdown-toggle"
                  href={() => false}
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  {t("common:about-page.navbar.item6")}
                </a>

                <ul
                  className="dropdown-menu arrow"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <LinkR className="dropdown-item" to="/" id="homepage">
                      Homepage
                    </LinkR>
                  </li>
                  <li>
                    <LinkR className="dropdown-item" to="/noticias" id="news">
                      Notícias
                    </LinkR>
                  </li>
                  <li>
                    <LinkR className="dropdown-item" to="/loja" id="homepage">
                      Loja online
                    </LinkR>
                  </li>
                  <li>
                    <LinkR
                      className="dropdown-item"
                      to="/formacao"
                      id="courses"
                    >
                      {t("common:navbar.item10")}
                    </LinkR>
                  </li>
                  <li>
                    <LinkR className="dropdown-item" to="/about" id="about">
                      {t("common:navbar.item8")}
                    </LinkR>
                  </li>
                  <li>
                    <LinkR className="dropdown-item" to="/testes">
                      {t("common:navbar.testsButton")}
                    </LinkR>
                  </li>
                  <li>
                    <LinkR className="dropdown-item" to="/formacao-agendadas">
                      Eventos
                    </LinkR>
                  </li>
                  <li>
                    <LinkR
                      className="dropdown-item"
                      to="/contactos"
                      id="events"
                    >
                      {t("common:navbar.item9")}
                    </LinkR>
                  </li>
                </ul>
              </li>
              <li
                className="pr-4"
                data-tip={t("common:navbar.testTooltip")}
                data-type="info"
              >
                <ButtonTesting />
                <ReactTooltip />
              </li>
              <li
                className="pr-3"
                data-tip={t("common:navbar.item10")}
                data-type="info"
              >
                <ButtonFormations />
                <ReactTooltip />
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href={() => false}
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  <IconContext.Provider
                    value={{ color: "#e9372e", size: "2em" }}
                  >
                    <FaGlobe />
                  </IconContext.Provider>
                </a>

                <ul
                  className="dropdown-menu arrow"
                  aria-labelledby="navbarDropdown"
                >
                  <li className="langugeLi">
                    <Link onClick={() => handleLanguage("pt")} to="">
                      {"pt" === language ? <b>Português</b> : "Português"}
                    </Link>
                  </li>
                  <li className="langugeLi">
                    <Link onClick={() => handleLanguage("en")} to="">
                      {"en" === language ? <b>English</b> : "English"}
                    </Link>
                  </li>
                  <li className="langugeLi">
                    <Link onClick={() => handleLanguage("de")} to="">
                      {"de" === language ? <b>Deutsch</b> : "Deutsch"}
                    </Link>
                  </li>
                  <li className="langugeLi">
                    <Link onClick={() => handleLanguage("fr")} to="">
                      {"fr" === language ? <b>Français</b> : "Français"}
                    </Link>
                  </li>
                  <li className="langugeLi">
                    <Link onClick={() => handleLanguage("es")} to="">
                      {"es" === language ? <b>Español</b> : "Español"}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarApps;
