import React, { useState, useContext, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
//import { Context } from "../../context/DataContext";
import { useTranslation } from "react-i18next";
import "./Events.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEvents } from "../../redux/eventsSlice";
import Spinner from "../Spinner/Spinner";

const CalendarCourses = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  let history = useHistory();
  //const { events } = useContext(Context);
  const localizer = momentLocalizer(moment);
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState({});
  const onOpenModal = (event) => {
    setEvent(event);
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);
  const openPage = (event) => {
    let url = `formacao-agendadas/${event.info.urlParameter}`;
    history.push(url);
  };
  const events = useSelector((state) => state.events.events);
  const eventsStatus = useSelector((state) => state.events.status);

  useEffect(() => {
    dispatch(getEvents());
  }, []);

  return (
    <>
      {eventsStatus !== "Fulfilled" ? <Spinner /> : ""}
      <section className="section" id="calendar">
        <div className="container">
          <div class="row">
            <div class="col-lg-12 text-center">
              <div class="title-box">
                <h2 class="title-alt">{t("common:events.calendar.title")}</h2>
                <h3 class="fadeIn animated wow" data-wow-delay=".1s">
                  {t("common:events.calendar.subtitle")}
                </h3>
                <div class="border"></div>
              </div>
            </div>
          </div>
          <div className="row text-center" id="contact-info">
            <div>
              <Calendar
                events={events}
                localizer={localizer}
                style={{ height: 500, width: "95%" }}
                onSelectEvent={openPage}
                messages={{
                  next: "Próximo",
                  previous: "Anterior",
                  today: "Hoje",
                  month: "Mês",
                  week: "Semana",
                  day: "Dia",
                }}
              />
            </div>
          </div>
        </div>
        <Modal open={open} onClose={onCloseModal} center showCloseIcon={true}>
          <ModalContent event={event} closeModal={onCloseModal} t={t} />
        </Modal>
      </section>
    </>
  );
};

export default CalendarCourses;

const ModalContent = ({ event, closeModal, t }) => {
  //const [event] = useState(event);

  let start = new Date(event.start).toLocaleDateString();
  let end = new Date(event.end).toLocaleDateString();

  return (
    <>
      <div className="container">
        <h3 className="text-center">
          <strong>{event.title}</strong>
        </h3>

        <div className="text-center pb-3">
          <img
            src={event.info.image}
            className="img-fluid rounded image-event"
            alt=""
          />
        </div>

        <p>
          <h5>
            <strong>{t("common:events.calendar.label1")}:</strong> {start}
          </h5>
        </p>
        <p>
          <h5>
            <strong>{t("common:events.calendar.label2")}:</strong> {end}
          </h5>
        </p>
        <p>
          <h5>
            <strong>{t("common:events.calendar.label3")}:</strong>{" "}
            {event.info.duration}
          </h5>
        </p>
        <p>
          <h5>
            <strong>{t("common:events.calendar.label4")}:</strong>{" "}
            {event.info.schedule}
          </h5>
        </p>
        <p>
          <h5>
            <strong>
              {t("common:events.calendar.label5")}:
              <a href={event.url} target="_blank" rel="noreferrer">
                {" "}
                {t("common:events.calendar.label5_text")}
              </a>
            </strong>
          </h5>
        </p>
      </div>
    </>
  );
};
