import { createSlice } from "@reduxjs/toolkit";
import data from "../components/Courses/data_pt";
const initialState = {
  status: null,
  data: data,
  fileredData: data,
  dataSelect: data,
  buttonId: 0,
};

export const formationsCatalogSlice = createSlice({
  name: "formationsCatalogSlice",
  initialState,
  reducers: {
    filterSearchInput(state, action) {
      if (action.payload !== "") {
        const temp = state.data.filter((item) => {
          return Object.values(item)
            .join("")
            .toLowerCase()
            .includes(action.payload.toLowerCase());
        });
        state.fileredData = temp;
      }
    },
    filterSelect1(state, action) {
      const temp = state.data.filter(
        (item) => item.category2 === action.payload
      );
      state.fileredData = temp;
    },
    filterSelect2(state, action) {
      const temp = state.data.filter((item) => item.partner === action.payload);
      state.fileredData = temp;
    },
    filterSelect3(state, action) {
      const temp = state.data.filter(
        (item) => item.category3 === action.payload
      );
      state.fileredData = temp;
    },
    filterSelect4(state, action) {
      const temp = state.data.filter((item) =>
        action.payload === "Online"
          ? item.online === "yes"
          : item.presential === "yes"
      );
      state.fileredData = temp;
    },
    filterButtons(state, action) {
      const buttonId = action.payload;
      if (buttonId === 0) {
        const temp = state.data;
        state.fileredData = temp;
        state.buttonId = 0;
      }
      if (buttonId === 1) {
        const temp = state.data.filter((item) => item.category1 === "Formação");
        state.fileredData = temp;
        state.buttonId = 1;
      }
      if (buttonId === 2) {
        const temp = state.data.filter(
          (item) => item.category1 === "Consultoria"
        );
        state.fileredData = temp;
        state.buttonId = 2;
      }
      if (buttonId === 3) {
        const temp = state.data.filter(
          (item) => item.category1 === "Auditoria"
        );
        state.fileredData = temp;
        state.buttonId = 3;
      }
      console.log("Clicked on button: ", buttonId);
    },
  },
});

export const {
  filterSearchInput,
  filterSelect1,
  filterSelect2,
  filterSelect3,
  filterSelect4,
  filterButtons,
} = formationsCatalogSlice.actions;

export default formationsCatalogSlice.reducer;
