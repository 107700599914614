import React, { useEffect, useContext } from 'react';
import { Translator, Translate } from 'react-auto-translate';
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from 'react-cookie-consent';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ProjectBanner from '../src/components/Homepage/projectbanner';
import SliderComponent from '../src/components/Slider/SliderComponent';
import { Context } from '../src/context/DataContext';
import './App.css';
import Application from './components/Applications/application';
import CartPage from './components/Cart/CartPage';
import CertificationBanner from './components/CertificationBanner';
import Checkoutv1 from './components/Checkout/Checkoutv1';
import { CookieInfoText } from './components/CookieBar/CookieInfoText';
import FloatMenu from './components/FloatingMenu/FloatMenu';
import Footer from './components/Footer';
import LateralMenu from './components/LateralMenu/LateralMenu';
import Navigation from './components/Navigation/Navigation';
import NotFound from './components/NotFound/notfound';
import Product from './components/Product/Product';
import ProductsV2 from './components/Shop/ProductsV2';
import ThankYou from './components/ThankYou/ThankYou';
import Careers from './new_pages/Careers';
import Formation from './new_pages/Formation';
import Formation_v2 from './new_pages/Formation_v2';
import Home from './new_pages/Home';
import Notices from './new_pages/Notices';
import PrivacyPolitics from './new_pages/PrivacyPolitics';
import TermsConditions from './new_pages/TermsConditions';
import About from './pages/about';
import Applications from './pages/applications';
import Contact from './pages/contact';
import Course2 from './pages/course2';
import Courses from './pages/courses';
import Event from './pages/event';
import Events from './pages/events';
import Notice from './pages/notice';
import Tests from './pages/tests';

function App() {
  const { items } = useContext(Context);
  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === 'true') {
      handleAcceptCookie();
    }
  }, [items]);

  if (process.env.NODE_ENV === 'production')
    console.log = function no_console() {};
  console.table = function no_console() {};
  return (
    <>
      <Router>
        <ScrollToTop />
        <CookieConsent
          enableDeclineButton
          onAccept={handleAcceptCookie}
          onDecline={handleDeclineCookie}
          buttonText="Eu compreendo."
          declineButtonText="Não aceito."
        >
          <CookieInfoText />
        </CookieConsent>
        <Navigation />

        <Switch>
          <Route path="/" component={Home} exact />
          {/* <Route path="/loja" component={Shop} exact /> */}
          <Route path="/loja" component={ProductsV2} exact />
          <Route path="/carro" component={CartPage} exact />
          <Route path="/careers" component={Careers} exact />
          <Route path="/produto/:id" component={Product} />
          <Route path="/noticias/:id" component={Notice} />
          <Route path="/noticias" component={Notices} exact />
          <Route exact path="/checkout" component={Checkoutv1} />
          <Route exact path="/thank-you" component={ThankYou} />
          <Route exact path="/slider" component={SliderComponent} />
          <Route path="/quem-somos" component={About} exact />
          {/* <Route path="/testes" component={Tests} exact /> */}
          <Route path="/contactos" component={Contact} exact />
          <Route path="/aplicacoes" component={Applications} exact />
          <Route path="/aplicacoes/:id" component={Application} exact />
          <Route path="/consultoria" component={Formation} exact />
          <Route path="/auditorias&projetos" component={Formation} exact />
          <Route path="/formacao" component={Formation_v2} exact />
          {/* <Route path="/formacao2" component={Formation_v2} exact /> */}
          <Route path="/formacao/:courseID" component={Course2} exact />
          <Route path="/formacao-agendadas" component={Events} exact />
          <Route path="/formacao-agendadas/:eventID" component={Event} exact />
          <Route path="/termos-condicoes" component={TermsConditions} exact />
          <Route
            path="/politica-privacidade"
            component={PrivacyPolitics}
            exact
          />
          <Route component={NotFound} />
        </Switch>
        <CertificationBanner />
        <ProjectBanner />
        <Footer />
      </Router>
      {/* </React.Suspense> */}
    </>
  );
}

export default App;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
