import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
//import { useTranslation } from "react-i18next";
import Image from 'react-image-webp';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { parse } from 'date-fns';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import cv from '../../images/cv/cv.jpg';
import cvWebp from '../../images/cv/cv.webp';
import { sendFormCareers } from '../../redux/careersSlice';
import { getCounties } from '../../redux/countiesSlice';
import { getDistricts } from '../../redux/districtsSlice';
import { countryList } from './countries';

const CareersForm2 = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const toastId = React.useRef(null);
  const recaptchaRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const requiredString = t('common:carrers-form.error');

  const districts = useSelector((state) => state.districts.data);
  const counties = useSelector((state) => state.counties.data);
  const sendFormStatus = useSelector(
    (state) => state.formCareers.sendFormStatus,
  );
  const status = useSelector((state) => state.formCareers.status);

  useEffect(() => {
    dispatch(getDistricts());
    dispatch(getCounties());
  }, [dispatch]);

  function setToastType() {
    console.log('Entrou no setToaterType:', status);
    if (status !== 'Fulfilled') {
      return (toastId.current = toast.loading(
        'Estamos a enviar o seu email...',
      ));
    } else {
      if (status === 'Fulfilled' && sendFormStatus === 200) {
        toast.update(toastId.current, {
          render: 'Email enviado com sucesso. Obrigado!',
          type: 'success',
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        toast.update(toastId.current, {
          render: `Ocorreu um erro. Por favor volte a tentar.`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      }
    }
  }
  function handleFileChange(event) {
    event.preventDefault();
    console.log('File changed:', event.target.files);
    setSelectedFile(event.currentTarget.files[0]);
  }

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required(requiredString),
    street: Yup.string().required(requiredString),
    zip: Yup.string()
      .length(8)
      .matches(/[0-9]{4}-[0-9]{3}/, 'Formato inválido: 1234-123')
      .required(requiredString),
    county: Yup.string().required(requiredString),
    district: Yup.string().required(requiredString),
    email: Yup.string().email('Email inválido.').required(requiredString),
    phoneNumber: Yup.number().required(requiredString),
    phone: Yup.number(),
    gender: Yup.string().required(requiredString),
    maritalStatus: Yup.string().required(requiredString),
    dateOfBirth: Yup.date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) {
          return value;
        }
        const result = parse(originalValue, 'dd.MM.yyyy', new Date());
        return result;
      })
      .typeError('Introduza uma data com formato válido: dd.mm.aaaa')
      .required(requiredString)
      .min('1969-11-13', 'Data muito pequena.'),
    countyBirthplace: Yup.string().required(requiredString),
    districtBirthplace: Yup.string().required(requiredString),
    countryBirthplace: Yup.string().required(requiredString),
    citizenCard: Yup.string().required(requiredString),
    citizenCardNumber: Yup.number().required(requiredString),
    citizenCardValidity: Yup.date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) {
          return value;
        }
        const result = parse(originalValue, 'dd.MM.yyyy', new Date());
        return result;
      })
      .typeError('Introduza uma data com formato válido: dd.mm.aaaa')
      .required(requiredString)
      .min('1969-11-13', 'Data muito pequena.')
      .required(requiredString),
    vatNumber: Yup.number().required(requiredString),
    irsRetention: Yup.string().required(requiredString),
    levelOfSchooling: Yup.string().required(requiredString),
    areaOfQualification: Yup.string().required(requiredString),
    formativeExperience: Yup.string().required(requiredString),
    capCpp: Yup.string().required(requiredString),
    acceptTerms: Yup.bool().oneOf(
      [true],
      'Para prosseguir terá de aceitar a Política de Privacidade.',
    ),
  });

  // Function to send email
  async function handlesubmit(emailData) {
    console.log('Handle submit:', emailData);
    const token = await getToken();
    emailData = { ...emailData, token: token };
    const formData = new FormData();
    Object.entries(emailData).forEach(([key, value]) => {
      console.log(`${key}: ${value}`);
      formData.append(key, value);
    });
    console.log(selectedFile);
    formData.append('file', selectedFile);
    //formData.append(emailData);
    console.log('Formdata', formData);
    dispatch(sendFormCareers({ formData }));
  }

  async function getToken() {
    return new Promise((resolve, reject) => {
      const captchaToken = recaptchaRef.current.executeAsync();
      resolve(captchaToken);
    });
  }

  return (
    <>
      {status != null ? setToastType() : ''}
      <section className="p-5">
        <div className="container">
          <div className="row justify-content-center" id="contact-info">
            <div className="col-lg-6 pt-4">
              <Image src={cv} webp={cvWebp} className="img-fluid rounded" />
            </div>
            <div className="col-lg-6">
              <div className="contact-form">
                <div>
                  {/* <h1>Any place in your app!</h1> */}
                  <Formik
                    initialValues={initial_carrers_fields}
                    validationSchema={SignupSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      console.log('onSubmit:', values);
                      console.log('setSubmitting:', setSubmitting);
                      handlesubmit(values);
                      resetForm();
                    }}
                  >
                    {({ errors, touched, isSubmitting }) => (
                      <div className="row">
                        <div className="col-lg-12">
                          <Form>
                            <div className="form-group mb-3">
                              <label htmlFor="name" className="fw-bold mb-2">
                                {t('common:carrers-form.name-label')}
                              </label>
                              <Field
                                name="name"
                                className="form-control mb-2"
                                type="text"
                                placeholder={t(
                                  'common:carrers-form.name-placeholder',
                                )}
                              />
                              {errors.name && touched.name ? (
                                <div className="alert alert-danger py-1">
                                  {errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="street" className="fw-bold mb-2">
                                {t('common:carrers-form.street-label')}
                              </label>
                              <Field
                                name="street"
                                className="form-control mb-2"
                                type="text"
                                placeholder={t(
                                  'common:carrers-form.street-placeholder',
                                )}
                              />
                              {errors.street && touched.street ? (
                                <div className="alert alert-danger py-1">
                                  {errors.street}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="zip" className="fw-bold mb-2">
                                {t('common:carrers-form.cp-label')}
                              </label>
                              <Field
                                name="zip"
                                className="form-control mb-2"
                                type="text"
                                placeholder="0000-000"
                              />
                              {errors.zip && touched.zip ? (
                                <div className="alert alert-danger py-1">
                                  {errors.zip}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="county" className="fw-bold mb-2">
                                {t('common:carrers-form.mun-label')}
                              </label>
                              <Field
                                as="select"
                                name="county"
                                className="form-select mb-2"
                                placeholder={t(
                                  'common:carrers-form.mun-placeholder',
                                )}
                              >
                                <option value="null">
                                  {t('common:carrers-form.mun-placeholder')}
                                </option>
                                {counties
                                  ? counties.map((option, index) => (
                                      <option value={option} key={index}>
                                        {option}
                                      </option>
                                    ))
                                  : null}
                              </Field>
                              {errors.county && touched.county ? (
                                <div className="alert alert-danger py-1">
                                  {errors.county}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="district"
                                className="fw-bold mb-2"
                              >
                                {t('common:carrers-form.district')}
                              </label>
                              {/* <Field
                                as="select"
                                name="district"
                                className="form-select mb-2"
                                placeholder={t(
                                  'common:carrers-form.district-placeholder',
                                )}
                              >
                                <option value="null">
                                  {t('common:carrers-form.mun-placeholder')}
                                </option>
                                {districts
                                  ? districts.map((option, index) => (
                                      <option value={option} key={index}>
                                        {option}
                                      </option>
                                    ))
                                  : null}
                              </Field> */}
                              {errors.district && touched.district ? (
                                <div className="alert alert-danger py-1">
                                  {errors.district}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="email" className="fw-bold mb-2">
                                {t('common:carrers-form.email-label')}
                              </label>
                              <Field
                                name="email"
                                className="form-control mb-2"
                                type="email"
                                placeholder={t(
                                  'common:carrers-form.email-placeholder',
                                )}
                              />
                              {errors.email && touched.email ? (
                                <div className="alert alert-danger py-1">
                                  {errors.email}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="phoneNumber"
                                className="fw-bold mb-2"
                              >
                                {t('common:carrers-form.phone-label')}
                              </label>
                              <Field
                                name="phoneNumber"
                                className="form-control mb-2"
                                type="number"
                                placeholder={t(
                                  'common:carrers-form.phone-placeholder',
                                )}
                              />
                              {errors.phoneNumber && touched.phoneNumber ? (
                                <div className="alert alert-danger py-1">
                                  {errors.phoneNumber}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="phone" className="fw-bold mb-2">
                                {t('common:carrers-form.telephone-label')}
                              </label>
                              <Field
                                name="phone"
                                className="form-control mb-2"
                                type="number"
                                placeholder={t(
                                  'common:carrers-form.telephone-placeholder',
                                )}
                              />
                              {errors.phone && touched.phone ? (
                                <div className="alert alert-danger py-1">
                                  {errors.phone}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="gender" className="fw-bold mb-2">
                                {t('common:carrers-form.gender')}
                              </label>
                              <Field
                                as="select"
                                name="gender"
                                className="form-select mb-2"
                                placeholder={t(
                                  'common:carrers-form.gender-choose-option',
                                )}
                              >
                                <option value="null">
                                  {t(
                                    'common:carrers-form.gender-choose-option',
                                  )}
                                </option>
                                <option value="M">
                                  {t('common:carrers-form.gender-option1')}
                                </option>
                                <option value="F">
                                  {t('common:carrers-form.gender-option2')}
                                </option>
                                <option value="Outro">
                                  {t('common:carrers-form.gender-option3')}
                                </option>
                              </Field>
                              {errors.gender && touched.gender ? (
                                <div className="alert alert-danger py-1">
                                  {errors.gender}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="maritalStatus"
                                className="fw-bold mb-2"
                              >
                                {t('common:carrers-form.civil-state')}
                              </label>
                              <Field
                                as="select"
                                name="maritalStatus"
                                className="form-select mb-2"
                                placeholder={t(
                                  'common:carrers-form.civil-state-option1',
                                )}
                              >
                                <option value="null">
                                  {t('common:carrers-form.civil-state-option1')}
                                </option>
                                <option value="Solteiro">
                                  {t('common:carrers-form.civil-state-option2')}
                                </option>
                                <option value="Casado">
                                  {t('common:carrers-form.civil-state-option3')}
                                </option>
                                <option value="Dirvociado">
                                  {t('common:carrers-form.civil-state-option4')}
                                </option>
                                <option value="Viúvo">
                                  {t('common:carrers-form.civil-state-option5')}
                                </option>
                                <option value="União de facto">
                                  {t('common:carrers-form.civil-state-option6')}
                                </option>
                              </Field>
                              {errors.maritalStatus && touched.maritalStatus ? (
                                <div className="alert alert-danger py-1">
                                  {errors.maritalStatus}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="dateOfBirth"
                                className="fw-bold mb-2"
                              >
                                {t('common:carrers-form.date-birth')}
                              </label>
                              <Field
                                name="dateOfBirth"
                                className="form-control mb-2"
                                type="text"
                                placeholder={t(
                                  'common:carrers-form.date-birth-label',
                                )}
                              />
                              {errors.dateOfBirth && touched.dateOfBirth ? (
                                <div className="alert alert-danger py-1">
                                  {errors.dateOfBirth}
                                </div>
                              ) : null}
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="countyBirthplace"
                                      className="fw-bold mb-2"
                                    >
                                      {t(
                                        'common:carrers-form.naturality-county',
                                      )}
                                    </label>
                                    <Field
                                      as="select"
                                      name="countyBirthplace"
                                      className="form-select mb-2"
                                      placeholder={t(
                                        'common:carrers-form.naturality-choose-option',
                                      )}
                                    >
                                      <option value="null">
                                        {t(
                                          'common:carrers-form.naturality-choose-option',
                                        )}
                                      </option>
                                      {counties
                                        ? counties.map((option, index) => (
                                            <option value={option} key={index}>
                                              {option}
                                            </option>
                                          ))
                                        : null}
                                    </Field>
                                    {errors.countyBirthplace &&
                                    touched.countyBirthplace ? (
                                      <div className="alert alert-danger py-1">
                                        {errors.countyBirthplace}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="districtBirthplace"
                                      className="fw-bold mb-2"
                                    >
                                      {t('common:carrers-form.naturality')}
                                    </label>
                                    {/* <Field
                                      as="select"
                                      name="districtBirthplace"
                                      className="form-select mb-2"
                                      placeholder="Escolha a sua Naturalidade (distrito)..."
                                    >
                                      <option value="null">
                                        {t(
                                          'common:carrers-form.naturality-choose-option',
                                        )}
                                      </option>
                                      {districts
                                        ? districts.map((option, index) => (
                                            <option value={option} key={index}>
                                              {option}
                                            </option>
                                          ))
                                        : null}
                                    </Field> */}
                                    {errors.districtBirthplace &&
                                    touched.districtBirthplace ? (
                                      <div className="alert alert-danger py-1">
                                        {errors.districtBirthplace}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="countryBirthplace"
                                className="fw-bold mb-2"
                              >
                                {t('common:carrers-form.country-choose-option')}{' '}
                              </label>
                              <Field
                                as="select"
                                name="countryBirthplace"
                                className="form-select mb-2"
                                placeholder="Escolha a sua Naturalidade (país)..."
                              >
                                <option value="null">
                                  {t(
                                    'common:carrers-form.country-choose-label',
                                  )}{' '}
                                </option>
                                {countryList
                                  ? countryList.map((option, index) => (
                                      <option value={option} key={index}>
                                        {option}
                                      </option>
                                    ))
                                  : null}
                              </Field>
                              {errors.countryBirthplace &&
                              touched.countryBirthplace ? (
                                <div className="alert alert-danger py-1">
                                  {errors.countryBirthplace}
                                </div>
                              ) : null}
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="citizenCard"
                                    className="fw-bold mb-2 text-center"
                                  >
                                    {t('common:carrers-form.document')}
                                  </label>
                                  <Field
                                    as="select"
                                    name="citizenCard"
                                    className="form-select mb-2"
                                    placeholder={t(
                                      'common:carrers-form.document-option',
                                    )}
                                  >
                                    <option value="null">
                                      {t('common:carrers-form.document-option')}
                                    </option>
                                    <option value="Cartão de cidadão">
                                      {t(
                                        'common:carrers-form.document-option1',
                                      )}
                                    </option>
                                    <option value="Bilhete de Identidade">
                                      {t(
                                        'common:carrers-form.document-option2',
                                      )}{' '}
                                    </option>
                                    <option value="Passaporte">
                                      {t(
                                        'common:carrers-form.document-option3',
                                      )}
                                    </option>
                                    <option value=" Título de Residência">
                                      {t(
                                        'common:carrers-form.document-option4',
                                      )}
                                    </option>
                                    <option value="Bilhete de Identidade estrangeiro">
                                      {t(
                                        'common:carrers-form.document-option5',
                                      )}
                                    </option>
                                  </Field>
                                  {errors.citizenCard && touched.citizenCard ? (
                                    <div className="alert alert-danger py-1">
                                      {errors.citizenCard}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="citizenCardNumber"
                                    className="fw-bold mb-2 text-center"
                                  >
                                    {t('common:carrers-form.document-number')}
                                  </label>
                                  <Field
                                    name="citizenCardNumber"
                                    className="form-control mb-2"
                                    type="number"
                                    placeholder="Nº..."
                                  />
                                  {errors.citizenCardNumber &&
                                  touched.citizenCardNumber ? (
                                    <div className="alert alert-danger py-1">
                                      {errors.citizenCardNumber}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="citizenCardValidity"
                                    className="fw-bold mb-2"
                                  >
                                    {t('common:carrers-form.document-validity')}
                                  </label>
                                  <Field
                                    name="citizenCardValidity"
                                    className="form-control mb-2"
                                    type="text"
                                    placeholder={t(
                                      'common:carrers-form.document-validity-label',
                                    )}
                                  />
                                  {errors.citizenCardValidity &&
                                  touched.citizenCardValidity ? (
                                    <div className="alert alert-danger py-1">
                                      {errors.citizenCardValidity}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-8">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="vatNumber"
                                      className="fw-bold mb-2 text-center"
                                    >
                                      {t('common:carrers-form.nif')}
                                    </label>
                                    <Field
                                      name="vatNumber"
                                      className="form-control mb-2"
                                      type="number"
                                      placeholder={t('common:carrers-form.nif')}
                                    />
                                    {errors.vatNumber && touched.vatNumber ? (
                                      <div className="alert alert-danger py-1">
                                        {errors.vatNumber}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="passiveVat"
                                      className="fw-bold mb-2 text-center"
                                    >
                                      {t('common:carrers-form.iva')}
                                    </label>
                                    <Field
                                      as="select"
                                      name="passiveVat"
                                      className="form-select mb-2"
                                      placeholder={t('common:carrers-form.iva')}
                                    >
                                      <option value="null">
                                        {t(
                                          'common:carrers-form.scholarship-option1',
                                        )}
                                      </option>
                                      <option value="Sim">
                                        {t('common:carrers-form.iva-option1')}
                                      </option>
                                      <option value="Não">
                                        {t('common:carrers-form.iva-option2')}
                                      </option>
                                    </Field>
                                    {errors.passiveVat && touched.passiveVat ? (
                                      <div className="alert alert-danger py-1">
                                        {errors.passiveVat}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="irsRetention"
                                  className="fw-bold mb-2 text-center"
                                >
                                  {t('common:carrers-form.irs')}
                                </label>
                                <Field
                                  as="select"
                                  name="irsRetention"
                                  className="form-select mb-2"
                                  placeholder={t('common:carrers-form.irs')}
                                >
                                  <option value="null">
                                    {t(
                                      'common:carrers-form.scholarship-option1',
                                    )}
                                  </option>
                                  <option value="Sim">
                                    {t('common:carrers-form.irs-option1')}
                                  </option>
                                  <option value="Não">
                                    {t('common:carrers-form.irs-option2')}
                                  </option>
                                </Field>
                                {errors.irsRetention && touched.irsRetention ? (
                                  <div className="alert alert-danger py-1">
                                    {errors.irsRetention}
                                  </div>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="levelOfSchooling"
                                  className="fw-bold mb-2 text-center"
                                >
                                  {t('common:carrers-form.scholarship')}
                                </label>
                                <Field
                                  as="select"
                                  name="levelOfSchooling"
                                  className="form-select mb-2"
                                  placeholder={t(
                                    'common:carrers-form.scholarship',
                                  )}
                                >
                                  <option value="null">
                                    {' '}
                                    {t(
                                      'common:carrers-form.scholarship-option1',
                                    )}
                                  </option>
                                  <option value="4º ano concluído">
                                    {t(
                                      'common:carrers-form.scholarship-option2',
                                    )}
                                  </option>
                                  <option value="6º ano concluído">
                                    {t(
                                      'common:carrers-form.scholarship-option3',
                                    )}
                                  </option>
                                  <option value="9º ano concluído">
                                    {t(
                                      'common:carrers-form.scholarship-option4',
                                    )}
                                  </option>
                                  <option value="10º ano concluído">
                                    {t(
                                      'common:carrers-form.scholarship-option5',
                                    )}
                                  </option>
                                  <option value="Frequência Universitária">
                                    {t(
                                      'common:carrers-form.scholarship-option6',
                                    )}
                                  </option>
                                  <option value="Bacharelato concluído">
                                    {t(
                                      'common:carrers-form.scholarship-option7',
                                    )}
                                  </option>
                                  <option value="Licenciatura concluída">
                                    {t(
                                      'common:carrers-form.scholarship-option8',
                                    )}
                                  </option>
                                  <option value="Pós Graduação">
                                    {t(
                                      'common:carrers-form.scholarship-option9',
                                    )}
                                  </option>
                                  <option value="Mestrado">
                                    {' '}
                                    {t(
                                      'common:carrers-form.scholarship-option10',
                                    )}
                                  </option>
                                  <option value="Doutoramento">
                                    {t(
                                      'common:carrers-form.scholarship-option11',
                                    )}
                                  </option>
                                </Field>
                                {errors.levelOfSchooling &&
                                touched.levelOfSchooling ? (
                                  <div className="alert alert-danger py-1">
                                    {errors.levelOfSchooling}
                                  </div>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="areaOfQualification"
                                  className="fw-bold mb-2"
                                >
                                  {t('common:carrers-form.qualification-area')}
                                </label>
                                <Field
                                  as="textarea"
                                  name="areaOfQualification"
                                  className="form-select mb-2"
                                  placeholder={t(
                                    'common:carrers-form.qualification-area-label',
                                  )}
                                />
                                {errors.areaOfQualification &&
                                touched.areaOfQualification ? (
                                  <div className="alert alert-danger py-1">
                                    {errors.areaOfQualification}
                                  </div>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="otherCourses"
                                  className="fw-bold mb-2"
                                >
                                  {t(
                                    'common:carrers-form.qualification-area-others',
                                  )}
                                </label>
                                <Field
                                  as="textarea"
                                  name="otherCourses"
                                  className="form-select mb-2"
                                  placeholder={t(
                                    'common:carrers-form.qualification-area-others-label',
                                  )}
                                />
                                {errors.otherCourses && touched.otherCourses ? (
                                  <div className="alert alert-danger py-1">
                                    {errors.otherCourses}
                                  </div>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="formativeExperience"
                                  className="fw-bold mb-2 text-center"
                                >
                                  {t(
                                    'common:carrers-form.formative-experience',
                                  )}
                                </label>
                                <Field
                                  as="select"
                                  name="formativeExperience"
                                  className="form-select mb-2"
                                  placeholder={t(
                                    'common:carrers-form.formative-experience',
                                  )}
                                >
                                  <option value="null">
                                    {t(
                                      'common:carrers-form.formative-experience-option1',
                                    )}
                                  </option>
                                  <option value="Sim">
                                    {t(
                                      'common:carrers-form.formative-experience-option2',
                                    )}
                                  </option>
                                  <option value="Não">
                                    {t(
                                      'common:carrers-form.formative-experience-option3',
                                    )}
                                  </option>
                                </Field>
                                {errors.formativeExperience &&
                                touched.formativeExperience ? (
                                  <div className="alert alert-danger py-1">
                                    {errors.formativeExperience}
                                  </div>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="formativeExperienceHours"
                                  className="fw-bold mb-2 text-center"
                                >
                                  {t(
                                    'common:carrers-form.formative-experience-hours',
                                  )}
                                </label>
                                <Field
                                  as="select"
                                  name="formativeExperienceHours"
                                  className="form-select mb-2"
                                  placeholder={t(
                                    'common:carrers-form.formative-experience-hours',
                                  )}
                                >
                                  <option value="null">
                                    {t(
                                      'common:carrers-form.formative-experience-hours-option1',
                                    )}
                                  </option>
                                  <option value="Nenhuma">
                                    {t(
                                      'common:carrers-form.formative-experience-hours-option2',
                                    )}
                                  </option>
                                  <option value="Até 50 horas">
                                    {t(
                                      'common:carrers-form.formative-experience-hours-option3',
                                    )}
                                  </option>
                                  <option value="Entre 50 e 100 horas">
                                    {t(
                                      'common:carrers-form.formative-experience-hours-option4',
                                    )}
                                  </option>
                                  <option value="Entre 100 e 200 horas">
                                    {t(
                                      'common:carrers-form.formative-experience-hours-option5',
                                    )}
                                  </option>
                                  <option value="Entre 200 e 500 horas">
                                    {t(
                                      'common:carrers-form.formative-experience-hours-option6',
                                    )}
                                  </option>
                                  <option value="Mais de 500 horas">
                                    {t(
                                      'common:carrers-form.formative-experience-hours-option7',
                                    )}
                                  </option>
                                </Field>
                                {errors.formativeExperienceHours &&
                                touched.formativeExperienceHours ? (
                                  <div className="alert alert-danger py-1">
                                    {errors.formativeExperienceHours}
                                  </div>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="lastFormativeCourses"
                                  className="fw-bold mb-2"
                                >
                                  {t('common:carrers-form.last-courses')}
                                </label>
                                <Field
                                  as="textarea"
                                  name="lastFormativeCourses"
                                  className="form-select mb-2"
                                  placeholder={t(
                                    'common:carrers-form.last-courses',
                                  )}
                                />
                                {errors.lastFormativeCourses &&
                                touched.lastFormativeCourses ? (
                                  <div className="alert alert-danger py-1">
                                    {errors.lastFormativeCourses}
                                  </div>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="capCpp"
                                  className="fw-bold mb-2 text-center"
                                >
                                  {t('common:carrers-form.cap')}
                                </label>
                                <Field
                                  as="select"
                                  name="capCpp"
                                  className="form-select mb-2"
                                  placeholder={t('common:carrers-form.cap')}
                                >
                                  <option value="null">
                                    {' '}
                                    {t('common:carrers-form.cap-option1')}
                                  </option>
                                  <option value="Sim">
                                    {' '}
                                    {t('common:carrers-form.cap-option2')}
                                  </option>
                                  <option value="Não">
                                    {' '}
                                    {t('common:carrers-form.cap-option3')}
                                  </option>
                                </Field>
                                {errors.capCpp && touched.capCpp ? (
                                  <div className="alert alert-danger py-1">
                                    {errors.capCpp}
                                  </div>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="capCppNumber"
                                  className="fw-bold mb-2"
                                >
                                  {t('common:carrers-form.cap-number')}
                                </label>
                                <Field
                                  name="capCppNumber"
                                  className="form-control mb-2"
                                  type="text"
                                  placeholder={t(
                                    'common:carrers-form.cap-number',
                                  )}
                                />
                                {errors.capCppNumber && touched.capCppNumber ? (
                                  <div className="alert alert-danger py-1">
                                    {errors.capCppNumber}
                                  </div>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="profession"
                                  className="fw-bold mb-2"
                                >
                                  {t('common:carrers-form.job')}
                                </label>
                                <Field
                                  name="profession"
                                  className="form-control mb-2"
                                  type="text"
                                  placeholder={t('common:carrers-form.job')}
                                />
                                {errors.profession && touched.profession ? (
                                  <div className="alert alert-danger py-1">
                                    {errors.profession}
                                  </div>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="cvResumed"
                                  className="fw-bold mb-2"
                                >
                                  {t('common:carrers-form.cv')}
                                </label>
                                <Field
                                  as="textarea"
                                  name="cvResumed"
                                  className="form-select mb-2"
                                  placeholder={t('common:carrers-form.cv')}
                                />
                                {errors.cvResumed && touched.cvResumed ? (
                                  <div className="alert alert-danger py-1">
                                    {errors.cvResumed}
                                  </div>
                                ) : null}
                              </div>
                              <hr className="" />
                              <div className="mb-3">
                                <label
                                  htmlFor="newsletter"
                                  className="fw-bold mb-2 text-center"
                                >
                                  {t('common:carrers-form.newsletter')}
                                </label>
                                <Field
                                  as="select"
                                  name="newsletter"
                                  className="form-select mb-2"
                                  placeholder="Deseja receber a nossa newsletter?..."
                                >
                                  <option value="null">
                                    {t(
                                      'common:carrers-form.newsletter-option1',
                                    )}
                                  </option>
                                  <option value="Sim">
                                    {t(
                                      'common:carrers-form.newsletter-option2',
                                    )}
                                  </option>
                                  <option value="Não">
                                    {t(
                                      'common:carrers-form.newsletter-option3',
                                    )}
                                  </option>
                                </Field>
                                {errors.newsletter && touched.newsletter ? (
                                  <div className="alert alert-danger py-1">
                                    {errors.newsletter}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <hr className="" />
                            <div className="mb-3">
                              <label className="fw-bold mb-2" for="file">
                                Upload CV
                              </label>
                              <input
                                name="file"
                                type="file"
                                class="form-control"
                                id="file"
                                //value={selectedFile}
                                onChange={handleFileChange}
                              />
                              {errors.file && touched.file ? (
                                <div className="alert alert-danger py-1">
                                  {errors.file}
                                </div>
                              ) : null}
                            </div>
                            <hr className="" />
                            <div className="mb-3">
                              <Field
                                type="checkbox"
                                name="acceptTerms"
                                id="acceptTerms"
                                className="form-check-input mx-2"
                              />
                              <label
                                htmlFor="acceptTerms"
                                className="fw-bold mb-2 text-center"
                              >
                                {t('common:carrers-form.privacy-terms')}
                                <Link
                                  to="/politica-privacidade"
                                  target="_blank"
                                >
                                  {t('common:carrers-form.privacy-terms2')}
                                </Link>
                              </label>
                              {errors.acceptTerms && touched.acceptTerms ? (
                                <div className="alert alert-danger py-1">
                                  {errors.acceptTerms}
                                </div>
                              ) : null}
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-lg-12">
                                <input
                                  type="submit"
                                  id="submit"
                                  name="send"
                                  className="submitBnt btn btn-primary btn-shadow rounded-pill w-md"
                                  value={t('common:carrers-form.submit-button')}
                                  disabled={isSubmitting}
                                ></input>
                              </div>
                            </div>
                            {/* <button type="submit" disabled={isSubmitting}>
                              Submit
                            </button> */}
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_RECAPTHA_KEY}
                              size="invisible"
                              badge="bottomleft"
                            />
                          </Form>
                        </div>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <ToastContainer />
      </div>
    </>
  );
};
export default CareersForm2;

const initial_carrers_fields = {
  name: '',
  street: '',
  zip: '',
  county: '',
  district: '',
  email: '',
  phoneNumber: '',
  phone: '',
  gender: '',
  maritalStatus: '',
  dateOfBirth: '',
  countyBirthplace: '',
  districtBirthplace: '',
  countryBirthplace: '',
  citizenCard: '',
  citizenCardNumber: '',
  citizenCardValidity: '',
  vatNumber: '',
  passiveVat: '',
  irsRetention: '',
  levelOfSchooling: '',
  areaOfQualification: '',
  otherCourses: '',
  formativeExperience: '',
  formativeExperienceHours: '',
  lastFormativeCourses: '',
  capCpp: '',
  capCppNumber: '',
  profession: '',
  cvResumed: '',
  newsletter: '',
  acceptTerms: false,
};
