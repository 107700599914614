import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as LinkR } from "react-router-dom";
import { FaQuoteLeft } from "react-icons/fa";
import "./aside.css";
import logo_cert from "../../images/logo_cert.png";
import { useTranslation } from "react-i18next";
import { Context } from "../../context/DataContext";
import Spinner from "../Spinner/Spinner";
import TestimonialSection from "./TestimonialSection";
import { getCatalogs } from "../../redux/asideSlice";

export const Aside = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const catalogData = useSelector((state) => state.catalogs.data);
  const catalogDataStatus = useSelector((state) => state.catalogs.status);

  useEffect(() => {
    dispatch(getCatalogs());
  }, []);

  const getCatalogUrl = (filename) => {
    let index = catalogData.findIndex((item) => item.filename === filename);
    return catalogData[index].url;
  };

  return (
    <>
      {catalogDataStatus !== "Fulfilled" ? <Spinner /> : ""}
      <div className="border-top my-3"></div>
      <section>
        <h4 className="title text-center fw-bold">
          {t("common:courses.aside.title1")}
        </h4>
        <p>{t("common:courses.aside.text1")}</p>
        <div className="text-center">
          <img src={logo_cert} alt="logo_cert" className="img-thumbnail" />
        </div>
      </section>
      <div className="border-top my-3"></div>
      <section>
        <h4 className="title text-center fw-bold">
          {t("common:courses.aside.title2")}
        </h4>
        <p>{t("common:courses.aside.text2")}</p>
        <div className="text-center">
          <LinkR
            className="btn btn-danger btn-shadow rounded-pill w-lg animated fadeInDown wow"
            //to={catalog}
            //to={getCatalogUrl("formation_catalog")}
            target="_blank"
            //download
            onClick={() =>
              window.open(getCatalogUrl("formation_catalog"), "_blank")
            }
          >
            {t("common:courses.aside.button-label")}
          </LinkR>
        </div>
      </section>
      <div className="border-top my-3"></div>
      <section>
        <h4 className="title text-center fw-bold">
          {t("common:courses.aside.title3")}
        </h4>
        <p>{t("common:courses.aside.text3")}</p>
        <div className="text-center">
          <LinkR
            className="btn btn-danger btn-shadow rounded-pill w-lg animated fadeInDown wow"
            //to={plan}
            target="_blank"
            //download
            onClick={() =>
              window.open(getCatalogUrl("formation_plan"), "_blank")
            }
          >
            {t("common:courses.aside.button-label")}
          </LinkR>
        </div>
      </section>
      <div className="border-top my-3"></div>
      <TestimonialSection />
    </>
  );
};
