import React, { useState, useContext, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { sendContactForm } from '../../redux/contactSlice';
import './Navbar2Elements.css';

const HomeContact = () => {
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const toastId = React.useRef(null);
  const [location1] = useState(location1_json);
  const [location2] = useState(location2_json);
  const [currentLocation, setCurrentLocation] = useState(0);
  const [emailData, setEmail] = useState(initial_emailFields);
  const [selectedOption, setselectedOption] = useState(null);
  const [selectedOption2, setselectedOption2] = useState(null);
  const { t } = useTranslation(['common']);
  const sendFormStatus = useSelector(
    (state) => state.formContact.sendFormStatus,
  );
  const status = useSelector((state) => state.formContact.status);

  async function getToken() {
    return new Promise((resolve, reject) => {
      const captchaToken = recaptchaRef.current.executeAsync();
      resolve(captchaToken);
    });
  }

  // Function to send email
  async function handlesubmit(e) {
    e.preventDefault();
    const result = await getToken();
    let temp = { ...emailData, token: result };
    dispatch(sendContactForm(temp));
  }

  function handleFormChange(event) {
    event.preventDefault();
    let name = event.target.name;
    let value = event.target.value;
    setEmail((prevState) => ({ ...prevState, [name]: value }));
  }
  // Funtion to handle subject select change
  function handleChangeSelect(selectedOption) {
    setselectedOption(selectedOption);
    let name = 'subject';
    setEmail((prevState) => ({ ...prevState, [name]: selectedOption.value }));
  }

  function handleChangeSelect2(selectedOption) {
    setselectedOption2(selectedOption);
    let information = 'information';
    setEmail((prevState) => ({
      ...prevState,
      [information]: selectedOption.value,
    }));
  }

  // Function to toggle location
  function toggleLocation(event) {
    if (event.target.id === 'location2' && !currentLocation)
      setCurrentLocation(!currentLocation);
    else if (event.target.id === 'location1' && currentLocation)
      setCurrentLocation(!currentLocation);
  }

  function setToastType() {
    console.log('Entrou no setToaterType:', status);
    if (status !== 'Fulfilled') {
      return (toastId.current = toast.loading(
        'Estamos a enviar o seu email...',
      ));
    } else {
      if (status === 'Fulfilled' && sendFormStatus === 200) {
        toast.update(toastId.current, {
          render: 'Email enviado com sucesso. Obrigado!',
          type: 'success',
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        toast.update(toastId.current, {
          render: `Ocorreu um erro. Por favor volte a tentar.`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      }
    }
  }

  return (
    <>
      {status != null ? setToastType() : ''}
      <section className="home">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="home-wrapper home-wrapper-sm text-center">
                <h2 className="animated fadeInDown wow" data-wow-delay=".1s">
                  {t('common:contact.title')}
                </h2>
                <h2>
                  <span
                    className="text-colored locations"
                    id="location1"
                    onClick={toggleLocation}
                  >
                    {currentLocation ? (
                      'Azeitão Office Center'
                    ) : (
                      <strong>Azeitão Office Center</strong>
                    )}
                  </span>{' '}
                  |{' '}
                  <span
                    className="text-colored locations"
                    id="location2"
                    onClick={toggleLocation}
                  >
                    {currentLocation ? (
                      <strong>Aveiro Business Center</strong>
                    ) : (
                      'Aveiro Business Center'
                    )}
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section contact-page">
        <div className="container">
          <div className="row text-center" id="contact-info">
            <div className="col-lg-4">
              <div className="contact-detail-box">
                <div className="mb-3">
                  <i className="zmdi zmdi-phone zmdi-hc-3x text-colored"></i>
                </div>
                <h4>{t('common:contact.icon1')}</h4>
                {currentLocation ? (
                  location2.phones[0]
                ) : (
                  <address>
                    {location1.phones.map((phone, index) => {
                      return (
                        <>
                          <div key={index}>{phone}</div>
                          <br />
                        </>
                      );
                    })}
                  </address>
                )}
                <p className="fw-bold">{t('common:contact-form.call1')}</p>
                <p className="fw-bold">{t('common:contact-form.call2')}</p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="contact-detail-box">
                <div className="mb-3">
                  <i className="zmdi zmdi-home zmdi-hc-3x text-colored"></i>
                </div>
                <h4>{t('common:contact.icon2')}</h4>

                <address>
                  {currentLocation ? location2.address1 : location1.address1}
                  <br />
                  {currentLocation ? location2.address2 : location1.address2}
                  <br />
                </address>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="contact-detail-box">
                <div className="mb-3">
                  <i className="zmdi zmdi-email zmdi-hc-3x text-colored"></i>
                </div>

                <h4>{t('common:contact.icon3')}</h4>
                {emails.map((email, index) => {
                  return (
                    <>
                      <a
                        href={'mailto:' + email}
                        className="text-muted"
                        id={index}
                        key={index}
                      >
                        {email}
                      </a>
                      <br />
                    </>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="row" id="get-in-touch">
            <div className="col-lg-6">
              <div className="contact-map">
                {currentLocation ? (
                  <iframe
                    src="https://www.google.com/maps/embed/v1/place?q=Aveiro%20Business%20Center%20Rua%20da%20Igreja%2C%2079%20E1%20N.%20Sra%20F%C3%A1tima%203810-744%20Aveiro&key=AIzaSyA7HKcbx4X4zNgImTnFxSPRl2jaaPaGc_I"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    style={{ width: '100%', height: '360px' }}
                    title="loc1"
                  ></iframe>
                ) : (
                  <iframe
                    src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJDXcQ0MBIGQ0RnK7OSFXw948&key=AIzaSyA7HKcbx4X4zNgImTnFxSPRl2jaaPaGc_I"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    style={{ width: '100%', height: '360px' }}
                    title="loc2"
                  ></iframe>
                )}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="contact-form">
                <span id="error-msg"></span>
                <form method="post" name="myForm">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label htmlFor="name2" className="fw-bold mb-2">
                          {t('common:contact-form.name')}
                        </label>
                        <input
                          value={emailData.name}
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder={t('common:contact-form.name')}
                          onChange={handleFormChange}
                        ></input>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="fw-bold mb-2">
                          {t('common:contact-form.email')}
                        </label>
                        <input
                          value={emailData.email}
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder={t('common:contact-form.email')}
                          onChange={handleFormChange}
                          required
                        ></input>
                      </div>
                      <div className="mb-3">
                        <Select
                          value={selectedOption}
                          onChange={handleChangeSelect}
                          options={options}
                          placeholder={t('common:contact-form.subject')}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="comments" className="fw-bold mb-2">
                          {t('common:contact-form.message')}
                        </label>
                        <textarea
                          value={emailData.message}
                          name="message"
                          id="message"
                          rows="5"
                          className="form-control"
                          placeholder={t('common:contact-form.message')}
                          onChange={handleFormChange}
                        ></textarea>
                      </div>
                      <div className="mb-3">
                        <Select
                          value={selectedOption2}
                          onChange={handleChangeSelect2}
                          options={options2}
                          placeholder={t('common:contact-form.how-find-page')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <input
                        type="submit"
                        id="submit"
                        name="send"
                        className="submitBnt btn btn-primary btn-shadow rounded-pill w-md"
                        value={t('common:contact-form.send')}
                        onClick={handlesubmit}
                      ></input>
                    </div>
                  </div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTHA_KEY}
                    size="invisible"
                    badge="bottomleft"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <ToastContainer />
      </div>
    </>
  );
};

export default HomeContact;

const emails = ['opco@opco.pt', 'academia@opco.pt'];

const options = [
  { value: 'Formação', label: 'Formação' },
  { value: 'Auditoria', label: 'Auditoria' },
  { value: 'Consultoria', label: 'Consultoria' },
  { value: 'Indústria e Serviços', label: 'Indústria e Serviços' },
  { value: 'Outro assunto', label: 'Outro assunto' },
];
const options2 = [
  { value: 'Email', label: 'Email' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'Outro', label: 'Outro' },
];

const location1_json = {
  address1: 'Rua José Augusto Coelho, N.º 158',
  address2: '2925-539 Vila Nogueira de Azeitão',
  phones: ['+351 210 152 492 *', '+351 962 119 040 **', '+351 962 119 042 **'],
};

const location2_json = {
  address1: 'Rua da Igreja, 79 E1 N. Srª Fátima',
  address2: '3810-744 Aveiro',
  phones: ['+351 234 059 202 *'],
};
const initial_emailFields = {
  name: '',
  email: '',
  subject: '',
  message: '',
  information: '',
};

const SelectContact = () => {
  const [selectedOption, setselectedOption] = useState('');
  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
    console.log(`Option selected:`, selectedOption);
  };
  return (
    <>
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        placeholder="Selecione o assunto.."
      />
    </>
  );
};
