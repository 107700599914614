import React, { useState, useContext, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Context } from '../../context/DataContext';
import { sendContactForm } from '../../redux/contactSlice';
import './contactHomepage.css';

const ContactHomepage = () => {
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const toastId = React.useRef(null);
  const [emailData, setEmail] = useState(initial_emailFields);
  const { setSubject } = useContext(Context);
  const [selectedOption, setselectedOption] = useState(null);
  const [selectedOption2, setselectedOption2] = useState(null);
  const sendFormStatus = useSelector(
    (state) => state.formContact.sendFormStatus,
  );
  const status = useSelector((state) => state.formContact.status);

  async function getToken() {
    return new Promise((resolve, reject) => {
      const captchaToken = recaptchaRef.current.executeAsync();
      setEmail((prevState) => ({ ...prevState, token: captchaToken }));
      resolve(captchaToken);
    });
  }

  async function handlesubmit(e) {
    e.preventDefault();
    const token = await getToken();
    let temp = { ...emailData, token: token };
    dispatch(sendContactForm(temp));
  }

  function handleFormChange(event) {
    event.preventDefault();
    let name = event.target.name;
    let value = event.target.value;
    setEmail((prevState) => ({ ...prevState, [name]: value }));
  }
  // Function to handle subject select change
  function handleChangeSelect(selectedOption) {
    setselectedOption(selectedOption);
    let name = 'subject';
    setEmail((prevState) => ({ ...prevState, [name]: selectedOption.value }));
    setSubject(selectedOption.value);
  }

  function handleChangeSelect2(selectedOption) {
    setselectedOption2(selectedOption);
    let information = 'information';
    setEmail((prevState) => ({
      ...prevState,
      [information]: selectedOption.value,
    }));
  }

  function setToastType() {
    console.log('Entrou no setToaterType:', status);
    if (status !== 'Fulfilled') {
      return (toastId.current = toast.loading(
        'Estamos a enviar o seu email...',
      ));
    } else {
      if (status === 'Fulfilled' && sendFormStatus === 200) {
        toast.update(toastId.current, {
          render: 'Email enviado com sucesso. Obrigado!',
          type: 'success',
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        toast.update(toastId.current, {
          render: `Ocorreu um erro. Por favor volte a tentar.`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      }
    }
  }

  return (
    <>
      {status != null ? setToastType() : ''}
      <section className="section bg-gray home" id="features">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="title-box">
                <h2 className="title-alt">{t('common:contact-form.title')}</h2>
                <h3 className="fadeIn animated wow" data-wow-delay=".1s">
                  {t('common:contact-form.title2')}
                </h3>
                <div className="border"></div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center ">
            <div className="col-lg-8">
              <div className="contact-form">
                <span id="error-msg"></span>
                <form method="post" name="myForm" form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label htmlFor="name2" className="fw-bold mb-2">
                          {t('common:contact-form.name')}
                        </label>
                        <input
                          value={emailData.name}
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder={t('common:contact-form.name')}
                          onChange={handleFormChange}
                        ></input>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="fw-bold mb-2">
                          {t('common:contact-form.email')}
                        </label>
                        <input
                          value={emailData.email}
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder={t('common:contact-form.email')}
                          onChange={handleFormChange}
                          required
                        ></input>
                      </div>
                      <div className="mb-3">
                        <Select
                          value={selectedOption}
                          onChange={handleChangeSelect}
                          options={options}
                          placeholder={t('common:contact-form.subject')}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="comments" className="fw-bold mb-2">
                          {t('common:contact-form.message')}
                        </label>
                        <textarea
                          value={emailData.message}
                          name="message"
                          id="message"
                          rows="5"
                          className="form-control"
                          placeholder={t('common:contact-form.message')}
                          onChange={handleFormChange}
                        ></textarea>
                      </div>
                      <div className="mb-3">
                        <Select
                          value={selectedOption2}
                          onChange={handleChangeSelect2}
                          options={options2}
                          placeholder={t('common:contact-form.how-find-page')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div class="d-grid gap-2 col-6 mx-auto">
                      <button
                        class="btn btn-primary"
                        type="submit"
                        id="submit"
                        name="send"
                        className="submitBnt btn btn-primary btn-shadow rounded-pill w-md"
                        value={t('common:contact-form.send')}
                        onClick={handlesubmit}
                      >
                        {t('common:contact-form.send')}
                      </button>
                    </div>
                    {/* <div className="col-lg-12">
                      <input
                        type="submit"
                        id="submit"
                        name="send"
                        className="submitBnt btn btn-primary btn-shadow rounded-pill w-md"
                        value={t("common:contact.form.button")}
                        onClick={handlesubmit}
                      ></input>
                    </div> */}
                  </div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTHA_KEY}
                    size="invisible"
                    badge="bottomleft"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <ToastContainer />
      </div>
    </>
  );
};

export default ContactHomepage;

const initial_emailFields = {
  name: '',
  email: '',
  subject: '',
  message: '',
  information: '',
  token: '',
};

const options = [
  { value: 'Formação', label: 'Formação' },
  { value: 'Auditoria', label: 'Auditoria' },
  { value: 'Consultoria', label: 'Consultoria' },
  { value: 'Indústria e Serviços', label: 'Indústria e Serviços' },
  { value: 'Outro assunto', label: 'Outro assunto' },
];
const options2 = [
  { value: 'Email', label: 'Email' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'Outro', label: 'Outro' },
];
