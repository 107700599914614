import React from 'react';
import Image from 'react-image-webp';

import logo1w from '../../images/opco_group/auto.webp';
import logo1 from '../../images/opco_group/automative_summit_logo.png';
import logo2w from '../../images/opco_group/indus.webp';
import logo2 from '../../images/opco_group/industrial_forum_logo.png';
import logo3 from '../../images/opco_group/opconetwork_logo.png';
import logo3w from '../../images/opco_group/opconetwork_logo.webp';
import './LateralMenu.css';

const LateralMenu = () => {
  const handleClick = (event) => {
    let logoID = event.target.id;
    let link = '';
    console.log('Image id', logoID);
    switch (logoID) {
      case '1':
        link = 'https://automotivesummit.pt/';
        break;
      case '2':
        link = 'https://www.ifportugal.pt/';
        break;
      case '3':
        link = 'https://opconetwork.com/';
        break;
      default:
        break;
    }
    window.open(link, '_blank');
  };
  return (
    <div class="icon-bar">
      <a href="#" class="facebook">
        <Image
          src={logo1}
          webp={logo1w}
          alt=""
          className="logo logo-universe"
          id="1"
          onClick={handleClick}
        />
      </a>
      <a href="#" class="twitter">
        <Image
          src={logo2}
          webp={logo2w}
          alt=""
          className="logo logo-universe"
          id="2"
          onClick={handleClick}
        />
      </a>
      <a href="#" class="google pt-4">
        <Image
          src={logo3}
          webp={logo3w}
          alt=""
          className="logo logo-universe-network"
          id="3"
          onClick={handleClick}
        />
      </a>
    </div>
  );
};

export default LateralMenu;
