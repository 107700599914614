import React from "react";
import HomeCourses from "../components/Courses/home";
import Catalog from "../components/Courses/catalog_v2";
import { Link as LinkR, withRouter, useLocation } from "react-router-dom";

const Formation_v2 = () => {
  let location = useLocation();

  return (
    <>
      <HomeCourses url={location.pathname} />
      <Catalog url={location.pathname} />
    </>
  );
};

export default Formation_v2;
