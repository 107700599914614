import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Client from "../Contentfull";

export const getFormations = createAsyncThunk(
  "formations/getFormationsSlider",
  async () => {
    return await Client.getEntries({
      content_type: "event",
    }).then((res) => formatData(res));
  }
);

function formatData(res) {
  let items = res.items;
  let today = new Date();
  let arr = [];
  //console.warn("Event: ", items);
  items.forEach((item) => {
    let tempObj = {
      title: "",
      start: "",
      end: "",
      allDay: "",
      url: "",
      info: { duration: "", schedule: "", image: "", urlParameter: "" },
      urlParameter: "",
    };
    tempObj.title = item.fields.title;
    tempObj.start = new Date(item.fields.start);
    tempObj.end = new Date(item.fields.end);
    tempObj.allDay = item.fields.allDay;
    tempObj.url = item.fields.url;
    tempObj.info.image = item.fields.image.fields.file.url;
    tempObj.info.duration = item.fields.duration;
    tempObj.info.schedule = item.fields.schedule;
    tempObj.info.urlParameter = item.fields.urlParameter;
    if (tempObj.start > today) {
      arr = [...arr, tempObj];
    }
  });
  return arr;
}

const initialState = {
  status: null,
  data: [],
};

const formationsSlideSlice = createSlice({
  name: "formationsSliderSlice",
  initialState,
  extraReducers: {
    [getFormations.pending]: (state) => {
      state.status = "Pending";
    },

    [getFormations.fulfilled]: (state, action) => {
      state.status = "Fulfilled";
      state.data = action.payload;
    },

    [getFormations.rejected]: (state) => {
      state.status = "Rejected";
    },
  },
});

export default formationsSlideSlice.reducer;
