import { React, useState } from "react";
import { Aside } from "./aside";
import CoursesHeader from "./coursesHeader";
import CoursesTable from "../Courses/coursesTable";
import { CoursesNav } from "./coursesNav";
import data_pt from "../../components/Courses/data_pt";
import "./courses.css";

const Catalog = ({ url }) => {
  const [data, setData] = useState(data_pt);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = data.filter(filter).filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(data);
    }
  };

  const filter = (value) => {
    if (url === "/formacao") {
      if (value.category1 === "Formação") return value;
    }
    if (url === "/consultoria") {
      if (value.category1 === "Consultoria") return value;
    }
    if (url === "/auditorias&projetos") {
      if (value.category1 === "Auditoria") return value;
    }
  };

  return (
    <>
      <section id="catalog">
        <div className="container home-courses">
          <div className="row">
            <div className="col-lg-8 col-md-7 col-12">
              <CoursesNav
                data={
                  searchInput.length > 1 ? filteredResults : data.filter(filter)
                }
                url={url}
                searchItems={searchItems}
              />
              <CoursesTable
                data={
                  searchInput.length > 1 ? filteredResults : data.filter(filter)
                }
              />
            </div>
            <aside className="col-lg-3 offset-lg-1 col-md-4 offset-md-1">
              <Aside />
            </aside>
          </div>
        </div>
      </section>
    </>
  );
};

export default Catalog;
