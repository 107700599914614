import React, { useState } from "react";
//import { Modal } from "react-responsive-modal";
import { useTranslation } from "react-i18next";
import PrivacyComponent from "../Footer/PrivacyComponent";
import { Button, Modal } from "react-bootstrap";

export const CookieInfoText = () => {
	const { t } = useTranslation(["common"]);
	const [open, setOpen] = useState(false);
	const onOpenModal = () => {
		setOpen(true);
	};
	const [show, setShow] = useState(false);
	const [title, settitle] = useState("");
	const [text, settext] = useState("");
	const handleClose = () => setShow(false);

	const onCloseModal = () => setOpen(false);
	const handleShow = (e) => {
		const title = "Política Privacidade";
		const text = <PrivacyComponent />;
		settitle(title);
		settext(text);
		setShow(true);
	};

	return (
		<>
			Este website utiliza cookies para garantira melhor experiência ao
			utilizador.{" "}
			{/* <a onClick={onOpenModal} classname="cookie-link">
				Saiba mais
			</a> */}
			<a href="#" class="" onClick={handleShow}>
				Saiba mais
			</a>
			<div className="text-center">
				<Modal show={show} onHide={handleClose}>
					<Modal.Header className="text-center">
						<Modal.Title>
							<h3>
								<strong>{title}</strong>
							</h3>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="">{text}</Modal.Body>
					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={handleClose}
							className="text-center"
						>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</>
	);
};
