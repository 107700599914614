import React, { useContext, useEffect, useState } from "react";
import "./courses.css";
import DataTable from "react-data-table-component";
import { Context } from "../../context/DataContext";
import { Link as LinkR } from "react-router-dom";
import { FaMailBulk } from "react-icons/fa";
import { useHistory } from "react-router-dom";

const CoursesTable = ({ data }) => {
  let history = useHistory();
  //   const filteredCourses2 = data.filter((course) => {
  //     return course.title.toLowerCase().includes(searchTextBox.toLowerCase());
  //   });

  const columns = [
    {
      name: "Designação",
      selector: "title",
      sortable: true,
      maxWidth: "400px",
      cell: (row) => (
        <div data-tag="allowRowEvents" style={{ fontWeight: 500 }}>
          {row.title}
        </div>
      ),
    },
    {
      name: "Presencial",
      selector: "presential",
      maxWidth: "50px",
      center: true,
    },
    {
      name: "Online",
      selector: "online",
      maxWidth: "50px",
      center: true,
    },
    {
      name: "Partner",
      grow: 0,
      cell: (row) => (
        <img
          data-tag="allowRowEvents"
          height="50px"
          width="50px"
          alt="thumbnail1"
          src={row.thumbnail}
        />
      ),
    },
  ];

  const handleRowClick = (row) => {
    console.log(row.url);
    let url = `formacao/${row.url}`;
    console.log("URL", url);
    console.log("History", history);
    history.push(url);
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        // expandableRows
        // expandableRowsComponent={<ExpandableComponent />}
        striped
        highlightOnHover
        pagination
        pointerOnHover
        // expandOnRowClicked
        className="titles-table"
        data-tag="allowRowEvents"
        onRowClicked={handleRowClick}
      />
    </>
  );
};

export default CoursesTable;

const ExpandableComponent = ({ data }) => {
  console.log(data);
  return (
    <>
      <div class="row">
        <div class="col-12 text-center">
          <img
            height="100px"
            width="100px"
            alt="thumbnail1"
            src={data.thumbnail}
          />
        </div>
      </div>
      <dl class="row">
        <dt className="col-sm-3">Designação:</dt>
        <dd className="col-sm-9">{data.title}</dd>
        <dt class="col-sm-3">Descrição:</dt>
        {/* <dd class="col-sm-9">{data.description}</dd> */}
        <dd class="col-sm-9">{data.description}</dd>
        <dt class="col-sm-3">Objetivos:</dt>
        <dd class="col-sm-9">{data.objectives}</dd>
        <dt className="col-sm-3">Online:</dt>
        <dd className="col-sm-9">
          {data.online.props.id === "true" ? (
            <>
              {data.online} | {data.online_hours} hours
            </>
          ) : (
            data.online
          )}
        </dd>
        <dt className="col-sm-3">Presencial</dt>
        <dd className="col-sm-9">
          {data.presential.props.id === "true" ? (
            <>
              {data.presential} | {data.presential_hours} hours
            </>
          ) : (
            data.presential
          )}
        </dd>
        <dt className="col-sm-3">Formações associadas:</dt>
        <dd class="col-sm-9">
          {data.associated_training.length > 0 ? (
            <DataAssociatedFormat data={data.associated_training} />
          ) : (
            "-"
          )}
        </dd>
        <dt className="col-sm-3">Mais informações:</dt>
        <dd className="col-sm-9">
          <LinkR to="/contactos">
            <FaMailBulk size="30px" color="red" />
          </LinkR>
        </dd>
      </dl>
    </>
  );
};

const DataAssociatedFormat = (data) => {
  //console.log("data", data);
  return (
    <>
      {data.data.map((item, index) => (
        <p key={index}>{item}</p>
      ))}
    </>
  );
};
