import React from "react";
import CareersForm from "../components/Careers/CareersForm";
import HomeCareers from "../components/Careers/HomeCareers";
import CareersForm2 from "../components/Careers/CareersForm2";

const Careers = () => {
  return (
    <>
      <HomeCareers />
      <CareersForm2 />
    </>
  );
};

export default Careers;
