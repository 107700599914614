import React from "react";
import HomeCourses from "../components/Courses/home";
import Catalog from "../components/Courses/catalog";
import { Link as LinkR, withRouter, useLocation } from "react-router-dom";

const Formation = () => {
  let location = useLocation();

  return (
    <>
      <HomeCourses url={location.pathname} />
      <Catalog url={location.pathname} />
    </>
  );
};

export default Formation;
