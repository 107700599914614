import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useTranslation } from "react-i18next";
import { FaQuoteLeft } from "react-icons/fa";
import "./MissionElement.css";

const Testimonials2 = () => {
	const { t } = useTranslation(["common"]);

	// const responsive = {
	// 	0: { items: 1 },
	// 	568: { items: 2 },
	// 	1024: { items: 3 },
	// };

	const items = [
		<>
			<blockquote className="quote2">
				<FaQuoteLeft className="quote-icon2" />{" "}
				{t("common:about-page.testimonials.test1_text")}
			</blockquote>
			<div class="source2">
				<p class="people2">
					<span class="name2">
						{" "}
						{t("common:about-page.testimonials.test1_author")}
					</span>
				</p>
			</div>
		</>,
		<>
			<blockquote className="quote2">
				<FaQuoteLeft className="quote-icon2" />{" "}
				{t("common:about-page.testimonials.test2_text")}
			</blockquote>
			<div class="source2">
				<p class="people2">
					<span class="name2">
						{" "}
						{t("common:about-page.testimonials.test2_author")}
					</span>
				</p>
			</div>
		</>,
		<>
			<blockquote className="quote2">
				<FaQuoteLeft className="quote-icon2" />{" "}
				{t("common:about-page.testimonials.test3_text")}
			</blockquote>
			<div class="source2">
				<p class="people2">
					<span class="name2">
						{" "}
						{t("common:about-page.testimonials.test3_author")}
					</span>
				</p>
			</div>
		</>,
		<>
			<blockquote className="quote2">
				<FaQuoteLeft className="quote-icon2" />{" "}
				{t("common:about-page.testimonials.test4_text")}
			</blockquote>
			<div class="source2">
				<p class="people2">
					<span class="name2">
						{" "}
						{t("common:about-page.testimonials.test4_author")}
					</span>
				</p>
			</div>
		</>,
		<>
			<blockquote className="quote2">
				<FaQuoteLeft className="quote-icon2" />{" "}
				{t("common:about-page.testimonials.test5_text")}
			</blockquote>
			<div class="source2">
				<p class="people2">
					<span class="name2">
						{" "}
						{t("common:about-page.testimonials.test5_author")}
					</span>
				</p>
			</div>
		</>,
		<>
			<blockquote className="quote2">
				<FaQuoteLeft className="quote-icon2" />{" "}
				{t("common:about-page.testimonials.test6_text")}
			</blockquote>
			<div class="source2">
				<p class="people2">
					<span class="name2">
						{" "}
						{t("common:about-page.testimonials.test6_author")}
					</span>
				</p>
			</div>
		</>,
		<>
			<blockquote className="quote2">
				<FaQuoteLeft className="quote-icon2" />{" "}
				{t("common:about-page.testimonials.test7_text")}
			</blockquote>
			<div class="source2">
				<p class="people2">
					<span class="name2">
						{" "}
						{t("common:about-page.testimonials.test7_author")}
					</span>
				</p>
			</div>
		</>,
		<>
			<blockquote className="quote2">
				<FaQuoteLeft className="quote-icon2" />{" "}
				{t("common:about-page.testimonials.test8_text")}
			</blockquote>
			<div class="source2">
				<p class="people2">
					<span class="name2">
						{" "}
						{t("common:about-page.testimonials.test8_author")}
					</span>
				</p>
			</div>
		</>,
		<>
			<blockquote className="quote2">
				<FaQuoteLeft className="quote-icon2" />{" "}
				{t("common:about-page.testimonials.test9_text")}
			</blockquote>
			<div class="source2">
				<p class="people2">
					<span class="name2">
						{" "}
						{t("common:about-page.testimonials.test9_author")}
					</span>
				</p>
			</div>
		</>,
		<>
			<blockquote className="quote2">
				<FaQuoteLeft className="quote-icon2" />{" "}
				{t("common:about-page.testimonials.test10_text")}
			</blockquote>
			<div class="source2">
				<p class="people2">
					<span class="name2">
						{" "}
						{t("common:about-page.testimonials.test10_author")}
					</span>
				</p>
			</div>
		</>,
	];

	return (
		<>
			<section className="section" id="testimonials">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 text-center">
							<div className="title-box">
								<h2 className="title-alt">{t("common:testimonials.title2")}</h2>
								<h3 className="fadeIn animated wow" data-wow-delay=".1s">
									{t("common:about-page.clients_text")}
								</h3>
								<div className="border"></div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-8 offset-lg-2 text-center">
							<AliceCarousel
								autoPlay
								// autoPlayControls
								//autoPlayStrategy="none"
								autoPlayInterval={1500}
								animationDuration={300}
								animationType="fadeout"
								infinite
								touchTracking={false}
								//disableDotsControls
								disableButtonsControls
								items={items}
							/>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Testimonials2;
