import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function useLanguage() {
	const { i18n } = useTranslation();
	const [language, setLanguage] = useState("");
	const [isLanguage, setIsLanguage] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const languageLocalStorage = localStorage.getItem("language-opco");
		//console.log("use effect", i18n.language);
		if (languageLocalStorage) {
			//console.log("TOKEN", token);
			setLanguage(languageLocalStorage);
			setIsLanguage(true);
			i18n.changeLanguage(languageLocalStorage);
		} else {
			setLanguage(i18n.language);
			setIsLanguage(true);
		}
		setLoading(false);
	}, [i18n]);

	function handleLanguage(value) {
		i18n.changeLanguage(value);
		setLanguage(value);
		setIsLanguage(true);
		localStorage.setItem("language-opco", value);
	}

	return {
		handleLanguage,
		language,
		isLanguage,
		loading,
	};
}
