/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import '../../redux/formationsCatalogSlice';
import { filterButtons } from '../../redux/formationsCatalogSlicev2';
import { getFormations } from '../../redux/formationsCatalogSlicev2';
import Pagination from '../Pagination/Pagination';
import Spinner from '../Spinner/Spinner';
import CardCourseComponent from './cardCourseComponent';
import './courses.css';

const CenterContainerCourses = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const catalogData = useSelector(
    (state) => state.formationCatalog2.fileredData,
  );
  const catalogDataStatus = useSelector(
    (state) => state.formationCatalog2.status,
  );
  const [clickedId, setClickedId] = useState(0);
  // User is currently on this page
  const [currentPage, setCurrentPage] = useState(1); // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = catalogData.slice(
    indexOfFirstRecord,
    indexOfLastRecord,
  );
  const nPages = Math.ceil(catalogData.length / recordsPerPage);

  const handleClick = (event, id) => {
    event.preventDefault();
    setClickedId(id);
    dispatch(filterButtons(id));
  };
  useEffect(() => {
    dispatch(getFormations());
  }, [dispatch]);

  const handleCourseClick = (e, item) => {
    e.preventDefault();
  };
  const links = [
    t('common:courses-page.complete-list'),
    'OFICIAL OPCO',
    'VDA-QMC',
    'SMMT INDUSTRY',
    'ODETTE',
    'FIEV',
    'STELLANTIS',
    'OFICIAL AIAG E PLEXUS',
  ];

  return (
    <>
      <div className="row">
        <nav className="nav nav-pills justify-content-center ">
          {links.map((label, index) => (
            <a
              className={
                index === clickedId
                  ? 'nav-link active pills fw-bolder text-danger'
                  : 'nav-link pill fw-bolder text-danger'
              }
              aria-current="page"
              href="#"
              key={index}
              name={label}
              onClick={(e) => handleClick(e, index)}
            >
              {label}
            </a>
          ))}
        </nav>
      </div>
      {catalogDataStatus !== 'Fulfilled' ? <Spinner /> : ''}
      <div className="row pt-4">
        {currentRecords.map((item, id) => {
          return (
            <CardCourseComponent
              item={item}
              id="id"
              onClick={(e) => handleCourseClick(e, item)}
            />
          );
        })}
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default CenterContainerCourses;
