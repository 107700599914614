import React from "react";
import Footer from "../Footer";
import Navbar3 from "../Navbar3";
import "./notfound.css";
import { Link as LinkR } from "react-router-dom";
import { FcCalendar, FcHome, FcSupport } from "react-icons/fc";

const NotFound = () => {
	return (
		<>
			<Navbar3/>
			<section className="section" id="home">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<div class="error-template">
								<h1>Oops!</h1>
								<h2>404 Página não encontrada</h2>
								<div class="error-details">
									Pedimos desculpa, mas ocorreu um erro com a página solicitada!
								</div>
								<div class="error-actions">
									<LinkR
										className="btn btn-outline-danger rounded formation-link"
										to="/"
									>
										<strong className="text-primary">Homepage</strong>{" "}
										<FcHome size={30} />
									</LinkR>
									<LinkR
										className="btn btn-outline-danger rounded formation-link"
										to="/contactos"
									>
										<strong>Suporte</strong> <FcSupport size={30} />
									</LinkR>
									{/* <a
										href="http://www.jquery2dotnet.com"
										class="btn btn-primary btn-lg"
									>
										<span class="glyphicon glyphicon-home"></span>
										Take Me Home{" "}
									</a>
									<a
										href="http://www.jquery2dotnet.com"
										class="btn btn-default btn-lg"
									>
										<span class="glyphicon glyphicon-envelope"></span> Contact
										Support{" "}
									</a> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div>
					<h1 className="text-center">404 - Not Found!</h1>
					<Link to="/">Go Home</Link>
				</div> */}
			</section>
			<Footer />
		</>
	);
};

export default NotFound;
