import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { FaGlobe } from 'react-icons/fa';
import { FcCalendar } from 'react-icons/fc';
import { Link as LinkR } from 'react-router-dom';
import { Link } from 'react-scroll';
import ReactTooltip from 'react-tooltip';

import { Context } from '../../context/LanguageContext';
// import { MdMenu } from "react-icons/md";
import logo from '../../images/logo.png';
import ButtonFormations from '../ButtonFormation/ButtonFormations';
import ButtonShopCart from '../ButtonShopCart/ButtonShopCart';
import ButtonTesting from '../ButtonTesting/ButtonTesting';
import './NavbarShop.css';

const NavbarShop = () => {
  const { language, handleLanguage } = useContext(Context);
  const { t } = useTranslation(['common']);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg fixed-top navbar-custom sticky-dark"
        id="navbar-sticky"
      >
        <div className="container">
          <LinkR className="navbar-brand logo" to="/">
            <img src={logo} alt="" className="logo" />
          </LinkR>
          {/* <a className="navbar-brand logo" href="">
						{" "}
						<img src={logo} alt="" className="logo" />
					</a> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <MdMenu /> */}
            <i className="zmdi zmdi-menu"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ml-auto">
              <li className="nav-item dropdown pr-3">
                <a
                  className="nav-link dropdown-toggle"
                  href={() => false}
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {' '}
                  {t('common:about-page.navbar.item6')}
                </a>

                <ul
                  className="dropdown-menu arrow"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <LinkR className="dropdown-item" to="/" id="homepage">
                      Homepage
                    </LinkR>
                  </li>
                  <li>
                    <LinkR
                      className="dropdown-item"
                      to="/noticias"
                      id="noticias"
                    >
                      Notícias
                    </LinkR>
                  </li>
                  <li>
                    <LinkR className="dropdown-item" to="/formacao">
                      {t('common:navbar.item10')}
                    </LinkR>
                  </li>
                  <li>
                    <LinkR
                      className="dropdown-item"
                      to="/formacao-agendadas"
                      id="events"
                    >
                      {t('common:navbar.item11')}
                    </LinkR>
                  </li>
                  <li>
                    <LinkR className="dropdown-item" to="/about" id="about">
                      {t('common:navbar.item8')}
                    </LinkR>
                  </li>
                  <li>
                    <LinkR className="dropdown-item" to="/testes">
                      {t('common:navbar.testsButton')}
                    </LinkR>
                  </li>
                  <li>
                    <LinkR className="dropdown-item" to="/aplicacoes">
                      Applications
                    </LinkR>
                  </li>
                  <li>
                    <LinkR
                      className="dropdown-item"
                      to="/contactos"
                      id="courses"
                    >
                      {t('common:navbar.item9')}
                    </LinkR>
                  </li>
                </ul>
              </li>
              <li
                className="pr-4"
                data-tip={t('common:navbar.testTooltip')}
                data-type="info"
              >
                <ButtonTesting />
                <ReactTooltip />
              </li>
              <li
                className="pr-3"
                data-tip={t('common:navbar.item10')}
                data-type="info"
              >
                <ButtonFormations />
                <ReactTooltip />
              </li>
              <li
                className="pr-3"
                data-tip={t('common:navbar.shopTooltip')}
                data-type="info"
              >
                <ButtonShopCart />
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href={() => false}
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {' '}
                  <IconContext.Provider
                    value={{ color: '#e9372e', size: '2em' }}
                  >
                    <FaGlobe />
                  </IconContext.Provider>
                </a>

                <ul
                  className="dropdown-menu arrow"
                  aria-labelledby="navbarDropdown"
                >
                  <li className="langugeLi">
                    <Link onClick={() => handleLanguage('pt')} to="">
                      {'pt' === language ? <b>Português</b> : 'Português'}
                    </Link>
                  </li>
                  <li className="langugeLi">
                    <Link onClick={() => handleLanguage('en')} to="">
                      {'en' === language ? <b>English</b> : 'English'}
                    </Link>
                  </li>
                  <li className="langugeLi">
                    <Link onClick={() => handleLanguage('de')} to="">
                      {'de' === language ? <b>Deutsch</b> : 'Deutsch'}
                    </Link>
                  </li>
                  <li className="langugeLi">
                    <Link onClick={() => handleLanguage('fr')} to="">
                      {'fr' === language ? <b>Français</b> : 'Français'}
                    </Link>
                  </li>
                  <li className="langugeLi">
                    <Link onClick={() => handleLanguage('es')} to="">
                      {'es' === language ? <b>Español</b> : 'Español'}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarShop;
// import React from "react";
// import { Link } from "react-router-dom";
// class NavbarShop extends React.Component {
// 	render() {
// 		return (
// 			<nav class="navbar navbar-expand-lg navbar-light bg-light">
// 				<div class="container px-4 px-lg-5">
// 					<Link class="navbar-brand" to={"/home"}>
// 						TheRich Shop
// 					</Link>

// 					<button
// 						class="navbar-toggler"
// 						type="button"
// 						data-bs-toggle="collapse"
// 						data-bs-target="#navbarSupportedContent"
// 						aria-controls="navbarSupportedContent"
// 						aria-expanded="false"
// 						aria-label="Toggle navigation"
// 					>
// 						<span class="navbar-toggler-icon"></span>
// 					</button>
// 					<div class="collapse navbar-collapse" id="navbarSupportedContent">
// 						<ul class="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-4">
// 							<li class="nav-item">
// 								<Link class="nav-link active" to={"/home"}>
// 									Home
// 								</Link>
// 							</li>
// 							<li class="nav-item dropdown">
// 								<a
// 									class="nav-link dropdown-toggle"
// 									id="navbarDropdown"
// 									href="#"
// 									role="button"
// 									data-bs-toggle="dropdown"
// 									aria-expanded="false"
// 								>
// 									Shop
// 								</a>
// 								<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
// 									<li>
// 										<a class="dropdown-item">All Products</a>
// 									</li>
// 									<li>
// 										<hr class="dropdown-divider" />
// 									</li>
// 									<li>
// 										<a class="dropdown-item">Popular Items</a>
// 									</li>
// 									<li>
// 										<a class="dropdown-item">New Arrivals</a>
// 									</li>
// 								</ul>
// 							</li>
// 						</ul>
// 					</div>
// 				</div>
// 			</nav>
// 		);
// 	}
// }
// export default NavbarShop;
