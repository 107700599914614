import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMailBulk } from 'react-icons/fa';
import { Link as LinkR } from 'react-router-dom';

import { Context } from '../../context/LanguageContext';
import temp_webp from '../../images/temp.webp';
import Footer from '../Footer';
import Spinner from '../Spinner/Spinner';
import { Aside } from './aside';
import HomeCourses from './home2';
import NavbarCourses from './navbarCourses';
import './singleCourse.css';

const SingleCourse = ({ course }) => {
  const { t } = useTranslation(['common']);
  const { language } = useContext(Context);

  let data = course.fields;
  let imgSrc = data.hasOwnProperty('image')
    ? data.image.fields.file.url
    : temp_webp;
  console.log('Course:', course);
  console.log('Data:', data);
  if (course === undefined) {
    return (
      <>
        <Spinner />
      </>
    );
  }
  return (
    <>
      {course && (
        <>
          {/* <NavbarCourses /> */}
          <HomeCourses
            courseName={language === 'pt' ? data.title : data.titleEn}
          />
          <div className="container col-sm-8">
            <div class="row">
              <div class="col-12 d-flex justify-content-center p-4 ">
                <div className="col-md-6">
                  <div class="card bg-dark text-white image-card bborder-secondary mb-3">
                    <img src={imgSrc} class="card-img" alt="..." />
                    <div class="card-img-overlay">
                      <p class="card-text text-card-red align-text-bottom text-center">
                        <b>{language === 'pt' ? data.title : data.titleEn}</b>
                      </p>
                    </div>
                  </div>
                </div>
                {/* <img
                  //   height="100px"
                  //   width="100px"
                  alt="thumbnail1"
                  src={data.image.fields.file.url}
                  className="rounded"
                /> */}
              </div>
            </div>
            <dl class="row">
              <dt className="col-sm-3">
                {t('common:single-formation.designation')}:
              </dt>
              <dd className="col-sm-9">
                {language === 'pt' ? data.title : data.titleEn}
              </dd>
              <dt class="col-sm-3">
                {t('common:single-formation.description')}:
              </dt>
              {/* <dd class="col-sm-9">{data.description}</dd> */}
              <dd class="col-sm-9">
                {language === 'pt' ? data.description : data.descriptionEn}
              </dd>
              <dt class="col-sm-3">
                {' '}
                {t('common:single-formation.objectives')}:
              </dt>
              <dd class="col-sm-9">
                {language === 'pt' ? data.objective : data.objectiveEn}
              </dd>
              <dt className="col-sm-3">
                {' '}
                {t('common:single-formation.online')}:
              </dt>
              <dd className="col-sm-9">
                {data.online ? (
                  <>
                    {/* {data.online} | {data.onlineHours} hours */}
                    {data.onlineHours} h
                  </>
                ) : (
                  '-'
                )}
              </dd>
              <dt className="col-sm-3">
                {' '}
                {t('common:single-formation.presential')}:
              </dt>
              <dd className="col-sm-9">
                {data.presential ? <>{data.prensentialHours} h</> : '-'}
              </dd>
              <dt className="col-sm-3">
                {' '}
                {t('common:single-formation.associated-formations')}:
              </dt>
              <dd class="col-sm-9">
                {data.associatedFormations === !undefined ? (
                  <DataAssociatedFormat data={data.associatedFormations} />
                ) : (
                  '-'
                )}
              </dd>
              <dt className="col-sm-3">
                {' '}
                {t('common:single-formation.more-information')}:
              </dt>
              <dd className="col-sm-9">
                <LinkR to="/contactos">
                  <FaMailBulk size="30px" color="red" />
                </LinkR>
              </dd>
              {'testing_link' in data ? (
                <>
                  <dt className="col-sm-3">Teste Diagnóstico:</dt>
                  <dd className="col-sm-9">
                    <a href={data.testing_link} target="_blank">
                      Link
                    </a>
                  </dd>
                </>
              ) : (
                console.log('key not exists')
              )}
            </dl>
          </div>
          {/* <Footer /> */}
        </>
      )}
    </>
  );
};
const DataAssociatedFormat = (data) => {
  //console.log("data", data);
  return (
    <>
      {data.data.map((item, index) => (
        <p key={index}>{item}</p>
      ))}
    </>
  );
};

export default SingleCourse;
