import React from 'react';
import Image from 'react-image-webp';
import { Link as LinkR } from 'react-router-dom';

import project from '../../files/project.pdf';
import imagew from '../../images/projectbanner/imagew.webp';
import image from '../../images/projectbanner/projectbanner.png';
import './Projectbanner.css';

const ProjectBanner = ({ backgroundGrey }) => {
  //console.log(backgroundGrey);
  const handleClick = () =>
    window.open(
      'https://opco.pt/wp-content/uploads/2019/10/Ficha-Projeto-2020.pdf',
      '_blank',
    );
  return (
    <>
      <section className={backgroundGrey ? 'home' : ''} id="">
        <div className="text-center">
          <LinkR to={project} target="_blank">
            <Image
              src={image}
              webp={imagew}
              alt="project banner"
              className="img-fluid mx-auto d-block projectbanner rounded"
              // onClick={handleClick}
            />
          </LinkR>
        </div>
      </section>
    </>
  );
};

export default ProjectBanner;
