import {
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiLogoutBoxRLine,
} from "react-icons/ri";
import vda from "../../images/partners/vda.png";
import oddete from "../../images/partners/odette.jpg";
import aiag from "../../images/partners/aiag.jpg";
import renault from "../../images/partners/renault.jpg";
import psa from "../../images/partners/PSA.jpg";
import fiev from "../../images/partners/fiev.jpg";
import smmt from "../../images/partners/smmt.jpg";
import opco from "../../images/logo2.png";
const yes = (
  <RiCheckboxCircleLine
    data-tag="allowRowEvents"
    id="true"
    size="20px"
    color="green"
  />
);
const no = (
  <RiCloseCircleLine
    data-tag="allowRowEvents"
    id="false"
    size="20px"
    color="red"
  />
);

const data = [
  {
    id: 1,
    title: "5’S E GESTÃO VISUAL",
    url: "5-S-E-GESTAO-VISUAL",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Os 5 ́S e a Gestão Visual são técnicas baseadas na melhoria contínua. Os 5 ́S envolvem todos os que trabalham na organização e que, com regra e disciplina, promovem o fluxo de materiais, melhoram as condições de trabalho, de ambiente e de segurança.",
    objectives:
      "Dotar os formandos de competências necessárias à operacionalização das ferramentas que permitem garantir um sistema contínuo de 5`S e de gestão visual do local de trabalho e da organização, com o objetivo de melhorar a qualidade e a produtividade.",
    online: yes,
    presential: yes,
    online_hours: "4",
    presential_hours: "16",
    associated_training: [
      "5’S – Programa para facilitadores",
      "5’S – Sensibilização",
    ],
  },
  {
    id: 2,
    title: "VSM - ANÁLISE DE CADEIA DE VALOR",
    url: "VSM-ANALISE-DE-CADEIA-DE-VALOR",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O VSM (Value Stream Mapping) é um método muito útil e tem sido um dos mais utilizados no universo de aplicações do lean thinking em empresas indústriais e de serviços.",
    objectives:
      "Dotar os formandos de competências necessárias para caraterizar a cadeia de valor e planear as ações de melhoria a fim de satisfazer as necessidades dos clientes, desde as relações com os fornecedores e ciclos de produção e venda até a fase da distribuição para o consumidor final. Cada elo dessa cadeia de atividades está interligado.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 3,
    title: "EFICÁCIA DE EQUIPAMENTOS – OEE",
    url: "EFICACIA-DE-EQUIPAMENTOS–OEE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A maioria dos fabricantes não conseguem identificar as principais perdas que contribuem para a baixa produtividade das máquinas. Mediante o indicador OEE consegue-se determinar a verdadeira eficiência operacional, melhorando a disponibilidade e fiabilidade dos equipamentos e atuando sobre as causas das perdas.",
    objectives:
      "Dotar os formandos dos conhecimentos necessários à operacionalização das métricas e conceitos aplicados aos equipamentos OEE, assim como de competências para monitorizar e melhorar a eficiência dos processos de fabrico tal como otimizar os processos de manutenção e produção da empresa.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 4,
    title: "ESTANDARDIZAÇÃO DO TRABALHO",
    url: "ESTANDARDIZACAO-DO-TRABALHO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A Estandardização é o processo de desenvolvimento e implementação de normas técnicas. Tem como objetivo definir especificações técnicas que auxiliem na maximização da compatibilidade, produtividade, segurança ou qualidade de determinado processo, produto ou serviço.",
    objectives:
      "Dotar os formandos dos conhecimentos necessários à operacionalização das métricas e conceitos aplicados à estandardização do trabalho, assim como de competências para identificar as vantagens destes métodos e ferramentas.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 5,
    title: "JUST IN TIME_KANBAN - SEQUENCIAÇÃO",
    url: "JUST-IN-TIME-KANBAN-SEQUENCIACAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Metodologia de programação de compras, de produção e de controlo de inventário extremamente precisa, baseada em cartões que permitem o controlo visual da posição de inventário e planeamento de produção de qualquer item, a qualquer momento.",
    objectives:
      "Dotar os formandos de competências nas áreas do JIT, Kanban e sequenciação, assim como de conhecimentos necessários para a operacionalização dos métodos logísticos que visam minimizar inventários.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 6,
    title: "KAIZEN - MELHORIA CONTÍNUA",
    url: "KAIZEN-MELHORIA-CONTINUA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A metodologia Kaizen é baseada no princípios socioculturais do oriente, que exige um comprometimento de todos os trabalhadores da empresa no sentido da melhoria contínua, com base numa forma de gestão orientada para a maximização da produtividade e rentabilidade, e consequente redução de custos.",
    objectives:
      "Visa dotar os formandos dos conhecimentos e práticas necessárias de forma a aumentar a produtividade pela simplificação de processos; compreender a filosofia Kaizen de melhoria Contínua baseada nos princípios de relações de causa efeito, resolução de problemas e potenciar o desenvolvimento do indivíduo na empresa como fonte de inovação e melhoria.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 7,
    title: "LEAN MANAGEMENT",
    url: "LEAN-MANAGEMENT",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O Lean Management é uma filosofia de gestão centrada na melhoria da produtividade, reduzindo ou eliminando custos e tempos, com vista a promover as atividades que realmente acrescentam valor para o cliente.",
    objectives:
      "Tem como objetivo familiarizar os formandos sobre os métodos focados na redução de desperdícios, redução de inventário e as necessidades a nível de espaço; potenciar a existência de sistemas de produção robustos, desenvolver um adequado sistema de abastecimento, interno e externo e melhorar os layouts e aumenta a flexibilidade.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 8,
    title: "LEAN BASICS",
    url: "LEAN-BASICS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Ferramenta de grande aplicabilidade profissional no domínio da gestão dos processos empresariais, promovendo decisões que tenham em conta os índices de produtividade e o acrescentar de valor à oferta atual das organizações envolvidas.",
    objectives:
      "Dotar os formandos de conhecimentos sobre as atividades que não acrescentam valor assim como de técnicas de aplicação das ferramentas essenciais associadas à filosofia Lean. Capacitar para a condução de um processo Lean na sua organização e aplicar os princípios da melhoria contínua.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 9,
    title: "LEAN SIX SIGMA",
    url: "LEAN-SIX-SIGMA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O Lean tradicionalmente foca-se na eliminação dos oito tipos de resíduos, superprodução, espera, talento não utilizado, transporte, inventário, movimento e processamento extra. O Six Sigma procura melhorar a qualidade dos resultados do processo, identificando e removendo as causas dos defeitos (erros) e minimizando a variação nos processos.",
    objectives:
      "Dotar os formandos de técnicas e métodos que permitam reduzir custos e melhorar a qualidade do produto e satisfação do cliente. Compreender a interligação entre o Lean e o Six Sigma para aumentar a produtividade.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "32",
    associated_training: [],
  },
  {
    id: 10,
    title: "LEAN LIGHT BELT",
    url: "LEAN-LIGHT-BELT",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A implementação adequada do Lean visa a eliminação dos processos e atividades que não adicionam valor para o cliente.",
    objectives:
      "Dotar os formandos de conhecimentos iniciais relativos às principais ferramentas da Qualidade e do Lean, qualificar os formandos para a participação em projetos de melhoria de acordo com a metodologia Lean.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 11,
    title: "LEAN GREEN BELT",
    url: "LEAN-GREEN-BELT",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A implementação adequada do Lean visa a eliminação dos processos e atividades que não adicionam valor para o cliente, através da formação ao nível operacional de Green Belts. Estes desempenham um papel vital no programa Lean pois trabalham diretamente na recolha e análise de dados, no mapeamento dos processos e nos projetos de melhoria.",
    objectives:
      "Aprofundar os conhecimentos iniciais relativos às principais ferramentas da Qualidade e do Lean, assim como qualificar para a liderança eficaz de equipas de melhoria, tendo por base a metodologia Lean. Garantir a correta aplicação a projetos reais e com resultados tangíveis.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "32",
    associated_training: [],
  },
  {
    id: 12,
    title: "TPM - MANUTENÇÃO PRODUTIVA TOTAL",
    url: "TPM-MANUTENCAO-PRODUTIVA-TOTAL",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A TPM tem como objetivo diminuir os custos de produção, diminuir as perdas e melhorar a qualidade total dos produtos. O processo produtivo deve ter zero falhas, assim como zero defeitos e zero quebras de equipamentos.",
    objectives:
      "Visa dotar os participantes dos conhecimentos que permitam a melhoria da eficiência global dos equipamentos aumentando a rentabilidade dos processos e a produtividade assim como conseguir zero avarias e processos mais flexíveis.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 13,
    title: "SMED - MUDANÇA RÁPIDA DE FERRAMENTAS",
    url: "SMED-MUDANCA-RAPIDA-DE-FERRAMENTAS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O SMED, ou Quick Changeover, permite reduzir o tempo de paragem de um equipamento para mudança de referência, aplicando técnicas simples e com baixo investimento inicial. O SMED organiza e simplifica o trabalho da preparação e da mudança das ferramentas, permitindo a melhoria da produtividade e a rentabilidade dos processos.",
    objectives:
      "Dotar os formandos de conhecimentos necessários à implementação das técnicas de mudança rápida de referências e ferramentas, eliminando sistematicamente os tempos de paragem das máquinas, flexibilizando as operações para a obtenção de ganhos de capacidade, eficiência e melhoria da qualidade.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 14,
    title: "PRODUÇÃO PUXADA PULL SYSTEM",
    url: "PRODUCAO-PUXADA-PULL-SYSTEM",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A produção puxada controla as operações sem recorrer a utilização de inventário em processo. Neste tipo de produção o consumo do cliente é que determina a quantidade produzida, gerando o que chamamos de sistema com nível mínimo de inventário.",
    objectives:
      "Visa dotar os participantes dos conhecimentos sobre as diferentes práticas para a montagem e organização dos sistemas de produção industriais com foco na produção puxada, permitindo aos mesmos o entendimento de como os sistemas produtivos puxados podem vir a garantir estrategicamente a eficácia produtiva.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 15,
    title: "POKA YOKE - SISTEMA ANTI ERRO",
    url: "POKA-YOKE-SISTEMA-ANTI-ERRO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Este sistema de inspeção é desenvolvido para prevenir riscos de falhas humanas e corrigir eventuais erros em processos industriais, sempre por meio de ações simples.",
    objectives:
      "Dotar os formandos das competências necessárias à operacionalização de um método que procure eliminar os defeitos causados por falhas ou erros humanos, mediante a otimização ou automatização das tarefas que necessariamente requerem a atenção por parte do operador, visando a minimização dos erros ou até a eliminação de peças defeituosas.",
    online: yes,
    presential: yes,
    online_hours: "4",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 16,
    title: "VA/VE - ANÁLISE DE VALOR",
    url: "VA/VE-ANALISE-DE-VALOR",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A necessidade de redução de custos e de adição de valor ao cliente e ao negócio, com as mudanças constantes e aceleradas de fatores internos e externos que influenciam a empresa, tornam obrigatório a busca de métodos e técnicas de trabalho que visem um maior controlo dos custos, sem que isto afete a qualidade dos resultados das empresas.",
    objectives:
      "Dotar os participantes de conhecimentos necessários à operacionalização da metodologia utilizada para a identificação de funções e o relacionamento com os custos para realizar cada função visando identificar e eliminar custos desnecessários. Dar a conhecer esta técnica eficaz na melhoria dos desempenhos funcionais e na consideração dos recursos necessários.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 17,
    title: "PLANEAMENTO AVANÇADO DE LAYOUTS",
    url: "PLANEAMENTO-AVANCADO-DE-LAYOUTS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Planeamento e optimização da sincronização dos fluxos de material, com recurso aos conhecimentos teóricos/práticos do Lean de modo a detetar em cada layout as fontes que continuem a originar os desperdícios.",
    objectives:
      "Dotar os participantes de competências ao nivel do planeamento e desenvolvimento de Layouts utilizando as ferramentas Lean, com o objetivo de reduzir custos de movimentação de materiais e gerar aumento de produtividade pelo balanceamento das linhas de produção.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "32",
    associated_training: [],
  },
  {
    id: 18,
    title: "20 CHAVES - KAIZEN",
    url: "20-CHAVES-KAIZEN",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "As 20 Chaves de Excelência movem a empresa em relação à melhoria contínua dentro do trinómio: Custo, Qualidade e Prazo, numa estrutura de equipas multidisciplinares de melhoria contínua.",
    objectives:
      "Visa dotar os formandos dos conhecimentos e práticas necessárias de forma a aumentar a produtividade pela implementação da simplificação de processos. Compreender a filosofia Kaizen de Melhoria Contínua baseada nos princípios de relações de causa-efeito, resolução de problemas e potenciar o desenvolvimento do indivíduo na empresa como fonte de inovação e melhoria.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 19,
    title: "DFM/DFA - DESIGN FOR MANUFACTURING AND ASSEMBLY",
    url: "DFM/DFA-DESIGN-FOR-MANUFACTURING-AND-ASSEMBLY",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O DFMA é uma combinação de duas metodologias, Design for Manufacturing (DFM) e Design for Assembly (DFA). Essa combinação permite que um produto seja eficientemente desenvolvido de forma a facilitar o fabrico e a montagem.",
    objectives:
      "Dotar os formandos de competências sobre o DFM/DFA, para que possam usar esta técnica tanto em processo manual como em processo automático, resultando em redução de custos significativos e em processos produtivos mais robustos e com menores custos.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 20,
    title: "A3 - MÉTODO DE RESOLUÇÃO DE PROBLEMAS",
    url: "A3-METODO-DE-RESOLUCAO-DE-PROBLEMAS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O relatório A3 é uma ferramenta que procura reconhecer e apoiar a resolução de problemas. Através dela, é possível identificar a causa, a sua natureza e as medidas de melhoria possíveis.",
    objectives:
      "Capacitar os formandos com competências de resolução de problemas / melhoria contínua de modo a estarem aptos a compreender os passos de resolução de problemas com o ciclo PDCA; saber como usar a A3 em todos os níveis da organização; a estabelecer a aplicabilidade de cada uma das ferramentas e, também, a promover uma alteração cultural associada à melhoria contínua assente no ciclo PDCA.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 21,
    title: "8D'S - MÉTODO DE RESOLUÇÃO DE PROBLEMAS",
    url: "8D-S-METODO-DE-RESOLUCAO-DE-PROBLEMAS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Lean/Produtividade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Saber aplicar a metodologia 8D como resposta a solicitações/exigências específicas dos clientes (tendencialmente obrigatória no setor automóvel) e/ou como abordagem para o tratamento de não conformidades internas ou externas (suscetíveis de elevados prejuízos e perdas de rentabilidade e eficiência operacional).",
    objectives:
      "Dotar os formandos das competências que ajudem a abordar os problemas de forma sistemática e compreender os passos necessários para corrigir e evitar novos problemas; entender e aplicar as principais ferramentas da qualidade e o método de resolução de problemas das 8 disciplinas, mediante o uso de técnicas gráficas e / ou estatísticas apropriadas.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 22,
    title: "MSA - ANÁLISE DOS SISTEMAS DE MEDIÇÃO",
    url: "MSA-ANALISE-DOS-SISTEMAS-DE-MEDICAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A Análise do Sistema de Medição (MSA) consiste em estudos estatísticos que analisam as variações nos processos de medição e identificam quanto dessa variação é originada ato de medição, de modo a assegurar a integridade dos dados utilizados e compreender as implicações dos erros de medição para as decisões tomadas sobre um produto ou processo.",
    objectives:
      "Dotar os participantes de conhecimentos técnicos/práticos sobre metodologias de análise dos sistemas de medição e monitorização no seu ambiente de trabalho, conduzindo estudos estatísticos, incluindo a linearidade, estabilidade, repetibilidade e reprodutibilidade (R&R) e interpretando as várias fontes de variação da medição por forma a garantir a fiabilidade dos dados obtidos.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
    testing_link:
      "https://docs.google.com/forms/d/e/1FAIpQLSc6-CvN0_vTyGylWawlsbGGRzfPTBuT7kG3wpNMW2wrBBPxHw/viewform?usp=sf_link",
  },
  {
    id: 23,
    title: "CORE TOOLS - FMEA, SPC E MSA",
    url: "CORE-TOOLS-FMEA-SPC-E-MSA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      'As "Core Tools": FMEA, SPC e MSA, são técnicas avançadas da qualidade e constituem uma base de conhecimento essencial para qualquer profissional da indústria automóvel. A formação permite abordar três das principais ferramentas para a indústria automóvel.',
    objectives:
      "Proporcionar aos participantes os conhecimentos necessários à utilização diária destas ferramentas, que asseguram não só a conformidade com a norma IATF 16949, mas também a melhoria contínua. Capacitar os formandos no sentido de identificar e implementar os requisitos associados às Core Tools (FMEA, SPC e MSA) de forma autónoma.",
    online: yes,
    presential: yes,
    online_hours: "24",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 24,
    title: "CUSTOS DE QUALIDADE",
    url: "CUSTOS-DE-QUALIDADE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Os custos da não qualidade estão associados à produção de material não conforme, falhas internas e falhas externas, atrasos no lançamento de novos produtos, insatisfação do consumidor, perdas de mercado.",
    objectives:
      "Dotar os participantes de competências e conhecimentos para a gestão dos aspectos económicos da Qualidade, tal como a sua quantificação e classificação com o objetivo final de implementar um sistema de custos e proceder a um plano de melhoria na organização.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 25,
    title: "REVERSE FMEA",
    url: "REVERSE-FMEA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O FMEA (Análise do Modo e Efeito da Falha) é uma abordagem metódica para avaliar, priorizar riscos sobre possíveis pontos de falha e a sua respetiva redução/eliminação. A metodologia R-FMEA é um processo estruturado de melhoria contínua que visa garantir a atualização e o progresso permanente de um estudo do FMEA com base na actual situação do processo.",
    objectives:
      "Transmitir os conhecimentos essenciais à operacionalização da ferramenta Reverse FMEA, mediante uma revisão - realizada no chão de fábrica - de todos os modos de falha incluídos no FMEA de Processo, conduzida por uma equipa multifuncional e focada em verificar se todos os modos de falha possuem controlos adequados (prevenção / deteção).",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 26,
    title: "FERRAMENTAS BÁSICAS DA QUALIDADE",
    url: "FERRAMENTAS-BASICAS-DA-QUALIDADE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Para que seja implantado o sistema de gestão da qualidade são necessárias ferramentas básicas para analisar fatos e auxiliar na tomada de decisão. Esses instrumentos são conhecidos como ferramentas da gestão da qualidade. O objetivo de utilizá-las é chegar a um grau de eficiência/eficácia em uma determinada atividade ou processo.",
    objectives:
      "Dotar os participantes de competências e técnicas para utilização das ferramentas básicas da qualidade, apostando na sua vantagem para a consolidação das tomadas de decisão.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 27,
    title: "FERRAMENTAS OPERACIONAIS DA QUALIDADE",
    url: "FERRAMENTAS-OPERACIONAIS-DA-QUALIDADE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Aprimorar produtos, processos, sistemas e projetos utilizando ferramentas de gestão de qualidade para prevenir problemas na empresa é o objetivo desta ação de formação. Apesar de serem tradicionais, podem representar uma grande inovação na forma de atuação da empresa.",
    objectives:
      "Dotar os participantes de competências e técnicas para operacionalização das ferramentas da qualidade, elementares à produção e permitindo analisar a ineficácia e os aspetos negativos de um produto ou processo em questão.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 28,
    title: "TQM - GESTÃO DA QUALIDADE TOTAL",
    url: "TQM-GESTAO-DA-QUALIDADE-TOTAL",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O TQM, ou Gestão da Qualidade Total, é um conjunto de pensamentos estratégicos que antecedem o agir e o produzir. A Gestão da Qualidade visa garantir a total eficiência e sucesso das instituições. A valorização do cliente está em primeiro lugar. Com uma abordagem ampla, tem o objetivo de tornar a empresa mais competitiva, flexível e eficaz construindo um planeamento sólido.",
    objectives:
      "Dotar os participantes de competências e conhecimentos necessários à aplicação da Gestão da Qualidade Total, identificando os principais desafios e benefícios desta estratégia de gestão orientada a criar consciência da qualidade em todos os processos organizacionais e com o objetivo de envolver fornecedores, clientes e demais parceiros de negócios.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 29,
    title: "RMM’S - GESTÃO DE RECURSOS DE MEDIÇÃO E MONITORIZAÇÃO",
    url: "RMM-S-GESTAO-DE-RECURSOS-DE-MEDICAO-E-MONITORIZACAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O desgaste natural dos equipamentos de medição pode ser causador de inúmeros incidentes. Desde o projeto até à utilização no dia-a-dia, a e fiabilidade dos resultados obtidos é a maior prioridade. Devido à grande complexidade de muitos sistemas, apenas inspetores altamente qualificados e dotados de uma variedade de competências podem realizar e interpretar medições com eficiência.",
    objectives:
      "Dotar os participantes de competências e conhecimentos necessários para a identificação e gestão de equipamentos de medição e monitorização, sua calibração e enquadramento normativo.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 30,
    title: "GESTÃO DAS RECLAMAÇÕES E SATISFAÇÃO DE CLIENTES",
    url: "GESTAO-DAS-RECLAMACOES-E-SATISFACAO-DE-CLIENTES",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A avaliação da satisfação de clientes é uma importante ferramenta de monitorização da perceção de como o cliente avalia a nossa empresa. As reclamações tem que ser corretamente tratadas para melhorar a reputação de uma organização, independentemente da dimensão, localização e setor.",
    objectives:
      "Dotar os formandos de competências necessárias ao tratamento das reclamações que podem levar a melhorias em produtos e processos e, onde as reclamações sejam corretamente tratadas. Dar conhecimento das diversas ferramentas existentes para a análise da satisfação de clientes.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 31,
    title: "METROLOGIA E CALIBRAÇÃO",
    url: "METROLOGIA-E-CALIBRACAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A metrologia é a ciência das medições, abrangendo todos os aspetos teóricos e práticos que asseguram a precisão exigida, procurando garantir a qualidade de produtos e serviços através da calibração de instrumentos de medição e da realização de ensaios, sendo uma das bases para a competitividade das empresas",
    objectives:
      "Dotar os participantes de competências e conhecimentos técnicos e práticos sobre análise de relatórios de calibração de equipamentos e realização de verificações de instrumentos de medição, bem como identificar conceitos de metrologia e cálculo de incertezas.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 32,
    title: "APQP/PPAP - PLANEAMENTO DA QUALIDADE",
    url: "APQP-PPAP-PLANEAMENTO-DA-QUALIDADE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "APQP é um modelo para a gestão de projeto, que inclui a conceção e desenvolvimento de produtos e de processos de produção, com o objetivo de standardizar métodos de trabalho e harmonizar a verificação de resultados. O PPAP visa apresentar evidências de todas as características, especificações e requisitos definidos na conceção do produto estarem conformes e serem cumpridos.",
    objectives:
      "Dotar os participantes de competências e conhecimentos necessários à gestão do lançamento de novos produtos e novos processos, com a devida implementação das respetivas ferramentas da Qualidade bem como capacitar os formandos para a submissão de um processo de amostras iniciais, como base para a estabilidade e melhoria dos processos produtivos.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 33,
    title: "SELEÇÃO E GESTÃO DE FORNECEDORES",
    url: "SELECAO-E-GESTAO-DE-FORNECEDORES",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Para ter um departamento de compras eficiente é preciso contar com uma boa gestão de fornecedores. É assim que se consegue adquirir mercadorias e matérias-primas com qualidade, preço, quantidade e prazo de entrega.",
    objectives:
      "Dotar os participantes de competências ao nível dos fatores técnicos e económicos da seleção de fornecedores, de modo a tirar partido da escolha e de beneficiar da implementação da política de compra. Pôr em prática metodologias assegurando a qualidade, eficiência e eficácia dos fornecedores.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 34,
    title: "SENSIBILIZAÇÃO PARA A QUALIDADE",
    url: "SENSIBILIZACAO-PARA-A-QUALIDADE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A Gestão da Qualidade é uma gestão focada na qualidade da produção e dos serviços de determinada empresa.",
    objectives:
      "Sensibilizar os colaboradores para os princípios de Gestão da Qualidade. Identificar e caracterizar os requisitos de gestão e técnicas referentes a um Sistema de Gestão da Qualidade. Sensibilização para a problemática da Qualidade no mercado atual.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 35,
    title: "SISTEMAS DE REFERÊNCIA (RPS/MCP)",
    url: "SISTEMAS-DE-REFERENCIA-RPS-MCP-",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O Conceito RPS tem por finalidade garantir que as referências dimensionais sejam idênticas em todas as fases do processo de desenvolvimento do produto e montagem, bem como entre clientes e fornecedores.",
    objectives:
      "Dotar os formandos de competências necessárias ao correcto desenvolvimento de métodos de medição. Identificação da relação entre os sistemas de medição em produção e processo de medição.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 36,
    title: "SPC - BÁSICO",
    url: "SPC-BASICO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O Controlo Estatístico de Processo é uma combinação de técnicas que visam analisar e melhorar continuamente os processos de produção para que o cliente dependa da uniformidade de um produto e possa comprá-lo a um custo mínimo.",
    objectives:
      "Dotar os formandos de competências necessárias ao correto desenvolvimento e aplicação do Controlo Estatístico do Processo. Compreensão da importância da metodologia de controlo de processos dentro das organizações para aumentar a capacidade de gestão com sucesso dos processos de trabalho.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "16",
    associated_training: [],
    testing_link:
      "https://docs.google.com/forms/d/e/1FAIpQLSeHYE3Q8kzJSk3_azK7mEAzf9O4DS9aPsZSzNSMvHY9u67XKQ/viewform",
  },
  {
    id: 37,
    title: "SPC - AVANÇADO",
    url: "SPC-AVANCADO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Avaliar a capacidade do processo, através da aplicação das técnicas do controlo estatístico de processos, reconhecendo a importância das variáveis e atributos nas cartas de controlo assim como um sistema de medição e de um estudo completo da capacidade de produção, como ferramentas de melhoria de produtos e processos.",
    objectives:
      "Dotar os formandos com as competências necessárias para o controlo e a melhoria dos seus processos produtivos, o cálculo da capacidade desses mesmos processos a médio e a longo prazo e as técnicas de otimização e melhoria de processos.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "32",
    associated_training: [],
    testing_link:
      "https://docs.google.com/forms/d/e/1FAIpQLSdyK_wTj-aUui2QtHKAPrcgGIf4aPQthjFmUjVV2Zlb_pw6nQ/viewform?usp=sf_link",
  },
  {
    id: 38,
    title: "QFD - QUALITY FUNCTION DEPLOYMENT",
    url: "QFD-QUALITY-FUNCTION-DEPLOYMENT",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O Quality Function Deployment (QFD) é um processo e conjunto de ferramentas usadas para definir efetivamente os requisitos do cliente e convertê-los em especificações de engenharia detalhada e planos para produzir os produtos que atendem a esses requisitos.",
    objectives:
      "Dotar os formandos dos conhecimentos e competências que lhes permitam identificar, interpretar e implementar requisitos definidos para a aplicação do QFD (Quality Function Deployment), permitindo que a equipa de desenvolvimento do produto incorpore as reais necessidades do cliente nos seus projetos de melhoria.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "40",
    associated_training: [],
  },
  {
    id: 39,
    title: "CURSO AVANÇADO DE ENGENHARIA DA QUALIDADE",
    url: "CURSO-AVANÇADO-DE-ENGENHARIA-DA-QUALIDADE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A Engenharia da Qualidade permite identificar técnicas de gestão da qualidade a aplicar numa organização certificada com vista à melhoria contínua.",
    objectives:
      "O curso tem o objetivo de dotar os participantes com temáticas de reconhecida importância para o planeamento, controlo, melhoria dos processos e produtos com ênfase na utilização eficiente dos recursos e uso de tecnologias adequadas às melhores práticas empresariais numa visão sistémica de sustentabilidade.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "40",
    associated_training: [],
  },
  {
    id: 40,
    title: "ANÁLISE À CAPACIDADE DOS PROCESSOS",
    url: "ANALISE-A-CAPACIDADE-DOS-PROCESSOS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O objetivo da Análise de Capacidade e/ou Desempenho do Processo é diagnosticar se os processos são capazes de satisfazer os requisitos dos clientes.",
    objectives:
      "A formação visa dotar os formandos com conhecimento e métodos para implementar a análise da capacidade de processo produtivo mais adequada à organização.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 41,
    title: "DESENHO TÉCNICO",
    url: "DESENHO-TECNICO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O desenho técnico é um ramo especializado do desenho, caracterizado pela sua normalização e pela apropriação que faz dos seguintes conteúdos: geometria descritiva, vistas ortogonais, cortes, seções, determinação de distâncias, áreas e planificação de sólidos.",
    objectives:
      "Dar a conhecer os métodos de projeção para interpretação de qualquer desenho, assim como compreender as tabelas ISO de tolerânciamento e a forma de as usar, dando a conhecer também os símbolos associados ao desenho que completam a definição da peça. Capacitar os formandos a realizar e a interpretar desenhos de peça, segundo as normas do desenho técnico.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 42,
    title: "SIX SIGMA - YELLOW BELT",
    url: "SIXSIGMA-YELLOW BELT",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Six Sigma Yellow Belt destina-se àqueles que são novos no mundo do Six Sigma, de modo a estarem mais familiarizados com os conceitos básicos de Six Sigma e DMAIC.",
    objectives:
      "Dotar os formandos dos conhecimentos e competências básicas do Six Sigma. Estas habilidades foram comprovadas para ajudar a melhorar os processos de negócios, desempenho e garantia de qualidade.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 43,
    title: "SIX SIGMA - ORANGE BELT",
    url: "SIX-SIGMA-ORANGE BELT",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "No curso de Six Sigma Orange Belt desenvolve-se o conhecimentos do Six Sigma. Muitas empresas, em seguida, passam diretamente para a formação Green Belt, mas a formação Orange Belt preenche a lacuna entre os níveis Yellow e Green.",
    objectives:
      "Dotar os formandos dos conhecimentos e competências relativas aos vários conceitos Six Sigma, além de várias métricas de desempenho Lean e ferramentas úteis associadas ao modelo DMAIC.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 44,
    title: "SIX SIGMA - GREEN BELT",
    url: "SIX-SIGMA-GREEN BELT",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "No curso de Six Sigma Green Belt aprofundam-se os conhecimentos do Six Sigma. As empresas necessitam de colaboradores com o conhecimento e a dinâmica para colocar as ferramentas de qualidade em prática, vencendo obstáculos e resistências internas de forma a conseguir resultados efetivos e mensuráveis.",
    objectives:
      "Dotar os formandos de conhecimentos relativos às principais ferramentas da Qualidade e à metodologia DMAIC, qualificar os formandos para a participação em projetos de melhoria de acordo com a metodologia Six Sigma.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "32",
    associated_training: [],
  },
  {
    id: 45,
    title: "SIX SIGMA - BLACK BELT",
    url: "SIX-SIGMA-BLACK BELT",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "No curso de Six Sigma Black Belt aprofundam-se os conhecimentos do Six Sigma e da gestão avançada de projectos Six Sigma. Conhecimentos de ferramentas avançadas de análise e decisão, garantindo a sua correta aplicação a projetos reais e com resultados tangíveis e qualificando para uma liderança eficaz de equipas de melhoria.",
    objectives:
      "Dotar os formandos de competências para a condução de projetos de melhoria de acordo com a metodologia Six Sigma, dotando-os dos conhecimentos avançados de ferramentas e métodos estatísticos.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "40",
    associated_training: [],
  },
  {
    id: 46,
    title: "MÉTODOS TAGUCHI",
    url: "METODOS-TAGUCHI",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O Método de Taguchi centra-se principalmente no aumento da produtividade de engenharia para desenvolver rapidamente novos produtos com eficiência e com uma gestão baseada em valor.",
    objectives:
      "Capacitar os participantes a aplicar as técnicas desenvolvidas no método Taguchi, para análises profundas sobre problemas da qualidade ou para melhorias contínuas de produtos e processos de fabricação.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 47,
    title: "DoE - DESIGN OF EXPERIMENTS",
    url: "DoE-DESIGN-OF-EXPERIMENTS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O DoE (Design of Experiments) é uma ferramentas poderosa com vista a avaliar e modelar os fatores que atuam no processo. A aplicação do DoE permite obter maior quantidade de informações do processo com menor número de experiências e, portanto, mais rapidamente e com menor custo.",
    objectives:
      "Capacitar os formandos na metodologia DOE, proporcionando conhecimentos para planear, realizar e interpretar melhorias nos processos, mediante o uso de desenhos experimentais (DoE), planeando, executando e analisando um projeto de experiências, optimizado através de experiências factoriais.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 48,
    title: "GD&T - GEOMETRIC DIMENSIONING & TOLERANCE",
    url: "GD&T-GEOMETRIC-DIMENSIONING&TOLERANCE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O Sistema Geométrico de Dimensionamento e Tolerância é o sistema que permite a optimização da definição das características dimensionais do produto, e optimização do processo de medição.",
    objectives:
      "Dotar os formandos de conhecimentos de Dimensionamento e Toleranciamento Geométrico de modo a identificar os conceitos e os processos de definição de dimensões e tolerâncias em desenhos técnicos, assim como descrever os princípios de seleção de referenciais, tolerâncias geométricas e efetuar a definição de dimensões geométricas. Compreender as tolerâncias de localização, forma, orientação, perfil e batimento e seus efeitos.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 49,
    title: "METROLOG XG",
    url: "METROLOG-XG",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O Metrolog XG é um software polivalente de controlo 3D que atende às necessidades de hoje em termos de medição 3D e controlo de qualidade.",
    objectives:
      "Capacitar os formandos para a operação de uma máquina de medição por coordenadas e respetivo Software Metrolog de modo a analisarem os requisitos da medição, interpretação do desenho, elaboração de programa com respetivos alinhamentos, definição, medição e construção de características, emissão de relatórios e respectiva análise.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "32",
    associated_training: [],
  },
  {
    id: 50,
    title: "GESTÃO POR PROCESSOS",
    url: "GESTAO-POR-PROCESSOS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A Gestão por Processos é a disciplina organizacional que fornece ferramentas e recursos para analisar, definir, otimizar, monitorar e controlar processos de negócios e para medir e melhorar o desempenho de processos de negócios interdependentes.",
    objectives:
      "Visa dotar os participantes de competências para a implementação da gestão por processos numa perspectiva de melhoria continua e em consonância com os princípios da gestão da qualidade.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 51,
    title: "INTEGRAÇÃO DE SISTEMAS DE GESTÃO",
    url: "INTEGRACAO-DE-SISTEMAS-DE-GESTAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "As normas de Sistemas de Gestão visam a eficiência e a eficácia das respostas das organizações à satisfação dos requisitos, necessidades e expetativas das Partes Interessadas, nos contextos em que se posicionam.",
    objectives:
      "Visa dotar os participantes de competências para a implementação da gestão por processos numa perspectiva de melhoria continua e em consonância com os princípios da gestão da qualidade.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "40",
    associated_training: [],
  },
  {
    id: 52,
    title: "ISO 19011 - GESTÃO DE AUDITORIAS INTERNAS DE QUALIDADE",
    url: "ISO-19011-GESTAO-DE-AUDITORIAS-INTERNAS-DE-QUALIDADE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A ISO 19011:2018 é a norma que serve de guia para a realização de auditorias de sistema, internas (1a parte), a fornecedores (2a parte) ou para efeitos de certificação (2a parte), indicando como todo o processo de auditoria deve decorrer, alinhado com a análise de risco.",
    objectives:
      "Conhecer os requisitos da norma ISO19011, assim como os princípios de realização de uma auditoria de sistema, tais como o de saber elaborar um programa de auditoria, a sua subsequente preparação, realização, relatório e fecho de não conformidades.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 53,
    title: "ISO 9001:2015 - QUALIFICAÇÃO DE AUDITORES INTERNOS",
    url: "ISO-9001-2015-QUALIFICACAO-DE-AUDITORES-INTERNOS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A ISO 9001 é a norma de sistema de gestão da Qualidade mais utilizada mundialmente, sendo a referência internacional para a Certificação de Sistemas de Gestão da Qualidade.",
    objectives:
      "Visa dotar os participantes de competências para analisar os conceitos e os princípios da qualidade, interpretar os requisitos da norma ISO9001, assim como desenvolver habilidades para avaliação dos requisitos na organização, compreender a inserção da certificação nos negócios da organização e realizar auditorias internas e em fornecedores, de acordo com a norma ISO9001.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 54,
    title: "ISO 9001:2015 - SENSIBILIZAÇÃO DA QUALIDADE",
    url: "ISO-9001-2015-SENSIBILIZACAO-DA-QUALIDADE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A sensibilização tem como objetivo informar e esclarecer os colaboradores sobre a norma ISO 9001, referência internacional para a Certificação de Sistemas de Gestão da Qualidade, procurando demonstrar aos formandos a importância da sua participação na qualidade do produto e processo.",
    objectives:
      "Visa dotar os participantes de conhecimentos para a interpretação dos requisitos da Norma ISO 9001 e a sua operacionalização no seu dia-a-dia.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 55,
    title: "ISO 9001:2015 - IMPLEMENTAÇÃO",
    url: "ISO-9001-2015-IMPLEMENTACAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A ISO 9001 é a norma de sistema de gestão mais utilizada, sendo a referência internacional para a Certificação de Sistemas de Gestão da Qualidade. A adoção de um Sistema de Gestão da Qualidade é, segundo a ISO 9001, uma decisão estratégica da Organização, ou seja, tomada ao mais alto nível de decisão para servir um propósito específico e obter resultados.",
    objectives:
      "Visa dotar os participantes de competências ao nível da concepção, planeamento e implementação da Norma ISO9001:2015. Adquirir conhecimentos no âmbito do processo de certificação.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "32",
    associated_training: [],
  },
  {
    id: 56,
    title: "ISO 14001:2015 - NORMA AMBIENTAL",
    url: "ISO-14001-2015-NORMA-AMBIENTAL",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A certificação ISO 14001:2015 - Sistema de Gestão Ambiental (SGA) permite demonstrar o compromisso com a proteção do meio ambiente, reforçando a imagem institucional da organização e acompanhando a constante evolução do mercado.",
    objectives:
      "Dotar os participantes com a interpretação dos requisitos da norma, reconhecendo os requisitos legais aplicáveis bem como os indicadores ambientais implementados na empresa. Na formação identificam- se os processos, políticas, procedimentos e práticas do Sistema de Gestão Ambiental (SGA).",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 57,
    title: "ISO 14001:2015 - SENSIBILIZAÇÃO AMBIENTAL",
    url: "ISO-14001-2015-SENSIBILIZAÇÃO-AMBIENTAL",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Sensibilização para os requisitos legais ambientais aplicáveis, problemas ambientais e suas possíveis soluções, procurando transformar os colaboradores em participantes ativos na proteção dos valores naturais.",
    objectives:
      "Sensibilizar os formandos para os princípios essenciais da Gestão Ambiental, assim como ajudar na definição dos objetivos ambientais das Organizações e para os contributos de cada um para o seu cumprimento.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 58,
    title: "ISO 14001:2015 - QUALIFICAÇÃO DE AUDITORES INTERNOS",
    url: "ISO-14001-2015-QUALIFICACAO-DE-AUDITORES-INTERNOS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A ISO 14001:2015 prevê requisitos para a gestão do sistema ambiental mais eficaz das atividades das empresas.",
    objectives:
      "Facultar conhecimentos sobre a estrutura da norma e os seus requisitos, implicações da sua operacionalização assim como a gestão de um programa de auditorias internas ao sistema de gestão ambiental, contribuindo para a melhoria contínua do sistema de gestão ambiental e realização de auditorias internas e em fornecedores, de acordo com a norma.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 59,
    title: "EXCELÊNCIA EFQM - SENSIBILIZAÇÃO",
    url: "EXCELENCIA-EFQM-SENSIBILIZACAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O Modelo de Excelência da EFQM é utilizado como base para a (auto)avaliação de uma organização, um exercício através do qual é avaliada face a um conjunto de 9 critérios detalhados.",
    objectives:
      "Dotar os participantes de conhecimentos sobre as linhas orientadoras definidas pelo modelo de excelência empresarial: European Foundation for Quality Management, a fim de alcançar a máxima qualidade do produto e do serviço, tal como a máxima rentabilização e otimização dos processos. Competências para a análise, compreensão e concretização do modelo de excelência EFQM.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 60,
    title: "LPA - LAYERED PROCESS AUDITS",
    url: "LPA-LAYERED-PROCESS-AUDITS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "As auditorias de processo (LPAs) são uma técnica de qualidade que se concentra em observar e validar como os produtos são feitos, com o objectivo da melhoria contínua.",
    objectives:
      "Visa dotar os participantes com os conhecimentos necessários ao planeamento, implementação e gestão de um programa de auditorias - Layered Process Audits, de acordo com o Manual AIAG CQI-8 e Requisitos Específicos de Cliente aplicáveis.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 61,
    title: "ISO 50001 - NORMA ENERGÉTICA",
    url: "ISO-50001-NORMA-ENERGETICA",
    category1: "Auditoria",
    category2: "Indústria",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A norma ISO 50001 estabelece parâmetros internacionais para o fornecimento, uso e consumo de energia em organizações industriais, comerciais e institucionais. A norma ajuda, efectivamente, as organizações a melhorar o desempenho energético, reduzir custos e superar as expetativas do cliente.",
    objectives:
      "Dotar os participantes de conhecimentos base sobre a norma ISO50001, as suas vantagens e desafios assim como a sua integração com os restantes Sistemas de Gestão. Visa também habilitar os participantes a planear e a realizar auditorias internas a um Sistema de Gestão de Energia e seus processos, de acordo com a norma ISO50001.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 62,
    title: "ISO 45001:2018 - QUALIFICAÇÃO DE AUDITORES INTERNOS",
    url: "ISO-45001-2018-QUALIFICACAO-DE-AUDITORES-INTERNOS",
    category1: "Auditoria",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Um Sistema de Gestão da Saúde e Segurança Ocupacional (SGSSO) é um elemento fundamental na estratégia de gestão do risco organizacional. A implementação de um SGSSO permite à organização promover locais de trabalho mais seguros e saudáveis a todos os seus colaboradores.",
    objectives:
      "Ajudar a compreender a relação entre a ISO 45001 e as outras normas ISO bem como ajudar a interpretar os requisitos comuns aos sistemas de gestão e os específicos da ISO 45001:2018, assim como ajudar a implementar e gerir o sistema de gestão em conformidade com os requisitos da ISO 45001:2018 e ainda gerir um programa de auditorias.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 63,
    title: "AS 9100/EN9100 - QUALIFICAÇÃO DE AUDITORES INTERNOS",
    url: "AS-9100-EN9100-QUALIFICACAO-DE-AUDITORES-INTERNOS",
    category1: "Formação",
    category2: "Aeronáutica",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A AS9100 é a norma de sistemas de gestão da qualidade especifico da indústria aeroespacial, naval e defesa, norma reconhecida internacionalmente, que estabelece requisitos para o sistema de gestão da qualidade específicos destas indústrias.",
    objectives:
      "Dotar os participantes de competências para analisar os conceitos e os princípios da qualidade, interpretar os requisitos da norma AS 9100, assim como desenvolver capacidades para a avaliação dos requisitos na organização e realizar auditorias internas e em fornecedores.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 64,
    title: "AS 9100/EN9100 - IMPLEMENTAÇÃO",
    url: "AS-9100-EN9100-IMPLEMENTACAO",
    category1: "Formação",
    category2: "Aeronáutica",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A AS9100 é a norma de sistemas de gestão da qualidade especifico da indústria aeroespacial, naval e defesa. A adoção de um Sistema de Gestão da Qualidade é, segundo a AS9100, uma decisão estratégica da Organização, ou seja, tomada ao mais alto nível de decisão para servir um propósito específico e obter resultados.",
    objectives:
      "Visa dotar os participantes de competências ao nível da concepção, planeamento e implementação da Norma AS9100. Adquirir conhecimentos no âmbito do processo de certificação.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "40",
    associated_training: [],
  },
  {
    id: 65,
    title: "AS 9100/EN9100 - NORMA AERONÁUTICA",
    url: "AS-9100-EN9100-NORMA-AERONAUTICA",
    category1: "Formação",
    category2: "Aeronáutica",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A AS9100 é a norma de sistemas de gestão da qualidade especifico da indústria aeroespacial, naval e defesa. Centra-se essencialmente em temas referentes à produção, dando especial enfoque às questões da qualidade, segurança e tecnologia em todas as vertentes da indústria e ao longo de toda a cadeia de fornecimento.",
    objectives:
      "Visa dotar o formando dos conhecimentos e competências para compreender, desenvolver e manter um Sistema de Gestão da Qualidade para a industria aeroespacial, naval e defesa, bem como do processo de certificação.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 66,
    title: "ISO/IEC 17025",
    url: "ISO-IEC-17025",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Define uma metodologia com validade para a acreditação em Portugal, na Europa e internacionalmente, com acordos de reconhecimento mútuo entre os organismos de acreditação, facilitando assim a internacionalização das empresas.",
    objectives:
      "Dotar os participantes dos conhecimentos sobre os requisitos de gestão e técnicos da norma ISO/IEC 17025.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 67,
    title: "ISO/IEC 17025 - IMPLEMENTAÇÃO",
    url: "ISO-IEC-17025-IMPLEMENTACAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Define uma metodologia com validade para a acreditação em Portugal, na Europa e internacionalmente, com acordos de reconhecimento mútuo entre os organismos de acreditação, facilitando assim a internacionalização das empresas.",
    objectives:
      "Desenvolver um Sistema de Gestão da Qualidade (SGQ) no Laboratório, evidenciando o cumprimento dos requisitos da norma EN ISO/IEC 17025.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "40",
    associated_training: [],
  },
  {
    id: 68,
    title: "ISO26262 - FUNCTIONAL SAFETY",
    url: "ISO26262-FUNCTIONAL-SAFETY",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A norma ISO26262 é aplicada a sistemas de segurança funcional de automóveis, autocarros e motociclos. A norma é aplicada para organizações que desenvolvem hardware e também software, bem como para organizações que se encontram na fase de integração do sistema e produção do mesmo. Ainda, incluindo uma análise dos níveis de integridade para os componentes (ASIL).",
    objectives:
      "A formação tem como objectivo apresentar os 10 capítulos que compõem a norma ISO26262, bem como informar os formandos dos requisitos aplicáveis à sua organização, como implementar os requisitos na sua organização, fazendo exercícios relacionados com o produto.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 69,
    title: "IATF 16949",
    url: "IATF-16949",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Engenharia da Qualidade",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O Sistema de Gestão da Qualidade (ISO9001:2015 e IATF 16949) e o enquadramento normativo na Indústria Automóvel (IATF); Abordagem e Gestão por Processos: - Metodologia, abordagem e documentação dos processos de acordo com o “IATF Automotive Process Approach” – Polvos e Tartarugas de Crosby; - Integração dos Requisitos Específicos dos Clientes e outras normas da Indústria Automóvel; - Core Tools;",
    objectives:
      "Visa dotar os formandos de competências para a interpretação, implementação e auditoria de acordo com a Norma IATF 16949. Aplicação do “IATF Automotive Process Approach”.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 70,
    title: "GESTÃO DA CADEIA DE FORNECIMENTO",
    url: "GESTAO-DA-CADEIA-DE-FORNECIMENTO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão de Operações",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A gestão da Cadeia de Fornecimento é um processo que assenta em três importantes pilares, a empresa, o fornecedor e o cliente, e que consiste na gestão dos produtos e da comunicação entre e dentro da cadeia.",
    objectives:
      "Este curso visa dotar os participantes das competências que permitam gerir de modo eficaz e eficiente, os fluxos físicos, bens e materiais, do ponto de origem ao ponto de consumo, em conformidade com os requisitos e necessidades dos clientes.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 71,
    title: "GESTÃO DA MANUTENÇÃO",
    url: "GESTAO-DA-MANUTENCAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão de Operações",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A gestão de manutenção é o controlo da alocação de custos, tempo e recursos de forma a assegurar a eficiência e adequação das operações de manutenção, evitando desperdício de recursos e até paralisações devidas a equipamentos avariados.",
    objectives:
      "Dotar os participantes de conhecimentos, competências e procedimentos atualizados no domínio de Gestão de Manutenção de Máquinas e Equipamentos, necessários à eficiente gestão de processos, em ambiente industrial, de acordo com as melhores práticas reconhecidas.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "40",
    associated_training: [],
  },
  {
    id: 72,
    title: "GESTÃO DE PROJETOS",
    url: "GESTAO-DE-PROJETOS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão de Operações",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Gestão de projetos é o conjunto de conhecimentos, técnicas e ferramentas utilizadas para planear, executar e avaliar um projeto. Assume um papel determinante na melhoria da competitividade por via da eficiência e eficácia, uma vez que permite a redução de custos e tempos afetos aos processos para o alcance dos objetivos dos projetos.",
    objectives:
      "Dotar os participantes de competências e metodologias técnicas e ferramentas para a gestão eficaz de projetos. Compreender métodos de desenvolvimento avançado dos produtos, de acordo com as melhores práticas reconhecidas. Aplicar as técnicas e ferramentas aprendidas a um caso prático a ser resolvido durante o curso.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "32",
    associated_training: [],
  },
  {
    id: 73,
    title: "GRUPOS AUTÓNOMOS DE TRABALHO",
    url: "GRUPOS-AUTONOMOS-DE-TRABALHO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão de Operações",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Planear e implementar grupos responsáveis e autónomos pela produção de um produto ou linha de produtos, com base na flexibilidade e qualificação para várias tarefas predeterminadas, incluindo a componente e a supervisão de modo a não deve interferir na maneira pela qual o grupo gere as tarefas, deixando à supervisão a função de servir de elo entre cada grupo e o seu meio externo. Criar a maior autonomia para os colaboradores, num ambiente de trabalho mais descentralizado e participativo, numa visão mais humanística do trabalho.",
    objectives:
      "Dotar os participantes com os conhecimetos e técnicas necessárias para a seleção e elaboração de grupos de trabalho autónomos, com o intuito de gerar flexibilidade e versatilidade operacional assim como aumentar as competências técnicas, o comprometimento e a motivação.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 74,
    title: "LIDERANÇA DE EQUIPAS DE PRODUÇÃO",
    url: "LIDERANCA-DE-EQUIPAS-DE-PRODUCAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão de Operações",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Uma Liderança eficaz condiciona e é determinante para motivar. Um bom líder aprende continuamente ao longo da sua vida e adapta-se às circunstâncias.",
    objectives:
      "Dotar os participantes de conhecimentos e técnicas necessárias para a liderança eficaz de uma equipa de produção, atendendo ao meio onde se insere. Desenvolver espírito de equipa no terreno, contribuir para a melhoria da organização, desenvolvendo com rigor o progresso permanentemente. Tornar os seus actos de gestão de equipa eficaz.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 75,
    title: "ORGANIZAÇÃO E GESTÃO DA PRODUÇÃO",
    url: "ORGANIZACAO-E-GESTAO-DA-PRODUCAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão de Operações",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A gestão da produção é uma das tradicionais áreas funcionais da gestão e inclui as funções de análise, escolha e implementação das tecnologias e processos produtivos mais eficientes na combinação e transformação dos factores produtivos (inputs) para obtenção do máximo de bens e serviços (outputs).",
    objectives:
      "Dotar os participantes de competências, técnicas e metodologias necessárias à correta organização e gestão da produção, visando a obtenção de oportunidades de melhoria e de eficácia no desempenho da organização.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "32",
    associated_training: [],
  },
  {
    id: 76,
    title: "SISTEMAS MODULARES DE PRODUÇÃO",
    url: "SISTEMAS-MODULARES-DE-PRODUCAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão de Operações",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Modularidade é uma estratégia para desdobrar processos complexos em pequenos subsistemas, de modo a serem desenvolvidos individualmente, mas que funcionam como um conjunto integrado.",
    objectives:
      "Dotar os participantes das competências necessárias à operacionalização dos métodos e conceitos aplicados aos sistemas modulares de produção, visando o menor ciclo de fabricação, a redução em transportes e movimentação, o fluxo de fabricação simplificado, a redução de retrabalhos, a melhoria da qualidade, menor número de operadores e menores custos.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 77,
    title: "BALANCED SCORECARD",
    url: "BALANCED-SCORECARD",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão de Operações",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O conceito de Balanced Scorecard (BSC) pode ser definido como um modelo de gestão estratégica que auxilia a mensuração dos progressos das empresas rumo às suas metas de longo prazo, a partir da tradução da estratégia em objetivos, indicadores, metas e iniciativas estratégicas.",
    objectives:
      "Dotar os participantes de mecanismos de gestão para a concretização dos objetivos estratégicos e para a implementação bem sucedida de uma determinada estratégia empresarial. Estabelecer os objetivos estratégicos a concretizar, assim como conhecer as medidas mais adequadas à sua implementação. Sabr desenhar o mapa estratégico mais adequado à sua organização.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 78,
    title: "GESTÃO DE ARMAZÉNS E INVENTÁRIOS",
    url: "GESTAO-DE-ARMAZENS-E-INVENTARIOS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão de Operações",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A gestão do armazém está diretamente relacionada com o processo de transferência de produtos para os clientes finais, e têm em conta aspetos como a mão-de-obra, o espaço, as condições do armazém e fundamentalmente um local onde se maximiza o espaço de armazenagem. A correta e eficiente gestão do armazém é importante para a sustentabilidade da empresa",
    objectives:
      "Dotar os formandos de competências de forma a analisar criticamente a gestão estratégica de inventários e formular soluções no sentido de que seja proporcionada uma elevada qualidade de serviço logístico oferecido. Os participantes deverão estar em condições de melhorar o seu desempenho na área de armazéns.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 79,
    title: "GESTÃO DE RISCOS",
    url: "GESTAO-DE-RISCOS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão de Operações",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O objetivo desta ação é familiarizar os formandos com o conceito de risco e os respetivos impactos na vida de qualquer organização, nomeadamente no que respeita aos aspetos de identificação e avaliação dos diferentes tipos de risco, na utilização de instrumentos de analise de risco e respetiva mensuração. Serão também conferidas competências relativamente aos respetivos sistemas de controlo interno e ainda sobre técnicas de auditoria ao processo de gestão do risco.",
    objectives:
      "Com o curso os formandos serão capazes de enunciar as etapas de um processo de Análise de Risco, assim como identificar as principais metodologias de análise de riscos e a sua aplicação em diferentes situações. Aplicar quantitativamente os métodos de Análise de Riscos: Árvores de Falhas, Análise Preliminar de Riscos e HAZOP e enunciar os principais parâmetros e o modo como estes influenciam o cálculo de uma avaliação de consequências de um acidente industrial.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 80,
    title: "PEÇAS DE SEGURANÇA E REGULAMENTAÇÃO",
    url: "PECAS-DE-SEGURANCA-E-REGULAMENTACAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão de Operações",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Os automóveis possuem na sua constituição peças de segurança, com requisitos de regulamentação a nível dos mercados onde são vendidos. A identificação e controlo dessas características é fundamental no que diz respeito a garantir a segurança do produto.",
    objectives:
      "Visa garantir que os formandos são capazes de identificar as características especiais associadas a peças de segurança, definir os meios de controlo bem como identificar os requisitos legais associados.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 81,
    title: "PLANEAMENTO E ORGANIZAÇÃO DO TRABALHO",
    url: "PLANEAMENTO-E-ORGANIZACAO-DO-TRABALHO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão de Operações",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A acumulação de tarefas é comum na rotina de muitos profissionais. Mas mesmo com o excesso de tarefas, um planeamento e organização eficientes podem melhorar o trabalho. Mesmo que não seja possível reduzir o número de responsabilidades, é possível melhorar o processo de trabalho e, com isso, tornar-se mais produtivo. Existem métodos e técnicas que facilitam o planeamento e organização do trabalho, com o objetivo de conseguir resultados mais eficazes, para o colaborador e para a organização.",
    objectives:
      "Capacitar os formando para organizar o trabalho, gerindo melhor o tempo, estipulando objetivos, metas e analisando os seus desvios, identificando cronófagos e escolhendo soluções alternativas, assim como definindo ou participando na definição de planos de acção.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 82,
    title: "IMDS - INTERNACIONAL MATERIAL DATA SYSTEM",
    url: "IMDS-INTERNACIONAL-MATERIAL-DATA-SYSTEM",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão de Operações",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O IMDS (International Material Data System) é o sistema de dados de materiais da indústria automóvel, usado pela maioria das OEM’s, que visa identificar e registar os materiasis utilizados nos veículos, dando cumprimento aos requisitos legais aplicáveis.",
    objectives:
      "Capacitar os formandos na elaboração e submissão dos MDS ́s (Material Data Sheet), recebimento e análise dos MDS ́s de fornecedores, assim como conhecimentos sobre os requisitos da Diretiva 2000/EC/53 e substâncias restritas ou proibidas das montadoras signatárias do IMDS. Obter um entendimento do IMDS como requisito para aprovação do Processo de Aprovação de Peças em Produção e aprofundar conhecimentos.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 83,
    title: "NEGOCIAR COM EFICIÊNCIA",
    url: "NEGOCIAR-COM-EFICIENCIA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Soft Skills",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Um bom negociador possui, para além do conhecimento das habituais estratégias de negociação, um conhecimento avançado da psicologia humana, da forma de comunicar e de influenciar mais eficazmente na negociação. Paralelamente, é também necessário conseguir decifrar a linguagem corporal e as emoções presentes no teatro negocial. Somente na posse de todos estes conhecimentos conseguimos aumentar as nossas hipóteses de realizar uma boa negociação.",
    objectives:
      "Este curso pretende aos formandos obter um maior preparo pessoal em momentos de negociação, assim como alcançar resoluções de negociação com perspicácia e segurança, aumentando a eficiência no trabalho e da produtividade.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 84,
    title: "LIDERANÇA E GESTÃO DE EQUIPAS",
    url: "LIDERANÇA-E-GESTAO-DE-EQUIPAS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Soft Skills",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Os gestores capazes de motivar, inspirar e apoiar a sua equipa são um verdadeiro trunfo para qualquer empresa.",
    objectives:
      "Este curso pretende dotar os participantes dos conhecimentos e técnicas necessárias para a liderança eficaz de uma equipa e desenvolver relações de trabalho produtivas, respondendo eficazmente aos fatores críticos de sucesso e motivação de equipas. Utilizar técnicas de feedback para gerir o desempenho de cada colaborador.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 85,
    title: "PERFORMANCE DE EQUIPAS E COLABORAÇÃO",
    url: "PERFORMANCE-DE-EQUIPAS-E-COLABORACAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Soft Skills",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Pessoas produtivas, motivadas e capazes de resultados incríveis são o alicerce do sucesso das empresas.",
    objectives:
      "No final da ação os formandos vão ser capazes de identificar as competências básicas de uma equipa e as competências que distinguem as equipas de alta performance das outras equipas. Vão saber diagnosticar a performance de uma equipa com clareza e precisão e desenvolver estratégias práticas para transformar a equipa numa equipa de alta performance.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 86,
    title: "LIDERANÇA OPERACIONAL",
    url: "LIDERANCA-OPERACIONAL",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Soft Skills",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O desenvolvimento da liderança nas áreas operacionais é sempre um desafio para grande parte das empresas.",
    objectives:
      "Este curso pretende ajudar os formandos a identificarem as prioridades de gestão, definindo um plano de ação; a ajudar na definição de objetivos comuns, delegando tarefas, incutindo a responsabilidade e coesão na equipa; aplicar as técnicas adequadas para gerir a motivação dos colaboradores e a utilizar técnicas de feedback adequadas a cada situação e a cada colaborador.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 87,
    title: "INTELIGÊNCIA EMOCIONAL",
    url: "INTELIGENCIA-EMOCIONAL",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Soft Skills",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Pessoas emocionalmente inteligentes têm mais sucesso porque tem a capacidade de reconhecer e avaliar os seus próprios sentimentos e os dos outros, assim como a capacidade de lidar com eles, focando-se no que podem controlar. São os hábitos de quem usa a mente para gerir o coração.",
    objectives:
      "Dotar os formandos de técnicas que permitam desenvolver competências como a empatia e a auto motivação; conhecer os pontos fortes ao nível de QE e QI, maximizando as mais valias no quotidiano profissional e identificando as áreas de melhoria, realizando esforços concertados e conscientes de desenvolvimento.",
    online: yes,
    presential: yes,
    online_hours: "4",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 88,
    title: "GESTÃO DO TEMPO E DAS PRIORIDADES",
    url: "GESTAO-DO-TEMPO-E-DAS-PRIORIDADES",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Soft Skills",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A gestão do tempo e das prioridades é um fator fundamental para o sucesso pessoal e profissional. Saber definir prioridades é uma tarefa que se deve dar atenção.",
    objectives:
      "Dotar os formandos de competências técnicas eficazes de gestão de tempo, desenvolvimento de estratégias para otimizar a organização do trabalho, a redução de tarefas não prioritárias e sem mais valia e o equilíbrio entre a vida pessoal e profissional.",
    online: yes,
    presential: yes,
    online_hours: "2",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 89,
    title: "GESTÃO DE CONFLITOS E TÉCNICAS DE ASSERTIVIDADE",
    url: "GESTAO-DE-CONFLITOS-E-TECNICAS-DE-ASSERTIVIDADE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Soft Skills",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "As técnicas de resolução de conflitos são ferramentas essenciais, porque nos levam a adquirir competências de negociação e comunicação assertiva levando-nos a estabelecer compromissos com mais eficácia perante os nossos pares e equipas.",
    objectives:
      "Dotar os formandos de atitudes e comportamentos eficazes de resolução de conflitos com proatividade e competência. Reconhecer os vários tipos de conflito e ajustar o comportamento a cada situação. Valorizar cada fase do processo de negociação para facilitar a obtenção de soluções e a construção de relações de confiança.",
    online: yes,
    presential: yes,
    online_hours: "2",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 90,
    title: "GESTÃO DE STRESS COM CRIATIVIDADE",
    url: "GESTAO-DE-STRESS-COM-CRIATIVIDADE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Soft Skills",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Desenvolver capacidades de identificar os sintomas e diminuir os níveis de stress nocivo adaptando ferramentas que proporcionem relaxamento, foco e aumentando assim o poder criativo que conduz ao êxito nas atividades diárias, é assim que se supõe gerir o stress coma criatividade.",
    objectives:
      "Dotar os formandos de competências na gestão de Stress, na identificação do “Stress” e alguns dos fatores que desencadeiam, assim como aplicar técnicas para a gestão do Stress.",
    online: yes,
    presential: yes,
    online_hours: "2",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 91,
    title: "MIND MAPPING & CREATIVE THINKING",
    url: "MIND-MAPPING&CREATIVETHINKING",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Soft Skills",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O mind mapping é uma das melhores maneiras de capturar os pensamentos e trazê-los à vida de forma visual através de um diagrama.",
    objectives:
      "Aquisição de competências para desenvolver o Mind Mapping e um pensamento criativo. Dotar os formandos de uma ferramenta para ilustrar ideias e conceitos, dar-lhes forma e contexto, traçar os relacionamentos de causa, efeito, simetria e/ou similaridade que existem entre elas e torná-las mais palpáveis e mensuráveis, sobre os quais se pode planear ações e estratégias para alcançar objetivos específicos.",
    online: yes,
    presential: yes,
    online_hours: "2",
    presential_hours: "14",
    associated_training: [],
  },
  {
    id: 92,
    title: "GESTÃO DE MUDANÇA",
    url: "GESTAO-DE-MUDANCA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Soft Skills",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A gestão da mudança é um processo de alocação de recursos, de forma a transformar a organização, com o objetivo de melhorar a sua eficácia.",
    objectives:
      "Saber acompanhar e motivar os colaboradores no processo de implementação de gestão da mudança, descrever as fases do processo de mudança assim como ultrapassar as barreiras à mudança.",
    online: yes,
    presential: yes,
    online_hours: "2",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 93,
    title: "COMUNICAÇÃO E RELACIONAMENTO INTERPESSOAL",
    url: "COMUNICACAO-E-RELACIONAMENTO-INTERPESSOAL",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Soft Skills",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A comunicação, seja ela verbal ou não verbal, é a responsável pela transmissão das mensagens entre as partes. Nos dias de hoje, as competências ligadas à comunicação e ao relacionamento interpessoal podem representar o caminho para o sucesso tanto pessoal como profissional.",
    objectives:
      "No final do curso os formandos devem ter otimizado a comunicação e adotado uma comunicação assertiva assim como ter reconhecido a importância das relações interpessoais no dia-a-dia.",
    online: yes,
    presential: yes,
    online_hours: "2",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 94,
    title: "COACHING E MOTIVAÇÃO DE EQUIPAS",
    url: "COACHING-E-MOTIVACAO-DE-EQUIPAS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Soft Skills",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Equipas motivadas cumprem os prazos, trabalham com afinco e convicção. Esta formação é direcionada a pessoas que pretendam aplicar o coaching como um processo indispensável à superação pessoal e profissional, pessoas que pretendem obter o máximo de rendimento no seu trabalho e maior capacidade de lliderança junto de equipas de trabalho.",
    objectives:
      "No final deste curso o formando estará capacitado para aplicar o coaching como um processo indispensável à superação pessoal e profissional, levando-o a obter o máximo de rendimento no seu trabalho e maior capacidade de Liderança junto de equipas de trabalho.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 95,
    title: "GESTÃO DE RECURSOS HUMANOS",
    url: "GESTAO-DE-RECURSOS-HUMANOS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Recursos Humanos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Pretende dar competências para responder às crescentes necessidades e exigências dos profissionais de Recursos Humanos, uma área que é cada vez mais fundamental para os objetivos de competitividade, produtividade e sustentabilidade das organizações.",
    objectives:
      "Aquisição de conhecimentos e instrumentos aplicados à gestão de RH. Dotar os formandos de competências para identificar e aplicar instrumentos na gestão de recursos; aplicação de ferramentas na gestão dos recursos humanos de acordo com cada empresa.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "32",
    associated_training: [],
  },
  {
    id: 96,
    title: "DESENVOLVIMENTO OPERACIONAL DE COMPETÊNCIAS",
    url: "DESENVOLVIMENTO-OPERACIONAL-DE-COMPETENCIAS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Recursos Humanos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Diante de um mercado cada vez mais dinâmico e exigente, as empresas são obrigadas a encontrar novas soluções que garantam a longevidade e o crescimento dos negócios. Neste sentido, o desenvolvimento de competências tem sido adotado por muitas organizações, que veem nos profissionais um diferencial competitivo. Os conhecimentos habilidades e atitudes dos colaboradores são fundamentais para delinear estratégias e cumprir metas e objetivos.",
    objectives:
      "No final do curso os formandos conseguirão adquirir conhecimentos para o desenvolvimento de competências das suas equipas assim como perceber que ferramentas são importantes para o desenvolvimento de competências dos colaboradores.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 97,
    title: "AVALIAÇÃO DO DESEMPENHO PROFISSIONAL",
    url: "AVALIACAO-DO-DESEMPENHO-PROFISSIONAL",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Recursos Humanos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Avaliação de desempenho mede o desempenho dos trabalhadores na organização, estabelecendo uma comparação entre o desempenho esperado e o apresentado pelos colaboradores. Perante esta formação conseguimos efetuar um plano de ação para atingir resultados eficazes em termos de desempenho.",
    objectives:
      "Dotar os formandos de competências para desenvolver e avaliar a importância de um processo de avaliação de desempenho, assim como adquirir competências na área de avaliação de desempenho.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 98,
    title: "AVALIAÇÃO DA EFICÁCIA DA FORMAÇÃO",
    url: "AVALIACAO-DA-EFICACIA-DA-FORMACAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Recursos Humanos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A necessidade contínua de assegurar a qualidade da formação desenvolvida e o desenvolvimento e controlo efetivo das horas de formação obrigatória, adequadas às reais necessidades dos colaboradores, exige uma gestão cada vez mais eficiente.",
    objectives:
      "Aquisição de conhecimentos para o desenvolvimento de formação eficaz; Dotar os formandos de competências para a conceção e desenvolvimento de ações de formação; desenvolver e aplicar um diagnóstico de necessidades de formação e respetivo plano de formação; implementar sistema de avaliação e acompanhamento da formação.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 99,
    title: "BALANCED SCORECARD NOS RECURSOS HUMANOS",
    url: "BALANCED-SCORECARD-NOS-RECURSOS-HUMANOS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Recursos Humanos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Conhecimentos acerca da estruturação da informação que permite avaliar e medir a contribuição do capital humano, dos objetivos estratégicos e as metas do balanced scorecard nos recursos humanos, integrando esta ferramenta na gestão global da empresa.",
    objectives:
      "Dotar os formandos de competências para estruturar a informação que permite avaliar e medir a contribuição do capital humano. Conhecer os objetivos estratégicos e as metas e métricas do balanced scorecard nos recursos humanos e integrar esta ferramenta na gestão global da empresa.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 100,
    title: "LIDERANÇA E GESTÃO DE CARREIRAS",
    url: "LIDERANCA-E-GESTAO-DE-CARREIRAS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Recursos Humanos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O mercado de trabalho está, cada vez mais, em busca de profissionais habilidosos, que saibam contribuir para o crescimento e sucesso do negócio. E para isso é necessário ter conhecimento nas áreas em que se atua e é preciso compreender as pessoas e desenvolver o fator humano nas organizações.",
    objectives:
      "Aquisição de competências para a adequada liderança e gestão de carreira; Dotar os formandos de competências para reconhecer um líder; Utilizar adequadamente fontes de informação; identificar, captar e reter talentos.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 101,
    title: "WORKSHOPS LEAN",
    url: "WORKSHOPS-LEAN",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Workshops Práticos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "As empresas têm cada vez mais necessidade de encontrar vantagens competitivas face à concorrência, de melhorar os seus resultados e performance, de implementar um modelo de excelência operacional ou de simplesmente de resolver problemas.",
    objectives:
      "Os formandos ficarão familiarizados com os métodos e práticas do Lean e preparados para a sua utilização profissional no domínio da gestão dos processos empresariais.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 102,
    title: "WORKSHOP SIX SIGMA",
    url: "WORKSHOP-SIX-SIGMA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Workshops Práticos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A metodologia Six Sigma permite às organizações preparar as suas equipas para atingir resultados e melhorar a sua performance. Focar as equipas na melhoria dos processos, com um método comprovado e robusto, atingindo resultados extraordinários.",
    objectives:
      "Os formandos ficarão a conhecer os conceitos fundamentais do Six Sigma, compreender as suas vantagens e conhecer as ferramentas principais do Six Sigma;",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 103,
    title: "WORKSHOP DoE - DESIGN OF EXPERIMENTS",
    url: "WORKSHOP-DoE-DESIGN-OF-EXPERIMENTS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Workshops Práticos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Apresentar uma das ferramentas mais poderosas da estratégia Lean Six Sigma e da estratégia para Desenvolvimento de Processos ou Produtos. O DOE (Design Of Experiments) permite avaliar e modelar os fatores que atuam no processo. A aplicação do DOE permite obter maior quantidade de informações do processo com menor número de experimentos e portanto, mais rapidamente e com menor custo.",
    objectives:
      "Proporcionar aos participantes conhecimentos para planear, realizar e interpretar melhorias nos processos, mediante o uso de desenhos experimentais (DOE) básicos.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 104,
    title: "WORKSHOP KAIZEN",
    url: "WORKSHOP-KAIZEN",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Workshops Práticos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A metodologia Kaizen é baseada no princípios socioculturais do oriente, que exige um comprometimento de todos os trabalhadores da empresa, consistindo numa forma de gestão orientada para a maximização da produtividade e da rentabilidade, e consequente redução de custos.",
    objectives:
      "Dotar os formandos dos conhecimentos e práticas necessárias de forma a aumentar a produtividade pela implementação da simplificação de processos.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 105,
    title: "WORKSHOP ECONOMIA CIRCULAR",
    url: "WORKSHOP-ECONOMIA-CIRCULAR",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Workshops Práticos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Economia Circular é um conceito estratégico que assenta na redução, reutilização, recuperação e reciclagem de materiais e energia.",
    objectives:
      "Informar e capacitar os formandos para a transição para uma sociedade que segue o modelo de economia circular, nomeadamente sobre os principais aspetos relacionados com a necessidade da sociedade progredir para uma circularidade global, conceitos, benefícios, modelos de negócio, as principais barreiras à economia circular, a circularidade nas empresas, modelos de gestão e a IS0 14001:2015.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 106,
    title: "WORKSHOP MINDFULNESS NO TRABALHO",
    url: "WORKSHOP-MINDFULNESS-NO-TRABALHO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Workshops Práticos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Através das práticas de Mindfulness é adquirida uma maior mestria na regulação de emoções, pensamentos e sensações à medida que estas emergem. Traz a possibilidade de modificar as reações automáticas e por vezes ineficazes que temos face às dificuldades do dia a dia, aprendendo-se a responder aos desafios inerentes à vida de forma mais adaptativa e equilibrada.",
    objectives:
      "A prática de Mindfulness tem como objetivo permitir que as pessoas desenvolvam a sua atenção, melhorem a sua consciência, a sua empatia e resiliência e aprimorem o seu foco e clareza, ao mesmo tempo que ajuda a diminuir o seu nível de stress e de ansiedade e aumenta a sensação de bem-estar.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 107,
    title: "WORKSHOP COM DICAS PARA UMA MELHOR GESTÃO DO TEMPO",
    url: "WORKSHOP-COM-DICAS-PARA-UMA-MELHOR-GESTAO-DO-TEMPO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Workshops Práticos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "As pessoas que conseguem administrar os processos de forma eficiente são reconhecidas no meio em que atuam por sua produtividade. E não poderia ser diferente, já que seu desempenho individual contribui para melhores resultados coletivos. Além dos benefícios profissionais voltados à carreira que podem ser alcançados, a correta gestão do tempo permite evoluir na qualidade de vida e nos relacionamentos pessoais.",
    objectives:
      "Este curso dá dicas/ferramentas para rentabilizar e utilizar corretamente o seu tempo, assim como ajuda a definir prioridades, tornando-nos mais eficazes e eficientes",
    online: yes,
    presential: yes,
    online_hours: "4",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 108,
    title: "WORKSHOP ANÁLISE E RESOLUÇÃO DE PROBLEMAS",
    url: "WORKSHOP-ANALISE-E-RESOLUCAO-DE-PROBLEMAS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Workshops Práticos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Análise e resolução de problemas é o procedimento, ou a padronização, de uma série de ferramentas analíticas, que podem ser utilizadas sistematicamente nas organizações.",
    objectives:
      "Proporcionar o conhecimento e a aplicação da metodologia para a análise e resolução de problemas e abordar problemas de forma sistemática para os solucionar.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 109,
    title: "WORKSHOP DE LIDERANÇA",
    url: "WORKSHOP-LIDERANCA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Workshops Práticos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Liderança é a arte de comandar pessoas, atraindo seguidores e influenciando de forma positiva mentalidades e comportamentos. Saber tirar partido das diferentes competências, técnicas e soft skills da equipa e conhecer o poder da comunicação na Liderança é vital numa organização.",
    objectives:
      "Este Workshop tem como principal objectivo identificar e desenvolver as competências de liderança mais ajustadas aos processos de gestão e direção. Alinhar a equipa de acordo com a visão, missão e cultura da empresa, para atingir as metas e aumentar a produtividade de cada elemento da organização.",
    online: yes,
    presential: yes,
    online_hours: "4",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 110,
    title: "WORKSHOP O PODER DA COMUNICAÇÃO ASSERTIVA",
    url: "WORKSHOP-O-PODER-DA-COMUNICACAO-ASSERTIVA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Workshops Práticos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A assertividade é a capacidade de nos expressarmos de forma clara, direta e objetiva, utilizando métodos que façam com que o outro entenda com exatidão aquilo que queremos dizer. Uma pessoa com essa característica é positiva, demonstra autoconfiança e sabe manifestar sua opinião de forma transparente. Essas virtudes cooperam para que ela se comporte com mais equilíbrio e segurança ao tomar decisões e executar suas ações.",
    objectives:
      "Neste Workshop dá-se relevância no estilo assertivo na resolução de problemas, valorizando o papel da comunicação e dos comportamentos e atitudes adequadas a cada situação.",
    online: yes,
    presential: yes,
    online_hours: "4",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 111,
    title: "WORKSHOP PLANEAMENTO E ORGANIZAÇÃO DO TRABALHO",
    url: "WORKSHOP-PLANEAMENTO-E-ORGANIZACAO-DO-TRABALHO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Workshops Práticos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Existem métodos e técnicas que facilitam o planeamento e organização do trabalho seja a nível pessoal seja profissional, com o objectivo de conseguir resultados mais eficazes, para o colaborador e para a organização.",
    objectives:
      "Destina-se a profissionais ou individuais que desejem otimizar o planeamento das atividades e organização do trabalho, mediante a eficácia em termos de gestão de tempo e recursos.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 112,
    title: "WORKSHOP RESOLUÇÃO CRIATIVA DE PROBLEMAS",
    url: "WORKSHOP-RESOLUCAO-CRIATIVA-DE-PROBLEMAS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Workshops Práticos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "As organizações bem-sucedidas reconhecem que o pensamento crítico e soluções criativas para os problemas, aumentam significativamente o potencial de negócios. Os potenciais decisores devem utilizar uma variedade de estilos de pensamento, metodologias e processos criativos, que os levem a ser mais eficientes e eficazes no seu dia-a-dia.",
    objectives:
      "Neste curso, poderá desenvolver competências no sentido de se tornar um pensador crítico e “solucionador” de problemas. Aprenderá a aproveitar as ferramentas e técnicas baseadas em tecnologias de ponta.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 113,
    title: "WORKSHOP TÉCNICAS DE MOTIVAÇÃO",
    url: "WORKSHOP-TECNICAS-DE-MOTIVACAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Workshops Práticos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A motivação no trabalho é um aspeto importante no desenvolvimento da nossa vida profissional. Que técnicas de motivação devem ser postas em prática para que uma empresa consiga motivar e manter os seus funcionários que são o combustível certo para alcançar o sucesso",
    objectives:
      "Desenvolver competências pessoais e profissionais através da capacidade de motivar os outros e a si próprio. Aplicar e dominar técnicas de motivação fundamentais.",
    online: yes,
    presential: yes,
    online_hours: "4",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 114,
    title: "ISO 9001 - AUDITORIAS AO SGQ",
    url: "ISO-9001-AUDITORIAS-AO-SGQ",
    category1: "Auditoria",
    category2: "Indústria",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A ISO 9001 é uma norma internacional que providencia requisitos para sistema de gestão da qualidade (SGQ) das organizações. Faz parte de uma série de normas publicadas pela ISO (International Organisation for Standardisation - Organização Internacional de Normalização).",
    objectives:
      "Realização de auditoria interna ou de 2a parte (a fornecedores), de acordo com a ISO9001, incluindo o apoio à definição e implementação do plano de acções corretivas.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 115,
    title: "IATF 16949 - AUDITORIA AO SGQ",
    url: "IATF-16949-AUDITORIA-AO-SGQ",
    category1: "Auditoria",
    category2: "Automóvel",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A IATF16949 tem como foco os requisitos de SGQ para organizações envolvidas na produção de peças série ou peças de serviço na cadeia de desenvolvimento da indústria automóvel.",
    objectives:
      "Realização de auditoria interna ou de 2a parte (a fornecedores), de acordo com a IATF 16949 e abordagem por processos definida pelo IATF, incluindo o apoio à definição e implementação do plano de acções corretivas.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 116,
    title: "VDA 6.3 - AUDITORIA DE PROCESSO",
    url: "VDA-6.3-AUDITORIA-DE-PROCESSO",
    category1: "Auditoria",
    category2: "Automóvel",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A norma VDA 6.3 pode ser aplicada a qualquer organização da indústria automóvel como ferramenta de auditoria de processos, independentemente da dimensão da organização. Sendo um dos requisitos do grupo VW, pode ser aplicado ao longo do ciclo de vida do produto.",
    objectives:
      "Realização de auditoria interna ou de 2a parte (a fornecedores), de acordo com a VDA6.3, incluindo o apoio à definição e implementação do plano de acções corretivas.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 117,
    title: "VDA 6.4 - AUDITORIA DE SISTEMA",
    url: "VDA-6.4-AUDITORIA-DE-SISTEMA",
    category1: "Auditoria",
    category2: "Automóvel",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A VDA 6.4 é uma alternativa à ISO 9001 para organizações produtoras de equipamentos de produção (produções unitárias como, por ex. moldes, linhas de produção, robots...) para a indústria automóvel, de forma a constituir uma vantagem competitiva, com produtos de alta qualidade.",
    objectives:
      "Realização de auditoria interna ou de 2a parte (a fornecedores), de acordo com a VDA6.4, incluindo o apoio à definição e implementação do plano de acções corretivas.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 118,
    title: "VDA 6.7 - AUDITORIA DE PROCESSO (PRODUÇÃO UNITÁRIA)",
    url: "VDA-6.7-AUDITORIA-DE-PROCESSO-PRODUCAO-UNITARIA",
    category1: "Auditoria",
    category2: "Automóvel",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A VDA 6.7 será o equivalente da VDA6.3, para utilização como referencial de norma de auditoria de processo produtivo, para o caso de produção unitária.",
    objectives:
      "Realização de auditoria interna ou de 2a parte (a fornecedores), de acordo com a VDA6.7, incluindo o apoio à definição e implementação do plano de acções correctivas.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 119,
    title: "AS/EN 9100 - AUDITORIA AO SGQ",
    url: "AS-EN-9100-AUDITORIA-AO-SGQ",
    category1: "Auditoria",
    category2: "Aeronáutica",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A EN 9100 estabelece requisitos sistema de gestão da qualidade específicos para a indústria aeroespacial, naval e defesa, nomeadamente para fornecedores de peças em série.",
    objectives:
      "Realização de auditoria interna ou de 2a parte (a fornecedores), de acordo com a AS/EN 9100, incluindo o apoio à definição e implementação do plano de acções corretivas.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 120,
    title: "AUDITORIA NP 4457",
    url: "AUDITORIA-NP-4457",
    category1: "Auditoria",
    category2: "Indústria",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A norma NP 4457:2007 baseia-se em um modelo de gestão da inovação, suportado por interfaces e interações entre o conhecimento científico e tecnológico, o conhecimento sobre a organização e o seu funcionamento e mercado ou a sociedade em geral.",
    objectives:
      "Realização de auditoria interna ou de 2a parte (a fornecedores), de acordo com a NP 4457, incluindo o apoio à definição e implementação do plano de acções corretivas.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 121,
    title: "FIEV 2.0 - AUDITORIA DE PROCESSO",
    url: "FIEV-2-0-AUDITORIA-DE-PROCESSO",
    category1: "Auditoria",
    category2: "Automóvel",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "As auditorias FIEV contribuem para a avaliação da eficiência dos processos produtivos. A FIEV, a PSA Peugeot Citroen e a Renault criaram um processo para supervisionar a implantação dum sistema de referência e reconhecer a validade desta norma e das auditorias realizadas de acordo com estes processos.",
    objectives:
      "Realização de auditoria interna ou de 2a parte (a fornecedores), de acordo com a FIEV v2.0, incluindo o apoio à definição e implementação do plano de acções corretivas.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 122,
    title: "VDA 6.5 - AUDITORIA DE PRODUTO",
    url: "VDA-6-5-AUDITORIA-DE-PRODUTO",
    category1: "Auditoria",
    category2: "Automóvel",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "As auditorias de produtos são importantes para avaliar a qualidade do produto. A VDA 6.5 providencia as orientações para a gestão de programas de auditoria de produto, permitindo ainda que as organizações desenvolvam um esquema de classificação adaptado aos seus próprios requisitos ou aos requisitos de cliente.",
    objectives:
      "Apoio à definição de um programa de auditorias de produto de acordo com o modelo da VDA 6.5.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 123,
    title: "MMOG/LE V5 - AUDITORIA INTERNA",
    url: "MMOG-LEV5-AUDITORIA-INTERNA",
    category1: "Auditoria",
    category2: "Automóvel",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A MMOG (Guia de Operações do Gestão de Materiais) é uma diretriz da ODETTE, que define as expetativas com relação à excelência no sistema de gestão de materiais na indústria automóvel. Aplica- se na gestão da cadeia de fornecedores de produtos, serviços, matérias primas, montagem de veículos, armazéns e distribuidores.",
    objectives:
      "Realização de auditoria interna ou de 2a parte (a fornecedores), de acordo com a MMOG/LE v5, incluindo o apoio à definição e implementação do plano de acções corretivas.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 124,
    title: "ISO 14001 E EMAS - AUDITORIAS AO SISTEMA DE GESTÃO AMBIENTAL",
    url: "ISO-14001-E-EMAS-AUDITORIAS-AO-SISTEMA-DE-GESTAO-AMBIENTAL",
    category1: "Audioria",
    category2: "Indústria",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A ISO 14001 é uma norma internacional que providencia requisitos para sistema de gestão ambiental (SGA) das organizações. Faz parte de uma série de normas publicadas pela ISO (International Organisation for Standardisation - Organização Internacional de Normalização. O SGA pode ainda incluir o Sistema Comunitário de Ecogestão e Auditoria (EMAS).",
    objectives:
      "Realização de auditoria interna ou de 2a parte (a fornecedores), de acordo com a ISO 14001 e EMAS, incluindo o apoio à definição e implementação do plano de acções corretivas.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 125,
    title: "AUDITORIA AOS CQI'S - (9, 11, 12, 15, 17, 23 E 27)",
    url: "AUDITORIA-AOS-CQI-S",
    category1: "Auditoria",
    category2: "Automóvel",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Os manuais CQI 9, 11, 12, 15, 17, 23 e 27 fazem parte de uma série de publicações do AIAG – Automotive Industry Action Group, que visam proporcionar um referencial padrão de auditoria no que se refere aos requisitos de qualidade em processos especiais.",
    objectives:
      "Realização de auditoria interna ou de 2a parte (a fornecedores), de acordo com o CQI aplicável, incluindo o apoio à definição e implementação do plano de acções corretivas.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 126,
    title: "ISO 45001",
    url: "ISO-45001",
    category1: "Auditoria",
    category2: "Indústria",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A ISO 45001 é uma norma internacional que providencia requisitos para sistema de gestão de saúde e segurança ocupacional (SGSSO) das organizações. Faz parte de uma série de normas publicadas pela ISO (International Organisation for Standardisation - Organização Internacional de Normalização.",
    objectives:
      "Realização de auditoria interna ou de 2a parte (a fornecedores), de acordo com a ISO 45001, incluindo o apoio à definição e implementação do plano de acções corretivas.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 127,
    title: "AUDITORIA AOS REQUISITOS DO FORMEL Q (INCLUINDO D-TLD)",
    url: "AUDITORIA-AOS-REQUISITOS-DO-FORMEL-Q",
    category1: "Auditoria",
    category2: "Automóvel",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "O Formel Q constitui o conjunto de requisitos específicos de cliente Volkswagen, incluindo a realização de auditoria de processo e de produto, bem como auditorias específicas a características especiais D/TLD, tendo como objectivo o alinhamento entre a Volkswagen e a sua cadeia de fornecimento.",
    objectives:
      "Realização de auditoria interna ou de 2a parte (a fornecedores), de acordo com o Formel Q, incluindo o apoio à definição e implementação do plano de acções corretivas. Apoio à definição de um programa de auditorias de produto de acordo com o modelo D/TLD.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 128,
    title: "PROJETO DE APOIO À TRANSIÇÃO - SGQ",
    url: "PROJETO-DE-APOIO-TRANSICAO-SGQ",
    category1: "Consultoria",
    category2: "Projetos",
    category3: "Projetos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Melhorar a eficiência e a produtividade dos recursos utilizados no apoio à transição de qualquer sistema normativo.",
    objectives:
      "Apoiar o planeamento, implementação e implementação de acções relativas à transição de qualquer sistema normativo.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 129,
    title: "PROJETO DE INTEGRAÇÃO DOS SISTEMAS DA QUALIDADE",
    url: "PROJETO-DE-INTEGRACAO-DOS-SISTEMAS-DA-QUALIDADE",
    category1: "Consultoria",
    category2: "Projetos",
    category3: "Projetos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Implementação, manutenção e gestão de sistemas de gestão da qualidade, ambiente, segurança e saúde no trabalho, nas organizações.",
    objectives:
      "Apoiar o planeamento, implementação e validação da integração dos Sistemas de Gestão Integrados.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 130,
    title: "PROJETO DE MELHORIA CONTÍNUA KAIZEN",
    url: "PROJETO-DE-MELHORIA-CONTINUA-KAIZEN",
    category1: "Consultoria",
    category2: "Projetos",
    category3: "Projetos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Kaizen tem como principal objetivo aumentar a qualidade e a rentabilidade, encurtando os prazos dos projetos.",
    objectives:
      "Apoiar a implementação de práticas necessárias à compreensão e melhoria dos processos de acordo com as metodologias Kaizen de Melhoria Contínua. Simplificar e normalizar processos, identificar e reduzir desperdício nas operações, usar a gestão visual como ferramenta para envolver os colaboradores e melhorar a produtividade e resolver, de forma estruturada, os problemas do dia a dia.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 131,
    title: "PROJETO DE IMPLEMENTAÇÃO DA METODOLOGIA FMEA",
    url: "PROJETO-DE-IMPLEMENTACAO-DA-METODOLOGIA-FMEA",
    category1: "Consultoria",
    category2: "Projetos",
    category3: "Projetos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "FMEA é uma ferramenta que procura evitar, por meio da análise das falhas potenciais e propostas de ações de melhoria, que ocorram falhas no projeto do produto ou do processo.",
    objectives:
      "Apoiar a implementação de práticas necessárias à compreensão e implementação da ferramenta FMEA. Tem como finalidade assegurar a identificação dos potenciais modos de falha, seus efeitos e suas causas e, daí, delinear acções de melhoria a nível de prevenção ou deteção.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 132,
    title: "PROJETO DE IMPLEMENTAÇÃO DA METODOLOGIA 8D",
    url: "PROJETO-DE-IMPLEMENTAÇÃO-DA-METODOLOGIA-8D",
    category1: "Consultoria",
    category2: "Projetos",
    category3: "Projetos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Os 8D têm como principal objectivo a resolução de problemas, identificando, corrigindo e prevenindo problemas que afetem os clientes e a eficiência operacional.",
    objectives:
      "Apoiar a implementação da metodologia, principalmente a nível da descrição do problema, identificação das causas de ocorrência, causas de não detecção ou causas sistemáticas, definindo as acções de contenção, correctivas e preventivas necessárias, contribuindo assim para a eliminação das não conformidades, respondendo às expetativas dos clientes.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 133,
    title: "PROJETO DE IMPLEMENTAÇÃO LEAN",
    url: "PROJETO-DE-IMPLEMENTACAO-LEAN",
    category1: "Consultoria",
    category2: "Projetos",
    category3: "Projetos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A metodologia Lean é uma integração de métodos e ferramentas desenvolvidos originalmente no Japão e adaptados pelo resto do mundo a partir da década de 90, em particular, na indústria automóvel, onde a Toyota construiu um sistema robusto, eficiente e em constante atualização, o “Toyota Production System”.",
    objectives:
      "Apoiar a implementação de uma metodologia que minimize desperdícios, dando mais fluidez e exatidão às atividades que o envolvem levando à optimização de processos produtivos e fluxos de informação e material mais eficientes.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 134,
    title: "PROJETO DE MELHORIA SIX SIGMA",
    url: "PROJETO-DE-MELHORIA-SIX-SIGMA",
    category1: "Consultoria",
    category2: "Projetos",
    category3: "Projetos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Filosofia de Melhoria Contínua que parte da definição concreta (qualitativa e quantitativamente) do que é valor para o cliente, e das suas necessidades para então definir o âmbito de atuação de um projeto de melhoria, seguindo as fases do ciclo DMAIC.",
    objectives:
      "Apoiar a implementação da metodologia Six Sigma percorrendo as etapas do ciclo DMAIC al longo do projeto: definição do processo; medição do processo; análise do processo; melhoria do processo e controlo do processo.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 135,
    title:
      "PROJETO DE APOIO À IMPLEMENTAÇÃO E CERTIFICAÇÃO SGQ DE ACORDO COM IATF 16949",
    url: "PROJETO-DE-APOIO-IMPLEMENTACAO-CERTIFICACAO-SGQ-IATF-16949",
    category1: "Consultoria",
    category2: "Projetos",
    category3: "Projetos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "A certificação é um processo de avaliação da conformidade, realizado por entidades acreditadas para o efeito, com o objectivo de demonstrar que um dado sistema de gestão cumpre com os requisitos que lhe são aplicáveis.",
    objectives:
      "Apoiar o processo de avaliação actual de conformidade (gap analysis) com os requisitos em consideração, planeamento e execução de um plano de ações com vista ao cumprimento dos requisitos e realização de uma auditoria interna para validação da eficácia das acções implementadas.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 136,
    title: "PROJETO DE APOIO À AUTO AVALIAÇÃO E IMPLEMENTAÇÃO DA TISAX",
    url: "PROJETO-APOIO-AUTO-AVALIACAO-IMPLEMENTACAO-TISAX",
    category1: "Consultoria",
    category2: "Projetos",
    category3: "Projetos",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "Os prestadores de serviços e fornecedores devem comprovar regularmente aos seus clientes que atendem aos requisitos de alta segurança para os dados fornecidos, e é aí que entra a importância da certificação TISAX.",
    objectives:
      "O processo de certificação garante um alto grau de compatibilidade e transparência, fortalecendo a confiança entre serviços e clientes com a obtenção dos rótulos relevantes da TISAX como um requisito obrigatório. Prestamos o serviço de consultoria e apoio à auto avaliação e implementação da TISAX, de acordo com a VDA ISA.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 138,
    title: "VDA 6.3 QUALIFICAÇÃO PARA AUDITOR DE PROCESSO",
    url: "VDA-6.3-QUALIFICACAO-PARA-AUDITOR-PROCESSO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Formação VDA 6.X",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "Qualificar os participantes como auditores de processo do ciclo de vida do produto, apresentando as exigências gerais, métodos, princípios, esquemas de avaliação e análise de risco.",
    objectives:
      "Considerando, a abordagem por processo e os respetivos requisitos específicos de cliente (CSR’s), esta formação visa dotar os formandos dos conhecimentos básicos para uma auditoria de processo VDA 6.3. Incluindo, os requisitos gerais, métodos, princípios, esquema de avaliação e análise de risco. Ainda, o questionário completo é completamente explicado, de acordo com os elementos de processo (P2 a P7), de modo que os formandos possam identificar os riscos ao longo da cadeia de fornecimento.",
    online: no,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 139,
    title: "VDA 6.3 EXAME DE QUALIFICAÇÃO DE AUDITOR",
    url: "VDA-6.3-EXAME-QUALIFICACAO-AUDITOR",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Formação VDA 6.X",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "O exame é dividido em duas partes, uma escrita e outra oral. Sendo aprovado em ambas receberá o certificado com um número de registo e carteira de auditor VDA QMC. Os seus dados também serão inseridos na base de dados associada da VDA QMC. O exame escrito possui 40 perguntas e o período de realização é de 60 minutos. O exame oral tem uma fase de preparação de 30 minutos e a entrevista em si de 20 minutos. Para ser aprovado é necessário pelo menos 70% (min. 28 questões) de aproveitamento.",
    objectives: "Certificação de auditor de processo VDA 6.3",
    online: no,
    presential: no,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 140,
    title: "VDA 6.3 WORKSHOP PARA AUDITOR DE PROCESSO CERTIFICADO",
    url: "VDA-6.3-WORKSHOP-AUDITOR-PROCESSO-CERTIFICADO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Formação VDA 6.X",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "O processo de auditoria 6.3 VDA é um procedimento eficaz para avaliar os processos em conexão com o planeamento e a fabricação de um produto. Para realizar auditorias de processo da VDA 6.3, é necessário conhecimento abrangente, experiência e competência. Assim, a qualificação do pessoal relevante é indispensável. Após a conclusão bem-sucedida da qualificação para auditor de processo VDA 6.3, surgem questões de desenvolvimento contínuo: Repetidas vezes, enquanto realizam auditorias na prática, os auditores encontrarão situações nas quais o manuseio ou a avaliação adequada dos requisitos levam a incertezas.",
    objectives:
      "Esta formação oferece a oportunidade de discutir essas situações na prática diária de auditoria com os participantes e um especialista, e determinar a adequação e aplicação dos requisitos e avaliações.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 141,
    title: "VDA 6.3 QUALIFICAÇÃO PARA AUDITOR DE PROCESSO - ONE WEEK TRAINING",
    url: "VDA-6.3-QUALIFICAÇÃO-AUDITOR-PROCESSO-1WEEK-TRAINING",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Formação VDA 6.X",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "A auditoria de processo VDA 6.3 é um procedimento de avaliação de processos relacionados com o planeamento e fabricação de um produto. Estas auditorias geralmente são realizadas dentro da organização ou com fornecedores. Têm uma contribuição valiosa para a otimização de processos com linhas de fabricação já existentes",
    objectives:
      "Qualificar os participantes como auditores de processo do ciclo de vida do produto, apresentando as exigências gerais, métodos, princípios, esquemas de avaliação e análise de risco + o exame de certificação de auditor de processo VDA 6.3.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "40",
    associated_training: [],
  },
  {
    id: 142,
    title: "VDA 6.3 - AUDITORES DE PROCESSO PARA SERVIÇOS",
    url: "VDA-6.3-AUDITORES-PROCESSO-SERVIÇOS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Formação VDA 6.X",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "A auditoria de processo VDA 6.3 é um procedimento de avaliação de processos relacionados com o planeamento e fabricação de um produto. Estas auditorias geralmente são realizadas dentro da organização ou com fornecedores. Têm uma contribuição valiosa para a otimização de processos com linhas de fabricação já existentes.",
    objectives:
      "Ensinar os participantes por meio dos requisitos das auditorias de processo. Possibilita a realização de auditorias para os serviços.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 143,
    title: "VDA 6.3 - RENOVAÇÃO DO CARTÃO DE AUDITOR DE PROCESSO",
    url: "VDA-6.3-RENOVACAO-CARTAO-AUDITOR-PROCESSO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Formação VDA 6.X",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "A validade dos certificados VDA 6.3 são de duração limitada, os auditores têm de fazer uma formação continua para manter as suas qualificações atualizadas.",
    objectives:
      "Os auditores certificados da VDA 6.3 são capazes de conduzir auditorias de processo de forma independente internamente e nos fornecedores. Com esta renovação, os participantes recebem a reconfirmação das suas aptidões.",
    online: no,
    presential: no,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 144,
    title: "MANUAL VDA 6.3 – 3.a EDIÇÃO AUDITORIA DO PROCESSO",
    url: "MANUAL-VDA-6.3-3EDICAO-AUDITORIA-PROCESSO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Formação VDA 6.X",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "O manual VDA 6.3 é um método importante e bem estabelecido para análise e implementação de auditorias de processos com a finalidade de avaliar eficazmente o desempenho real dos processos. Este inclui um questionário de avaliação, critérios de avaliação / classificação e requisitos de qualificação dos auditores de processo. A aplicação da norma VDA 6.3 é recomendada para implementação e manutenção dos Sistemas de Gestão da Qualidade.",
    objectives: "",
    online: no,
    presential: no,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 145,
    title: "VDA 6.4 - QUALIFICAÇÃO DE AUDITORES DE 1a, 2a E 3a PARTE",
    url: "VDA-6.4-QUALIFICACAO-AUDITORES",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Formação VDA 6.X",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "Na indústria automóvel, ferramentas, plantas, máquinas, equipamentos, medições e testes devem atender aos mais altos padrões de qualidade. O VDA 6.4 é a escolha para os fabricantes e fornecedores de recursos de produção internacionais",
    objectives:
      "A formação é dada para os participantes poderem implementar e verificar os requisitos da norma VDA 6.4.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 146,
    title: "VDA 6.5 QUALIFICAÇÃO PARA AUDITOR DE PRODUTO",
    url: "VDA-6.5-QUALIFICACAO-AUDITOR-PRODUTO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Formação VDA 6.X",
    opco: false,
    partner: "VDA QMC",
    thumbnail: vda,
    description:
      "s auditorias de produtos são importantes para garantir a qualidade do produto. A VDA 6.5 é uma diretriz para a gestão de programas de auditoria de produtos, permitindo ainda que as organizações desenvolvam um esquema de classificação adaptado aos seus próprios produtos, assim como uma ferramenta de melhoria contínua.",
    objectives:
      "Os formandos ficam dotados com metodologias para criar, implementar e melhorar planos e programas de auditorias a produtos. São apresentados métodos para identificar possíveis riscos e melhorias no produto, a fim de reduzir de forma sustentável o índice de reclamações.",
    online: yes,
    presential: yes,
    online_hours: "DISPONÍVEL ONLINE A PARTIR DO 2o TRIMESTE DE 2021",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 147,
    title:
      "VDA 6.7 - QUALIFICAÇÃO PARA AUDITORES DE PROCESSO PARA PRODUÇÃO DE UNIDADES E FABRICO DE MEIOS DE PRODUÇÃO",
    url: "VDA6.7-QUALIFICACAO-AUDITORES-PROCESSO-PRODUCAO-UNIDADES-FABRICO-MEIOS-PRODUCAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Formação VDA 6.X",
    opco: false,
    partner: "VDA QMC",
    thumbnail: vda,
    description:
      "Os requisitos dos clientes serão atendidos com melhor performance quando as auditorias de processo são realizadas para os setores da produção unitária e dos meios de produção.",
    objectives:
      "Os formandos adquirem conhecimentos de como preparar, executar, avaliar e acompanhar auditorias de processo de acordo com a VDA 6.7 Assim como percebem as conexões entre sistema, processo e auditorias de produtos, assim como o conteúdo e aplicação das análises de potencial.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 148,
    title: "FMEA - WORKSHOP ON HARMONISED AIAG-VDA",
    url: "FMEA-WORKSHOP-HARMONISED-AIAG-VDA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "Os fornecedores que entregam os seus produtos aos fabricantes alemães e norte-americanos (OEM) são obrigados a realizar a sua análise de risco de produto e processo segundo a metodologia FMEA, de acordo com os manuais FMEA de VDA e AIAG.",
    objectives:
      "O objetivo deste workshop é apresentar aos formandos as alterações resultantes da versão harmonizada AIAG e VDA da metodologia FMEA e dar dicas práticas para aplicação.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 149,
    title: "FMEA - BASIC TRAINING HARMONISED AIAG-VDA",
    url: "FMEA-BASIC-TRAINING-HARMONISED-AIAG-VDA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "A indústria é desafiada pela exigências de qualidade cada vez maiores por parte dos clientes, pela necessária otimização de custos dos produtos e processos e pela maior complexidade, bem como pela responsabilidade pelo produto por parte do criador e do fabricante, exigidas pela legislação. Assim, o método FMEA é utilizado para abordar os aspetos técnicos da redução de risco.",
    objectives:
      "A edição do manual de FMEA em conjunto com o AIAG e VDA descreve a nova metodologia que os fornecedores da industria automóvel devem adotar, permitindo entregar os produtos, de acordo com as necessidades e expetativas dos clientes.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 150,
    title: "MANUAL - FMEA AIAG & VDA",
    url: "MANUAL-FMEA-AIAG-VDA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "O Manual FMEA AIAG & VDA 1a Edição, em Português, possui mais conteúdo, além de esclarecer dúvidas frequentes para a elaboração dos FMEA's nas organizações. Uma grande alteração é o alinhamento entre os pontos em comum entre os dois principais tipos de FMEA – o DFMEA e o PFMEA.",
    objectives: "",
    online: no,
    presential: no,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 151,
    title: "AUTOMOTIVE CORE TOOLS - MÓDULO I",
    url: "AUTOMOTIVE-CORE-TOOLS-MODULOI",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "Este curso mostra como os problemas em fases iniciais dos projetos automóveis devem ser projetados, com a implementação específica de métodos que suportam a obtenção de resultados planeados e como os métodos são integrados de maneira correta e eficiente.",
    objectives:
      "O primeiro bloco do seminário de duas partes lida com a gestão de projetos na fase de conceito e desenvolvimento de produto e processo. As atividades das várias fases do projeto são tratadas da mesma forma que os métodos para garantir a qualidade do produto.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 152,
    title: "AUTOMOTIVE CORE TOOLS - MÓDULO II",
    url: "AUTOMOTIVE-CORE-TOOLS-MODULOII",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "A maioria dos fabricantes e fornecedores de automóveis exige o uso de uma ou mais das principais ferramentas. Por isso, as organizações cujos funcionários dominam as core tools podem garantir aos seus clientes que a IATF 16949 e os requisitos aplicáveis do manual de referência de qualidade serão atendidos sem falhas.",
    objectives:
      "A segunda parte do curso core tools foca-se nos métodos estatísticos utilizados no desenvolvimento do processo, na transição para a produção em massa e na produção em massa para assegurar a capacidade dos sistemas de medição e os processos de produção.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 153,
    title: "AUTOMOTIVE CORE TOOLS - FOR PROCESS AND SYSTEM AUDITORS",
    url: "AUTOMOTIVE-CORE-TOOLS-FOR-PROCESS-SYSTEM-AUDITORS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "Para garantir uma alta qualidade das atividades de auditoria nas empresas e em toda a cadeia de fornecimento, os auditores precisam de ter a competência adequada em relação aos respetivos métodos. A IATF 16949 enfatiza claramente esse requisito para a competência dos auditores. A abordagem eficiente durante as auditorias é um importante fator de sucesso.",
    objectives:
      "Os participantes treinam em situações típicas de auditoria no contexto das Automotive Core Tools, com foco numa abordagem eficiente. Tanto a avaliação da aplicação tecnicamente correta dos respetivos métodos quanto a avaliação específica de casos de situações típicas são uma componente central da formação.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 154,
    title: "VDA 2 - PRODUCTION PROCESS AND PRODUCT APPROVAL (PPA)",
    url: "VDA-2-PRODUCTION PROCESS-PRODUCT-APPROVAL",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "Para compreender as muitas exigências dirigidas a um representante de segurança e conformidade de produto, é necessário ter competências e qualificações abrangentes. Esta formação é direcionado para os requisitos do trabalho diário dos participantes, enquanto envolvidos na temática da integridade de produto. Os tópicos centrais da integridade do produto são explorados, e a competência e tarefas como representante de segurança do produto é desenvolvida.",
    objectives:
      "Depois de uma introdução ao tema, é abordado como o tema é gerido na organização, como a integridade do produto é abordado ao longo ciclo de vida, a reação em caso de desvios no produto incluindo avaliação de risco, e ainda serão abordados exemplos de ferramentas e métodos.",
    online: yes,
    presential: yes,
    online_hours: "14",
    presential_hours: "14",
    associated_training: [],
  },
  {
    id: 184,
    title: "VDA FFA FIELD FAILURE ANALYSIS UPGRADE TRAINING",
    url: "VDA-FFA-FIELD-FAILURE-ANALYSIS-UPGRADE-TRAINING",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "A Análise de falha de campo fornece uma abrangente abordagem para identificar causas e foi completamente revista em 2018. Nesta formação de um dia, o foco é especificamente para utilizadores da versão anterior que deseja ser atualizado.",
    objectives:
      "O foco desta formação está em mencionar as mudanças e as revisões do manual FFA de 2018. Nesta formação, os participantes iram rever a análise e o processo de NTF e aprofundar os seus conhecimentos sobre o processo de solução de problemas.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 185,
    title: "VDA FFA FIELD FAILURE ANALYSIS USER TRAINING",
    url: "VDA-FFA-FIELD-FAILURE-ANALYSIS-USER-TRAINING",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "Para reclamações de campo, as peças reclamadas pode ser solicitada por parte do fabricante do veículo (OEM) ou pelo fornecedor através do OEM, no qual é aplicada da metodologia do Field Failure Analysis. A Análise de falha de campo fornece uma abrangente abordagem para identificar causas de falhas.",
    objectives:
      "O foco desta formação é de providenciar aos formandos uma abordagem especializada do método de análise de falhas, de acordo com o referencial, de modo a implementar nas suas organizações métodos de análise e o processo de Non Trouble Found (NTF) .",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 186,
    title: "PSB PARA PSCR (UPGRADE) PRODUCT SAFETY & CONFORMITY REPRESENTATIVE",
    url: "PSB-PSCR-RODUCT-SAFETY-CONFORMITY-REPRESENTATIVE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "Todas as organizações da indústria automóvel são obrigadas a garantir a segurança e a conformidade dos seus produtos. Para o efeito, os atuais estatutos jurídicos para a integridade do produto, dos respetivos paises e regiões, são aplicáveis, de acordo com as expectativas de segurança do público em geral.",
    objectives:
      "Requalificação do actual PSB para os novos requisitos do PSCR , de forma a garantir as competências internas como produtor, mas ao longo da cadeia de fornecimento, desde da fase de conceção, desenvolvimento até à fase de produção e fase de uso dos produtos.",
    online: yes,
    presential: yes,
    online_hours: "7",
    presential_hours: "7",
    associated_training: [],
  },
  {
    id: 187,
    title: "PSCR PRODUCT SAFETY & CONFORMITY REPRESENTATIVE",
    url: "PSCR-PRODUCT-SAFETY-CONFORMITY-REPRESENTATIVE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "Para compreender as muitas exigências dirigidas a um representante de segurança e conformidade de produto, é necessário ter competências e qualificações abrangentes. Esta formação é direcionado para os requisitos do trabalho diário dos participantes, enquanto envolvidos na temática da integridade de produto. Os tópicos centrais da integridade do produto são explorados, e a competência e tarefas como representante de segurança do produto é desenvolvida.",
    objectives:
      "Depois de uma introdução ao tema, é abordado como o tema é gerido na organização, como a integridade do produto é abordado ao longo ciclo de vida, a reação em caso de desvios no produto incluindo avaliação de risco, e ainda serão abordados exemplos de ferramentas e métodos.",
    online: yes,
    presential: yes,
    online_hours: "14",
    presential_hours: "14",
    associated_training: [],
  },
  {
    id: 155,
    title: "VDA 5 - CAPABILITY OF MEASUREMENT PROCESS",
    url: "VDA-5CAPABILITY-MEASUREMENT-PROCESS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "O volume 5 da VDA descreve como cumprir os requisitos do sistema de medição, pela seleção correta do equipamento de teste, identificação interna da incerteza de calibração ou monitoramento contínuo de processos de teste",
    objectives:
      "No final da formação os participantes deverão ser capazes de selecionar o teste de capacidade do sistema de medição adequado, identificar e calcular as influências sobre a capacidade dos processos de medição. Devem ser capazes de testar as caraterísticas dos atributos e validação do software de medição.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 156,
    title: "MLA - MATURITY LEVEL ASSURANCE FOR NEW PARTS",
    url: "MLA-MATURITY-LEVEL-ASSURANCE-NEW-PARTS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "É uma diretriz para as empresas da indústria automóvel na implementação de metodologia do nível de maturidade na validação dos produtos que recebem dos seus fornecedores. Também pode ser considerada como um requisito na relação cliente-fornecedor (incluindo, por exemplo, nas condições de compra, especificações de requisitos e requisitos específicos do projeto).",
    objectives:
      "Propiciar aos participantes o entendimento das oito etapas do desenvolvimento do produto, assim como avaliar o nível de risco dos produtos que estão sendo desenvolvidos e avaliar os critérios de medição nos oito níveis de maturidade.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "4160",
    associated_training: [],
  },
  {
    id: 157,
    title: "FORMEL Q",
    url: "FORMEL-Q",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "A Formel Q é um conjunto de regras e regulamentos que abrange todos os requisitos do grupo Volkswagen para a qualidade e gestão de projetos para a cooperação ao longo da cadeia de fornecedores, considerando o ciclo de vida do produto. O cumprimento destes requisitos visa garantir a satisfação do cliente e garantir um produto conforme em série.",
    objectives:
      "A formação visa abordar os principais temas do Formel Q, nomeadamente o Formel Q Concreto, o Formel Q Integral Novas Peças, o Formel Q Capacidade, com o Apêndice de Auditoria de Processo e ainda Formel Q Capacidade de Software.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 158,
    title: "AUTOMOTIVE SPICE® - INTRODUÇÃO",
    url: "AUTOMOTIVE-SPICE-INTRODUCAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "A ISO/IEC 15504 e a ISO/IEC 330xx, respectivamente (SPICE: Software Process Improvement and Capability Determination), constituem um padrão mundial para avaliar a capacidade do processo e melhorar os processos no desenvolvimento de software e sistemas. Esse padrão é usado para classificar e qualificar fornecedores de ECU e software na indústria automóvel e pode ser considerado em sistemas baseados em software na indústria automóvel, bem como na avaliação de fornecedores dos OEMs.",
    objectives:
      "Os participantes são apresentados ao Automotive SPICE®, método de avaliação e modelo de avaliação e receberão uma explicação das áreas de processo e processos individuais. A avaliação SPICE® e os níveis de capacidade 2 e 3 serão apresentados em detalhes.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 159,
    title: "AUTOMOTIVE SPICE® - EXECUTIVOS",
    url: "AUTOMOTIVE-SPICE-EXECUTIVOS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "É um modelo de avaliação compatível com ISO / IEC 330xx e adaptado às necessidades da indústria automóvel. Desde 2007 é o critério para o desenvolvimento de sistemas de software para fornecedores e um recurso permanente da avaliação de fornecedores das OEMs.",
    objectives:
      "Esta formação oferece uma visão SPICE® e a sequência de uma avaliação SPICE® e é constituída por palestras, exercícios e exemplos de casos e a oportunidade para questões, discussões e troca de experiências.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 160,
    title: "EOS – ELECTRICAL OVERSTRESS IN THE AUTOMOTIVE INDUSTRY",
    url: "EOS-ELECTRICAL-OVERSTRESS-AUTOMOTIVE-INDUSTRY",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "Lidar com dispositivos semicondutores mostrando uma assinatura de sobretensão elétrica.",
    objectives:
      "Dotar os formandos de conhecimentos tais como: significado e relevância do tópico “EOS” para a indústria automóvel; processo e tratamento de dispositivos semicondutores mostrando uma assinatura de dano físico eletricamente induzido (EIPD); classificação de um retorno específico como “Nível 1” e “Nível 2” casos usando análise estatística e um questionário, bem como processamento em conformidade; apoio na descoberta da causa raiz para devoluções de campo relacionadas ao EIPD ao longo da cadeia de abastecimento",
    online: no,
    presential: no,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 161,
    title: "VDA 19.1 - SKILLED ASSISTANT STANDARDIZED CLEANLINESS INSPECTION",
    url: "VDA19-1-SKILLED-ASSISTANT-STANDARDIZED-CLEANLINESS-INSPECTION",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "A limpeza técnica de componentes e grupos de montagem é uma característica funcional importante na produção de veículos modernos.",
    objectives:
      "É a primeira publicação padrão abrangente para lidar com a abordagem e procedimentos para caracterizar a limpeza dos produtos na cadeia de qualidade na indústria automóvel.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 162,
    title: "VDA 19.2 - SKILLED ASSISTANT TECHNICAL CLEANLINESS IN ASSEMBLY",
    url: "VDA-19-2-SKILLED-ASSISTANT-TECHNICAL-CLEANLINESS-ASSEMBLY",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Gestão da Qualidade",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "A experiência passada mostra que, para garantir a qualidade da limpeza, a limpeza dos componentes da montagem é decisiva, mas não é suficiente por si só. Após a limpeza das peças individuais, muitos fatores e processos de influência podem causar a recontaminação de peças ou grupos de montagem com partículas com funcionalidade crítica.",
    objectives:
      "Os participantes aprendem em 2 dias de formação, com base nas especificações de limpeza, a avaliar medidas afim de evitar novas contaminações. Ao considerar vários efeitos do meio ambiente, logística, pessoal e equipamentos de montagem, bem como métodos para medir influencias de limpeza, os participantes aprendem a abordar a limpeza técnica de maneira independente e sistemática para identificar medidas de limpeza irracionais e evitar investimentos desnecessários",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 163,
    title: "IATF 16949 - EXECUTIVOS",
    url: "IATF-16949-EXECUTIVOS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "IATF 16949",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "A conformidade com a IATF 16949 é a condição para constar na lista de fornecedores de fabricantes de automóveis. Isso também inclui a disposição dos executivos de participar ativamente e da sua responsabilidade pela qualidade: todos os executivos de uma organização devem cumprir as suas obrigações com relação à qualidade, de acordo com o novo conjunto de regras.",
    objectives:
      "Visa dotar os participantes de conhecimentos gerais que os permitam implementar as responsabilidades executivas requeridas pela IATF 16949, adquirindo conhecimentos ao nível da estrutura e conteúdo da norma IATF 16949 e ISO9001.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 164,
    title: "IATF 16949 - IMPLEMENTAÇÃO NA ORGANIZAÇÃO",
    url: "IATF-16949-IMPLEMENTACAO-ORGANIZACAO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "IATF 16949",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "Destinado a uma empresa que se esteja a preparar para a certificação IATF 16949 e que precise de lidar com a implementação dos novos requisitos. Serão apresentados os requisitos de ambas as normas IATF 16949 e ISO 9001 e orientação específica sobre como interpretar os requisitos e sobre o que precisa ser feito para os implementar na organização.",
    objectives:
      "Visa dotar os participantes de conhecimentos detalhados que os permitam implementar os requisitos da IATF na organização. São fornecidos ao longo da formação, interpretações oficiais sobre os requisitos IATF 16949 e ISO 9001, bem como com sobre as regras da implementação e certificação da IATF.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 165,
    title: "IATF 16949 - QUALIFICAÇÃO DE AUDITORES DE 1a E 2a PARTE",
    url: "IATF-16949-QUALIFICACAO-AUDITORES",
    category1: "Formação",
    category2: "Automóvel",
    category3: "IATF 16949",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "Na indústria automóvel, a qualificação e competência de auditores de 1a e 2a partes na IATF 16949 é de vital importância. Por um lado, para capacitar a sua própria empresa e introduzir as ações de melhoria contínua através de uma avaliação adequada do sistema de gestão da qualidade e verificação do cumprimento dos requisitos da IATF 16949.",
    objectives:
      "Visa dotar os participantes de conhecimentos sobre os principais conteúdos e requisitos da IATF 16949 e ISO 9001, assim como da estrutura da IATF 16949 e como avaliar os mesmos durante a auditoria, de acordo com a abordagem por processos delineada pelo IATF. Além disso, são informados sobre as regras de certificação da IATF e capacitados para planear, preparar e realizar uma auditoria do sistema de gestão da qualidade.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "40",
    associated_training: [],
  },
  {
    id: 166,
    title: "MANUAL - THE MEMORY JOGGER: IATF 16949 & ISO 9001",
    url: "MANUAL-THE-MEMORY-JOGGER-IATF-16949-ISO-9001",
    category1: "Formação",
    category2: "Automóvel",
    category3: "IATF 16949",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "IATF 16949: 2016 Automotive - Um guia de bolso para implementar um sistema de gestão da qualidade automotiva IATF 16949: 2016. ISO 9001: 2015 - Implementando uma abordagem de processo compatível com os padrões dos sistemas de gestão de qualidade ISO 9001: 2015.",
    objectives: "",
    online: no,
    presential: no,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 167,
    title: "IATF 16949 - EXAME PARA AUDITOR DE 1a E 2a PARTE",
    url: "IATF-16949-EXAME-AUDITOR",
    category1: "Formação",
    category2: "Automóvel",
    category3: "IATF 16949",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "O exame para o auditor de 1a e 2a parte de IATF 16949 oferece aos participantes um nível de qualificação para a realização de auditorias. O exame é dividido em duas partes, uma escrita e outra oral. Sendo aprovado em ambas receberá o certificado com um número de registo e carteira de auditor VDA QMC. Os seus dados também serão inseridos na base de dados associada da VDA QMC. O exame escrito possui 40 perguntas e o período de realização é de 60 minutos. O exame oral tem uma fase de preparação de 30 minutos e a entrevista em si de 20 minutos. Para ser aprovado é necessário pelo menos 70% (min. 28 questões) de aproveitamento.",
    objectives: "Certificação de auditor de sistema IATF 16949",
    online: no,
    presential: no,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 168,
    title:
      "IATF 16949 - QUALIFICAÇÃO DE AUDITORES DE 1a E 2a PARTE COM EXAME DE AUDITOR - ONE WEEK TRAINING",
    url: "IATF-16949-QUALIFICACAO-AUDITORES-PARTE-EXAME-AUDITOR-1WEEK",
    category1: "Formação",
    category2: "Automóvel",
    category3: "IATF 16949",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "A competência dos auditores da IATF 16949 é de importância decisiva: por um lado, porque precisam implementar os requisitos da IATF 16949 na organização e avaliar a eficácia das ações de gestão da qualidade. Por outro lado, para que possam auditar os fornecedores quanto à conformidade do seu SGQ face à IATF 16949.",
    objectives:
      "O foco desta formação está em apresentar um conhecimento especializado que permita aos auditores da IATF 16949 a capacidade de conduzirem auditorias ao sistema. O exame e o certificado complementam a certificação de auditor, fornecendo evidências da qualificação de auditor da IATF 16949.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "40",
    associated_training: [],
  },
  {
    id: 169,
    title: "IATF 16949 - EXAME PARA REQUALIFICAÇÃO DE AUDITOR DE 1a E 2a PARTE",
    url: "IATF-16949-EXAME-REQUALIFICACAO-AUDITOR",
    category1: "Formação",
    category2: "Automóvel",
    category3: "IATF 16949",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "Estar em conformidade com a IATF 16949 é a condição para a aceitação na lista de fornecedores de fabricantes de automóveis. Em preparação para a certificação, os responsáveis de uma organização devem lidar com a implementação concreta dos requisitos.",
    objectives:
      "Os auditores de 1a e 2a parte estão qualificados para realizarem auditorias internas aos sistema IATF 16949 assim como aos fornecedores. Ao realizar este exame, os participantes mais uma vez obtêm confirmação dos seus conhecimentos, assim como a evidência da atualização e validação da qualificação para o cumprimento dos requisitos do cliente ou fornecedores em relação à competência do auditor face à norma IATF 16949.",
    online: no,
    presential: no,
    online_hours: "-",
    presential_hours: "-",
    associated_training: [],
  },
  {
    id: 170,
    title:
      "IATF 16949 - WORKSHOP PARA A REQUALIFICAÇÃO DE AUDITOR DE 1a E 2a PARTE",
    url: "IATF-16949-WORKSHOP-REQUALIFICACAO-AUDITOR",
    category1: "Formação",
    category2: "Automóvel",
    category3: "IATF 16949",
    opco: false,
    thumbnail: vda,
    partner: "VDA QMC",
    description:
      "De acordo com a ISO 19011, os auditores devem continuamente atualizar o seus conhecimentos, mediante a revalidação das suas competências. Isso inclui o desenvolvimento prático e a realização de auditorias internas e aos fornecedores.",
    objectives:
      "Esta requalificação concentra-se numa troca de experiências e atualização de conhecimentos e habilidades dos auditores. Os tópicos principais são o conteúdo da ISO 19011, IATF 16949 e os seus requisitos, incluindo a ISO 9001, bem como as áreas que carecem de implementação. Além disso, os formandos podem apresentar as suas experiências sobre a implementação do IATF 16949 para discussão.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 171,
    title: "STANDARD WORK FOR IATF 16949:2016",
    url: "STANDARD-WORK-16949-2016",
    category1: "Formação",
    category2: "Automóvel",
    category3: "PRODUTIVIDADE APLICADA À IATF 16949",
    opco: false,
    thumbnail: smmt,
    partner: "SMMT",
    description:
      'Adotando a abordagem "Aprenda fazendo". Providencia-se uma compreensão mais profunda de alguns dos principais conceitos do Trabalho Padrão para a IATF16949, por meio de instruções, discussões e atividades em pequenos grupos.',
    objectives:
      "No final deste workshop o formando será capaz de selecionar corretamente as técnicas de padronização adequadas ao seu ambiente, para garantir que o processo seja consistente e que os resultados sejam previsíveis. Compreender os fundamentos da padronização e sua importância na construção de qualquer programa de Melhoria Contínua. Preparar formulários de trabalho padronizados. Preparar formulários de trabalho padronizados e introduzir técnicas de padronização para melhorar a formação; eliminação de resíduos, sustentabilidade de melhorias e obter mais previsibilidade dos resultados.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 172,
    title: "TPM FOR IATF 16949:2016",
    url: "TPM-IATF-16949-2016",
    category1: "Formação",
    category2: "Automóvel",
    category3: "PRODUTIVIDADE APLICADA À IATF 16949",
    opco: false,
    thumbnail: smmt,
    partner: "SMMT",
    description:
      "Os princípios do TPM estão alinhados com o objetivo do padrão de qualidade IATF 16949 e permitem desenvolver um sistema de gestão de equipamentos que envolve os funcionários, garante resultados de qualidade, aumenta a disponibilidade e o desempenho do equipamento e se baseia no desenvolvimento de operações padrão.",
    objectives:
      "Este curso de formação de um dia fornece uma visão geral dos conceitos e abordagens práticas para a implementação de um programa eficaz de TPM, enfatizando em detalhes as áreas relevantes para atender aos requisitos do IATF 16949: 2016. Os participantes adquirirão competências de como usar esses princípios para melhorar a eficiência e o desempenho dos equipamentos.",
    online: yes,
    presential: yes,
    online_hours: "8",
    presential_hours: "8",
    associated_training: [],
  },
  {
    id: 173,
    title: "MMOG/LE V5 - GESTÃO DE MATERIAIS E AVALIAÇÃO LOGÍSTICA",
    url: "MMOG-LE-V5-GESTAO-MATERIAIS-AVALIACAO-LOGISTICA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "MMOG/LE",
    opco: false,
    thumbnail: oddete,
    partner: "ODETTE",
    description:
      "As Diretrizes Operacionais de Gestão de Materiais Globais / Avaliação Logística (MMOG/LE) são uma ferramenta de auto- avaliação e melhoria contínua dos processos relativos à cadeia de fornecimento, que melhoram a eficiência e a precisão do gestão de materiais e reduzem os custos de erros e desperdícios.",
    objectives:
      "Dotar os participantes de competências ao nível da gestão da cadeia de fornecimento através da directriz MMOG/LE, respectivo sistema de avaliação, mediante a avaliação da eficácia da cadeia de fornecimento. Apresentar os recursos da nova plataforma web MMOG.np e analisar os diversos capítulos, abordando requisitos e critérios incluindo as alterações da versão v5 em comparação à versão anterior.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 174,
    title: "FIEV 2.0 - QUALIFICAÇÃO AUDITORES DE PROCESSO",
    url: "FIEV-2-0-QUALIFICACAO-AUDITORES-PROCESSO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "FIEV",
    opco: false,
    thumbnail: fiev,
    partner: "FIEV",
    description:
      "Este curso destina-se a transmitir os conceitos e requisitos relativos ao modelo de auditoria de processo FIEV v2.0. Para o efeito, a FIEV, em conjunto com a PSA Peugeot Citroën e Renault, desenvolveram o referencial adequado à auditoria e classificação da eficácia e eficiência dos processos produtivos.",
    objectives:
      "Visa dotar os formandos de competências para a aplicação dos requisitos do questionário do FIEV V2.0, através da cadeia de fornecimento, assim como dotar os formandos de competências para a realização de auditorias de processo através da cadeia de fornecimento mediante a capacidade para identificar riscos e determinar o potencial de novos fornecedores. O exame é realizado presencialmente.",
    online: yes,
    presential: yes,
    online_hours: "24",
    presential_hours: "24",
    associated_training: [],
  },
  {
    id: 175,
    title: "APQP/PPAP - DE ACORDO COM O AQF DA PSA",
    url: "APQP-PPAP-ACORDO-AQF-PSA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "PSA",
    thumbnail: psa,
    opco: false,
    partner: "PSA",
    description:
      "Este curso destina-se a fornecedores ou potenciais fornecedores interessados em entender os requisitos do PSA de acordo com o SQM (Manual de Qualidade do Fornecedor) e permite implementar os métodos e ferramentas para um projeto bem-sucedido.",
    objectives:
      "Dotar os formandos de conhecimentos sobre o contexto das evoluções do manual de exigências da PSA. Conhecer as exigências e particularidades do APQP/PPAP PSA assim como compreender a natureza dos requisitos PSA no enquadramento de um projeto de desenvolvimento conduzido através do APQP/PPAP. Conhecer os requisitos e as regras para aplicação do APQP/PPAP em colaboração com outros interlocutores PSA e saber construir um plano de acções para aplicação das novas exigências.",
    online: no,
    presential: yes,
    online_hours: "-",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 176,
    title: "ANPQP V. 3.1 - ALLIANCE NEW PRODUIT QUALITY PROCEDURE",
    url: "ANPQP-V3-1-ALLIANCE-NEW-PRODUIT-QUALITY-PROCEDURE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "Renault",
    thumbnail: renault,
    opco: false,
    partner: "Renault",
    description:
      "A Alliance New Product Quality Procedure (ANPQP) visa criar um ambiente de aprendizagem para os fornecedores Renault, com o objectivo da melhoria do processo de desenvolvimento de novos produtos e processos, de acordo com os requisitos.",
    objectives:
      "O objetivo da formação consiste nos formandos compreenderem os conceitos ANPQP V.3.1 e a sua arquitetura. Compreender o funcionamento do relacionamento entre a RENAULT-NISSAN e os seus fornecedores durante o desenvolvimento em série e o ciclo de vida dos seus produtos. Alinhamento com as principais mudanças da Versão 3.1.",
    online: yes,
    presential: yes,
    online_hours: "14",
    presential_hours: "14",
    associated_training: [],
  },
  {
    id: 177,
    title: "CQI 9 - TRATAMENTO TÉRMICO (HEAT TREATMENT)",
    url: "CQI9-TRATAMENTO-TERMICO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "AIAG/PLEXUS",
    thumbnail: aiag,
    opco: false,
    partner: "AIAG/PLEXUS",
    description:
      "Melhorar a gestão dos processos de tratamento térmico, focando na prevenção e melhoria da qualidade pela promoção de maior conhecimento e controlo sobre o processo e pela redução de riscos de ocorrência de produtos não conformes e custos da não qualidade. Entender as exigências do CQI-9 e garantir a qualificação dos auditores internos.",
    objectives:
      "Dotar os formandos dos conhecimentos e competências necessárias para a interpretação e aplicação do requisito CQI-9: “Processos Especiais: Avaliação do Sistema de Tratamento Térmico”, tornando-os capazes de avaliar e auditar o sistema de tratamento térmico conforme as tabelas de processos.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 178,
    title: "CQI 11 - TRATAMENTO DE SUPERFÍCIE (PLATING)",
    url: "CQI-11-TRATAMENTO-SUPERFICIE",
    category1: "Formação",
    category2: "Automóvel",
    category3: "AIAG/PLEXUS",
    opco: false,
    thumbnail: aiag,
    partner: "AIAG/PLEXUS",
    description:
      "Melhorar a gestão dos processos de tratamento de superfície, focando na prevenção e melhoria da qualidade pela promoção de maior conhecimento e controlo sobre o processo e pela redução de riscos de ocorrência de produtos não conformes e custos da não qualidade. Entender as exigências do CQI-11 e garantir a qualificação dos auditores internos.",
    objectives:
      "Dotar os formandos dos conhecimentos e competências necessárias para a interpretação e aplicação do requisito CQI-11 ”Processos Especiais: Avaliação do Sistema de Tratamento Superficial (Plating)”, tornando-os capazes de avaliar e auditar o sistema de tratamento superficial conforme as tabelas de processos.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 179,
    title: "CQI 12 - SISTEMA DE REVESTIMENTO (COATING)",
    url: "CQI-12-SISTEMA-REVESTIMENTO",
    category1: "Formação",
    category2: "Automóvel",
    category3: "AIAG/PLEXUS",
    opco: false,
    thumbnail: aiag,
    partner: "AIAG/PLEXUS",
    description:
      "Melhorar a gestão dos processos de revestimento, focando na prevenção e melhoria da qualidade pela promoção de maior conhecimento e controlo sobre o processo e pela redução de riscos de ocorrência de produtos não conformes e custos da não qualidade. Entender as exigências do CQI-12 e garantir a qualificação dos auditores internos.",
    objectives:
      "Dotar os formandos dos conhecimentos e competências necessárias para a interpretação e aplicação dos requisitos “Processos Especiais: Avaliação do Sistema de Revestimento (Coating)”, tornando-os capazes de avaliar e auditar o sistema de revestimento conforme as tabelas de processos.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 180,
    title: "CQI 14 - PROCESSO DE GESTÃO DE GARANTIA",
    url: "CQI-14-PROCESSO-GESTAO-GARANTIA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "AIAG/PLEXUS",
    opco: false,
    thumbnail: aiag,
    partner: "AIAG/PLEXUS",
    description:
      "Requisitos relativos à gestão de garantias e análise de peças substituídas no terreno, para a indústria automóvel e qualificação dos profissionais na aplicação das ferramentas de avaliação do CQI14. Fornece uma abordagem de melhores práticas para gerir processos de garantia com foco no consumidor final.",
    objectives:
      "Dotar os formandos dos conhecimentos e competências necessárias para a interpretação e aplicação dos requisitos do manual CQI-14 para que possam aplicar o processo de gestão de garantias, através do trabalho em equipa, com foco no cliente final/utilizador, em conexão com os requisitos da IATF 16949.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 181,
    title: "CQI 15 - SISTEMA DE SOLDADURA (WELDING)",
    url: "CQI-15-SISTEMA-DE-SOLDADURA",
    category1: "Formação",
    category2: "Automóvel",
    category3: "AIAG/PLEXUS",
    opco: false,
    thumbnail: aiag,
    partner: "AIAG/PLEXUS",
    description:
      "Melhorar a gestão dos processos de soldadura metalúrgica, focando na prevenção e melhoria da qualidade pela promoção de maior conhecimento e controlo sobre o processo e pela redução de riscos de ocorrência de produtos não conformes e custos da não qualidade. Entender as exigências do CQI-15 e garantir a qualificação dos auditores internos).",
    objectives:
      "Dotar os formandos dos conhecimentos e competências necessárias para a interpretação e aplicação dos requisitos “Processos Especiais: Avaliação do Sistema de Soldadura (Welding)” tornando-os capazes de avaliar e auditar o sistema de soldadura conforme as tabelas de processos.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 182,
    title: "CQI 17 - SISTEMA DE SOLDA DE COMPONENTES ELECTRÓNICOS (SOLDERING)",
    url: "CQI-17-SISTEMA-SOLDA-COMPONENTES-ELECTRONICOS",
    category1: "Formação",
    category2: "Automóvel",
    category3: "AIAG/PLEXUS",
    opco: false,
    thumbnail: aiag,
    partner: "AIAG/PLEXUS",
    description:
      "Melhorar a gestão dos processos de solda electrónica, focando na prevenção e melhoria da qualidade pela promoção de maior conhecimento e controlo sobre o processo e pela redução de riscos de ocorrência de produtos não conformes e custos da não qualidade. Entender as exigências do CQI-17 e garantir a qualificação dos auditores internos.",
    objectives:
      "Dotar os formandos dos conhecimentos e competências necessárias para a interpretação e aplicação dos requisitos “Processos Especiais: Avaliação do Sistema de Solda (Soldering)”, tornando-os capazes de avaliar e auditar o sistema de revestimento conforme as tabelas de processos.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 183,
    title: "CQI 23 - SISTEMA DE MOLDAGEM (MOULDING)",
    url: "CQI23-SISTEMA-MOLDAGEM",
    category1: "Formação",
    category2: "Automóvel",
    category3: "AIAG/PLEXUS",
    opco: false,
    thumbnail: aiag,
    partner: "AIAG/PLEXUS",
    description:
      "Melhorar a gestão dos processos de moldagem, focando na prevenção e melhoria da qualidade pela promoção de maior conhecimento e controlo sobre o processo e pela redução de riscos de ocorrência de produtos não conformes e custos da não qualidade. Entender as exigências do CQI-23 e garantir a qualificação dos auditores internos.",
    objectives:
      "Dotar os formandos dos conhecimentos e competências necessárias para a interpretação e aplicação dos requisitos “Processos Especiais: Avaliação do Sistema de Moldagem (Moulding)”, tornando-os capazes de avaliar e auditar o sistema de revestimento conforme as tabelas de processos.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
  {
    id: 184,
    title: "VDA-ISA/TISAX - Segurança da Informação no Setor Automóvel",
    url: "VDA-ISA-TISAX",
    category1: "Auditoria",
    category2: "Automóvel",
    category3: "Auditorias Internas",
    opco: true,
    thumbnail: opco,
    partner: "OPCO",
    description:
      "TISAX (Trusted Information Security Assessment eXchange) é um sistema global de segurança da informação para a indústria automóvel. TISAX é principalmente aplicável a fornecedores Tier1 e Tier2, mas também extensível a todos os fornecedores. A processo de avaliação é um requisito obrigatório de algumas OEMs.",
    objectives:
      "O processo de avaliação é um requisito obrigatório de algumas OEMs. Assim, pretende-se dotar os formandos do sistema TISAX e respetivas aplicabilidades.",
    online: yes,
    presential: yes,
    online_hours: "16",
    presential_hours: "16",
    associated_training: [],
  },
];

export default data;
