import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Client from '../Contentfull';

export const getFormations = createAsyncThunk(
  'formations/getFormationsCatalog',
  async () => {
    return await Client.getEntries({
      content_type: 'formation',
      skip: 0,
      limit: 1000,
      order: 'sys.createdAt',
    }).then((res) => formatData(res));
  },
);

function formatData(res) {
  //console.log(res.items);
  return res.items;
}

const initialState = {
  status: null,
  data: [],
  fileredData: [],
  inputData: [],
  dataSelect: [],
  buttonId: 0,
  select1Value: 'FORMAÇÃO OFICIAL OPCO',
  select2Value: 'LEAN/PRODUTIVIDADE',
  select1Open: false,
};

const formationsSlideSlicev2 = createSlice({
  name: 'formationsCatalog',
  initialState,
  reducers: {
    filterSearchInput(state, action) {
      //console.log('Reducer filter:', action.payload);
      if (action.payload !== '') {
        const temp = state.inputData.filter((item) => {
          //console.log(item);
          return Object.values(item.fields)
            .join('')
            .toLowerCase()
            .includes(action.payload.toLowerCase());
        });
        state.fileredData = temp;
      }
    },
    filterSelect1(state, action) {
      //console.log(state, action.payload);
      const temp = state.data.filter(
        (item) => item.fields.partner === action.payload,
      );
      state.fileredData = temp;
      state.inputData = temp;
      state.select1Value = action.payload;
      state.select1Open = true;
    },
    filterSelect2(state, action) {
      const temp = state.data.filter(
        (item) => item.fields.actuationArea === action.payload,
      );
      state.fileredData = temp;
      state.inputData = temp;
      state.select2Value = action.payload;
    },
    filterSelect3(state, action) {
      const temp = state.data.filter(
        (item) => item.category3 === action.payload,
      );
      state.fileredData = temp;
    },
    filterSelect4(state, action) {
      const temp = state.data.filter((item) =>
        action.payload === 'Online'
          ? item.online === 'yes'
          : item.presential === 'yes',
      );
      state.fileredData = temp;
    },
    filterButtons(state, action) {
      const buttonId = action.payload - 1;
      //console.log(buttonId);
      const values = [
        { value: 'FORMAÇÃO OFICIAL OPCO', label: 'FORMAÇÃO OFICIAL OPCO' },
        { value: 'FORMAÇÃO VDA-QMC', label: 'FORMAÇÃO VDA-QMC' },
        { value: 'FORMAÇÃO SMMT INDUSTRY', label: 'FORMAÇÃO SMMT INDUSTRY' },
        { value: 'FORMAÇÃO ODETTE', label: 'FORMAÇÃO ODETTE' },
        { value: 'FORMAÇÃO FIEV', label: 'FORMAÇÃO FIEV' },
        { value: 'FORMAÇÃO PSA', label: 'FORMAÇÃO PSA' },
        {
          value: 'FORMAÇÃO OFICIAL AIAG E PLEXUS',
          label: 'FORMAÇÃO OFICIAL AIAG E PLEXUS',
        },
      ];
      if (buttonId === -1) {
        const temp = state.data;
        state.fileredData = temp;
        state.inputData = temp;
        state.buttonId = 0;
        state.select1Value = '';
        state.select2Value = '';
        state.select1Open = false;
      } else {
        const temp = state.data.filter(
          (item) => item.fields.partner === values[buttonId].value,
        );
        state.fileredData = temp;
        state.buttonId = buttonId;
        state.inputData = temp;
      }
      //console.log('Clicked on button: ', buttonId);
    },
  },
  extraReducers: {
    [getFormations.pending]: (state) => {
      state.status = 'Pending';
    },

    [getFormations.fulfilled]: (state, action) => {
      state.status = 'Fulfilled';
      state.data = action.payload;
      state.fileredData = action.payload;
      // state.fileredData = action.payload.filter(
      //   (item) =>
      //     item.fields.partner === state.select1Value &&
      //     item.fields.actuationArea === state.select2Value,
      // );
      // state.inputData = action.payload.filter(
      //   (item) =>
      //     item.fields.partner === state.select1Value &&
      //     item.fields.actuationArea === state.select2Value,
      // );
      state.inputData = action.payload;
    },
    //users = users.filter(obj => obj.name == filter.name && obj.address == filter.address)
    // action.payload.filter(item => item.fields.partner === select1Value1 && item.fields.actuationArea === select1Value2)

    [getFormations.rejected]: (state) => {
      state.status = 'Rejected';
    },
  },
});

export const {
  filterSearchInput,
  filterSelect1,
  filterSelect2,
  filterSelect3,
  filterSelect4,
  filterButtons,
} = formationsSlideSlicev2.actions;

export default formationsSlideSlicev2.reducer;
