import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { Context } from '../../context/LanguageContext';
import { getBanners } from '../../redux/bannersSlice';
import Spinner from '../Spinner/Spinner';
import './hero5.css';

const Hero5 = () => {
  const { language } = useContext(Context);
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const bannersData = useSelector((state) => state.banners.data);
  const bannersDataStatus = useSelector((state) => state.banners.status);

  useEffect(() => {
    dispatch(getBanners());
  }, [dispatch]);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    // cssEase: "linear",
    easing: 'linear',
    cssEase: 'cubic-bezier(0.600, -0.280, 0.735, 0.045)',
  };

  console.log(bannersData);
  return (
    <>
      {bannersDataStatus !== 'Fulfilled' ? <Spinner /> : ''}
      <section>
        <Slider {...settings}>
          {bannersData.map((picture, i) => {
            return (
              <div key={i}>
                <section
                  //className="home bg-img-1"
                  className="home test"
                  id="home"
                  style={{
                    backgroundImage: `url("https:${picture.image}")`,
                    width: '100%',
                    height: '100vh',
                    backgroundSize: 'cover',
                    paddingTop: '90px',
                  }}
                >
                  {/* <div className="bg-overlay"></div> */}
                  <div className="container h-100">
                    <div className="row align-items-center h-100">
                      <div className="col-lg-12">
                        <div className="home-wrapper text-center">
                          <h1
                            className="animated fadeInDown wow text-white"
                            data-wow-delay=".1s"
                          >
                            <span
                              className={
                                picture.titleWhite ? 'text-white' : 'text-body'
                              }
                            >
                              {language === 'pt'
                                ? picture.title
                                : picture.titleEn}
                            </span>
                          </h1>
                          <h4
                            className="animated fadeInDown wow text-white line-height-1_6 pt-3 pb-5"
                            data-wow-delay=".2s"
                          >
                            {language === 'pt' ? picture.text : picture.textEn}
                          </h4>
                          {picture.showButton ? (
                            <>
                              <a
                                target="_blank"
                                href={picture.url}
                                className="btn btn-danger btn-shadow rounded-pill w-lg animated fadeInDown wow"
                              >
                                {t('common:hero.button_label')}{' '}
                              </a>
                              <div className="clearfix"></div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            );
          })}
        </Slider>
      </section>
    </>
  );
};

export default Hero5;
