import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Events.css";
import "react-responsive-modal/styles.css";
import { Context as LanguageContext } from "../../context/LanguageContext";
import SectionWhite from "../Section/SectionWhite";
import SectionGrey from "../Section/SectionGrey";
import Spinner from "../Spinner/Spinner";
import { getNews } from "../../redux/newsSlice";
import CardNewComponent from "./CardNewComponent";

const News = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation(["common"]);
  const { language } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const newsData = useSelector((state) => state.news.data);
  const newsDataStatus = useSelector((state) => state.news.status);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    dispatch(getNews());
  }, []);

  function handleClick(singleNew) {
    history.push({
      pathname: `/noticias/${singleNew.id}`,
      state: { notice: { ...singleNew } },
    });
  }

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = newsData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(newsData);
    }
  };

  return (
    <>
      {newsDataStatus !== "Fulfilled" ? <Spinner /> : ""}
      <SectionWhite>
        <div className="container border border-danger p-2 mb-2 border-opacity-25 rounded p text-center">
          <div className="col pt-4">
            <div className="h4 pb-2 mb-4 text-danger border-bottom border-danger">
              <div className="row align-items-center justify-content-center pb-4">
                <div className="col-5">
                  <div className="input-group">
                    <span className="input-group-text">
                      Pesquisar por título
                    </span>
                    <input
                      type="text"
                      aria-label="search-title"
                      className="form-control"
                      placeholder="Pesquisar por título"
                      onChange={(e) => searchItems(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
              {searchInput.length > 1
                ? filteredResults
                    // .filter((item, index) => item.language === language)
                    .map((item, id) => {
                      return (
                        <CardNewComponent onClick={handleClick} item={item} />
                      );
                    })
                : newsData
                    // .filter((item, index) => item.language === language)
                    .map((item, id) => {
                      return (
                        <CardNewComponent onClick={handleClick} item={item} />
                      );
                    })}
            </div>
          </div>
        </div>
      </SectionWhite>
    </>
  );
};

export default News;
