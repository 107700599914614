import React, { useContext, useEffect } from "react";
import Services2 from "../components/Services2";
import TestimonialsBrands from "../components/TestimonialsClients";
import Team from "../components/Team";
import CarouselHome from "../components/CarrouselFormations";
import ProjectBanner from "../components/Homepage/projectbanner";
import Hero5 from "../components/HeroSection5";
import FormationsSlider from "../components/FormationsSlider/FormationSlider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Context } from "../context/DataContext";
import ContactHomepage from "../components/ContactHomepage";
import ShopSlider from "../components/ShopSlider/ShopSlider";
import HighLigthHomepage from "../components/HighlightHomepage/HighLigthHomepage";

const Home = () => {
  const { orderStatus, setOrderStatus } = useContext(Context);
  useEffect(() => {}, [orderStatus]);

  const notify = () => {
    toast.success("Encomenda processada com sucesso. Obrigado!", {
      onClose: () => setOrderStatus(""),
    });
  };
  return (
    <>
      {orderStatus === 201 ? notify() : <></>}
      <Hero5 />
      <FormationsSlider />
      <ShopSlider />
      {/* <ProjectBanner /> */}
      {/* <CarouselHome /> */}
      <Services2 />
      <HighLigthHomepage />
      <TestimonialsBrands />
      <Team />
      <ContactHomepage />
      <ToastContainer />
    </>
  );
};

export default Home;
