import React from 'react';

import TConditions_pt from '../components/Footer/TConditions_pt';
import Home from '../components/TermsConditions/Home';

const TermsConditions = () => {
  return (
    <>
      <Home />
      <TConditions_pt />
    </>
  );
};

export default TermsConditions;
