import React from "react";
import { useTranslation } from "react-i18next";

const HomeCourses = ({ courseName }) => {
  console.log(courseName);
  const { t } = useTranslation(["common"]);
  //console.log("courseName", courseName.course.title);
  return (
    <>
      <section className="home">
        {/* <div className="bg-overlay"></div> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="home-wrapper home-wrapper-sm text-center">
                <h2 className="animated fadeInDown wow" data-wow-delay=".1s">
                  <span className="text-colored">
                    <strong>{courseName}</strong>
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeCourses;
