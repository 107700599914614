import React, { useContext } from "react";
// import { MdMenu } from "react-icons/md";
import logo from "../../images/logo.png";
import logow from "../../images/logo.webp";
import "./Navbar2Elements.css";
import { FaGlobe } from "react-icons/fa";
import { IconContext } from "react-icons";
import { Context } from "../../context/LanguageContext";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { Link as LinkR } from "react-router-dom";
import { AwesomeButtonSocial } from "react-awesome-button";
import "react-awesome-button/dist/themes/theme-blue.css";
import { FcCalendar } from "react-icons/fc";
import ButtonFormations from "../ButtonFormation/ButtonFormations";
import Image from "react-image-webp";

const Navbar3 = () => {
	const { language, handleLanguage } = useContext(Context);
	const { t } = useTranslation(["common"]);

	return (
		<>
			<nav
				className="navbar navbar-expand-lg fixed-top navbar-custom sticky-dark"
				id="navbar-sticky"
			>
				<div className="container d-flex justify-content-center">
					<LinkR className="navbar-brand logo " to="/">
						<Image
							src={logo}
							webp={logow}
							alt=""
							className="logo text-center"
						/>
					</LinkR>
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarCollapse"
						aria-controls="navbarCollapse"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						{/* <MdMenu /> */}
						<i class="zmdi zmdi-menu"></i>
					</button>
				</div>
			</nav>
		</>
	);
};

export default Navbar3;
